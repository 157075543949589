<div class="d-flex justify-content-center">
  <div class="sundo-container medium text-center">
    <app-h2>{{'translate.general.welcomeUser' | translate:{'username': username} }}</app-h2>
    <app-h4>{{'translate.routes.plannedRoutes' | translate}}</app-h4>
    <div class="routes pb-4" >
      <ng-container *ngIf="plannedRoutes$ | async as plannedRoutes else loading">
        <div *ngFor="let route of plannedRoutes" class="route-card d-block mt-4 mx-auto" [routerLink]="['/operations/route-overview', route.plannedRouteId]">
          <app-h4 [subtitle]="isAdmin ? route.userFullName + ' - ' + (route.plannedDate * 1000 | date: 'dd/MM/yyyy') : ''">
            {{route.routeDescription}}
          </app-h4>
        </div>

        <div *ngIf="!plannedRoutes.length">
          <h5>
            {{'translate.routes.noPlannedRoutesToday' | translate}}
          </h5>
        </div>
      </ng-container>

      <ng-template #loading>
        <div class="d-flex justify-content-center">
          <mat-spinner color="accent"></mat-spinner>
        </div>
      </ng-template>
    </div>
  </div>
</div>
