import {Component, OnInit} from '@angular/core';
import {catchError, filter, tap} from 'rxjs/operators';
import {EMPTY, Observable} from 'rxjs';
import {DeviceDTO} from '../../dtos/deviceDTO';
import {SmotService} from '../../services/smot.service';
import {ToastrNotificationService} from '../../services/toastr-notification.service';
import {TranslateService} from '@ngx-translate/core';
import {of} from 'rxjs/internal/observable/of';
import {ScannedQR} from '../../models/ScannedQR';
import {ScannerService} from '../../services/scanner.service';
import {OperationService} from '../../services/operation.service';
import {DeviceService} from '../../services/device.service';
import {SmotDTO} from '../../dtos/SmotDTOs/smotDTO';
import {ActionDTO} from '../../dtos/OperationDTOs/actionDTO';

@Component({
  selector: 'app-overrides',
  templateUrl: './overrides.component.html',
  styleUrls: ['./overrides.component.scss', '../operations/operations.scss']
})
export class OverridesComponent implements OnInit {

  // OverridePage
  $device: Observable<DeviceDTO | SmotDTO>;
  isSmot: boolean = false;

  // Scanner
  qrScanComplete: boolean = false;
  badScan: boolean = false;

  constructor(private smotService: SmotService,
              private toastr: ToastrNotificationService,
              private translate: TranslateService,
              private scanService: ScannerService,
              private operationService: OperationService,
              private deviceService: DeviceService) { }

  ngOnInit(): void {
    this.fetchDevice();
  }

  sendOverride(device: any, overrideId: string, lastEventCounter: boolean = false, path: boolean = false) {
    const params: ActionDTO = {
      iccid: device.backplate?.iccid ?? device.device?.backplate?.iccid,
      overrideId: overrideId
    };

    if (lastEventCounter) {
      if (device.lastEventCounter == null) {
        this.toastr.showError('Failed because this device is not linked to a SMOTSpot', 'Failure');
        return;
      }
      params.lastEventCounter = device.lastEventCounter;
    }

    if (path) {
      // Add path to params
    }

    this.smotService.performSmotAction(params).pipe(
      filter(x => x !== undefined),
      tap(() => this.toastr.showSucces(this.translate.instant('override action success'), this.translate.instant('Sucess'))),
      catchError(error => {
        this.toastr.showErrorBasedOnStatus(error.status);
        return EMPTY;
      })
    ).subscribe();
  }


  scanCompleteHandler(event) {
    this.badScan = false;
    if (event === undefined) { this.badScan = true; return; }
    const scannedQR: ScannedQR = this.scanService.verifyScan(event);
    if (scannedQR == null) { this.badScan = true; return; }

    this.operationService.operationEntityScanned(+scannedQR.casingId, +scannedQR.backplateId, +scannedQR.seasonId, +scannedQR.customCasingId).pipe(
      tap(value => {
        if (value == null) {
          this.toastr.showError('This code is not linked to a Device or SMOTSpot', 'Failure');
          return;
        }

        this.qrScanComplete = true;

        if (this.deviceService.instanceOfDevice(value)) {
          this.isSmot = false;
          this.$device = of(value);
          return;
        }

        if (this.smotService.instanceOfSmot(value)) {
          this.isSmot = true;
          this.$device = of(value);
          return;
        }
      }),
    ).subscribe();
  }

  private fetchDevice() {
    this.$device = of({
      deviceId: 1,
      backplate: {
        backplateId: 1,
        iccid: '11221312312',
        type: 'idk',
        state: 'something',
        version: 1
      },
      customCasing: {
        customCasingId: 1
      }
    } as DeviceDTO);
  }
}
