<div class="sundo-container medium h-100 m-auto position-relative">
  <div class="back-button" (click)="goToPreviousStep()">
    <mat-icon >keyboard_arrow_left</mat-icon>
  </div>
  <app-h2 class="text-center">{{this.data.headerString ?? 'Scan een label' | translate}}</app-h2>
  <app-js-qr-scanner (codeScanned)="handleScannedCode($event)" [processingScan]="processingScan" [badScan]="badScan"></app-js-qr-scanner>
  <p class="error-message-minimal text-center" *ngIf="incorrectSmotScanned">
    {{'translate.routes.scannedIncorrectSmot' | translate}}
  </p>
</div>


