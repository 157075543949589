import { BaseFlow } from './base-flow.class';
import {OperationService} from '../../services/operation.service';
import {Injectable} from '@angular/core';
import {OPERATION_REPAIR_FLOW} from '../../models/operationFlows';
import {ToastrNotificationService} from '../../services/toastr-notification.service';
import { Location } from '@angular/common';
import {Params} from '@angular/router';
import _ from 'lodash';
import {FlowScannerComponent} from '../../views/shared/flow/flow-steps/flow-scanner/flow-scanner.component';
import {ROUTE_MAINTENANCE_FLOW} from '../../models/routeFlows';
import {CreateOperationDTO} from '../../dtos/OperationDTOs/createOperationDTO';
import {FlowService} from '../../services/flow.service';

@Injectable({
  providedIn: 'root'
})
// TODO: reintroduce operationSmot$ to decrease number of api calls in the flows
export class OperationFlow extends BaseFlow {
  constructor(private operationService: OperationService,
              public location: Location,
              public toastr: ToastrNotificationService,
              public flowService: FlowService) {
    super(toastr, location, flowService);
    this.key = 'operation_step_stage';
  }

  getFlow(params: Params): any {
    switch (params['flow']) {
      case 'maintenance':
        const flow = _.cloneDeep(ROUTE_MAINTENANCE_FLOW);
        this.flow = {
          ...flow,
          steps: flow.steps.filter(step => step.component !== FlowScannerComponent)
        };
        return this.flow;
      case 'repair':
        this.flow = _.cloneDeep(OPERATION_REPAIR_FLOW);
        return this.flow;
      default:
        return null;
    }
  }

  getCurrentStep(params: Params): any {
    const step = super.getCurrentStep(params);

    // Get SMOTSpot and define step data
    step.data = {
      ...step.data,
      smotId: params['id'],
    };

    return step;
  }

  finishFlow() {
    switch (this.params['flow']) {
      case 'maintenance':
        const operation: CreateOperationDTO = {
          operationType: 'CLEAN',
          info: null,
          smotLogicalId: this.params['id'],
        };
        this.operationService.postOperation(operation).subscribe(() => {
          super.finishFlow();
        });
        break;
      default:
        super.finishFlow();
        break;
    }
  }
}
