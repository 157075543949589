import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RenderDynamicComponentDirective} from './render-dynamic-components/render-dynamic-component.directive';
import {AutoFocusDirective} from './auto-focus/auto-focus.directive';


@NgModule({
  declarations: [
    RenderDynamicComponentDirective,
    AutoFocusDirective
  ],
  exports: [
    RenderDynamicComponentDirective,
    AutoFocusDirective
  ],
  imports: [
    CommonModule
  ]
})
export class DirectivesModule { }
