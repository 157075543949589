import { Injectable } from '@angular/core';
import {ApiHelperService} from './api-helper.service';
import {environment} from '../../environments/environment';
import {CasingDTO} from '../dtos/casingDTO';

@Injectable({
  providedIn: 'root'
})
export class CasingService {
  casingUrl = environment.apiRoot + '/casings';

  constructor(private api: ApiHelperService) { }

  getCasingById(id: number) {
    const url = `${this.casingUrl}/${id}`;
    return this.api.getRequest<CasingDTO>(url);
  }
}
