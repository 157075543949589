<style>
  .profile-img {
    margin-right: 0;
  }

  #lng-picker {
    position: absolute;
    top: 1px;
    left: 80px;
    right: auto;
  }

  .aside-menu {
    visibility: visible;
  }

  .container{
    padding: 0;
  }

  @media screen and (min-width: 990px){
    .aside-menu {
      visibility: hidden;
    }

    #lng-picker {
      right: 100px;
      left: auto;
    }

    .profile-img {
      margin-right: 30px;
    }
  }
</style>

<app-header
  [navbarBrandRouterLink]="['/dashboard']"
  [fixed]="true"
  [navbarBrandFull]="{src: 'assets/img/brand/sundo_logo.svg', width: 150, height: 80, alt: 'Sundo Logo'}"
  [navbarBrandMinimized]="{src: 'assets/img/brand/sygnet.svg', width: 30, height: 30, alt: 'Sundo Logo'}"
  [sidebarToggler]="'lg'"
  [mobileAsideMenuToggler]="false"
  [asideMenuToggler]="false">
  <ul class="nav navbar-nav d-md-down-none" >
    <li *ngFor="let item of topNavItems" class="nav-item px-3">
      <a [routerLink]="item.url" class="nav-link">{{item.key | translate}}</a>
    </li>
  </ul>
  <ul class="nav navbar-nav ml-auto">
    <li id="lng-picker">
      <app-lng-picker></app-lng-picker>
    </li>
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
        <ng-container *ngIf="profileImage$ | async as image else defaultImage">
          <img [attr.src]="image" alt="profile image" class="profile-img" height="35px" width="35px" style="border-radius: 100%; object-fit: cover;">
        </ng-container>
        <ng-template #defaultImage>
          <img  src='./assets/img/avatars/undefined.jpg' alt="no img" height="35px"/>
        </ng-template>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center"><strong>{{ 'translate.nav.settings' | translate }}</strong></div>
        <a class="dropdown-item" href="#/profile"><i class="fa fa-user"></i> {{ 'translate.nav.profile' | translate }}</a>
        <a class="dropdown-item" [href]="'#/settings/manage-notifications/' + this.username"><i class="fa fa-wrench"></i> {{'translate.settings.manageNotifications' | translate }}</a>
        <a class="dropdown-item" href="#/settings/change-password"><i class="fa fa-wrench"></i> {{'translate.settings.changePassword.changePassword' | translate }}</a>
        <div class="divider"></div>
        <a class="dropdown-item" href="#"><i class="fa fa-shield"></i> {{ 'translate.nav.lockAccount' | translate }}</a>
        <a class="dropdown-item" href="#/login" (click)="logout()"><i class="fa fa-lock"></i> {{ 'translate.nav.logout' | translate }}</a>
      </div>
    </li>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized" (minimizedChange)="toggleMinimize($event)">
    <app-sidebar-nav [navItems]="sideNavItems" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <aside class="aside-menu" >
  </aside>
  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <!-- breaking change 'cui-breadcrumb' -->
    <!-- deprecation warning for 'app-breadcrumb' -->
    <!--<ol class="breadcrumb">-->
      <!--<app-breadcrumb></app-breadcrumb>-->
      <!--&lt;!&ndash; Breadcrumb Menu&ndash;&gt;-->
      <!--<li class="breadcrumb-menu d-md-down-none">-->
        <!--<div class="btn-group" role="group" aria-label="Button group with nested dropdown">-->
          <!--<a class="btn" href="#"><i class="icon-speech"></i></a>-->
          <!--<a class="btn" [routerLink]="['/dashboard']"><i class="icon-graph"></i> &nbsp;Dashboard</a>-->
          <!--<a class="btn" href="#"><i class="icon-settings"></i> &nbsp;Settings</a>-->
        <!--</div>-->
      <!--</li>-->
    <!--</ol>-->
<!--    -->
<!--    [ngClass]="{'one': i%2==0, 'three':i%2==1}"-->
    <div class="h-100 p-3" [ngClass]="{'container': isMobile, 'container-fluid': !isMobile }" >
      <router-outlet></router-outlet>
    </div><!-- /.container-fluid -->
  </main>
</div>
<app-footer>
  <span><a href="https://www.sundo.be/">Sundo BV</a> &copy; 2020</span>
</app-footer>
