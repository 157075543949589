<div class="smot-table">
<!--  <div class="filters">-->

<!--  </div>-->
  <app-sundo-table
    [$dataSource]="smotTableData$"
    [columns]="columns"
    [clickableRows]="true"
    (editClicked)="goToSmotDetails($event)"
    (rowClicked)="goToSmotDetails($event)"
    (dataChangeRequested)="handleDataChangeRequested($event)">
      <div class="mr-3 mt-3">
        <mat-slide-toggle [labelPosition]="'before'"  (change)="handleFilterUpdate()" [(ngModel)]="showDeinstalled">{{'translate.smot.showDeinstalled' | translate}}</mat-slide-toggle>
      </div>
  </app-sundo-table>
</div>

