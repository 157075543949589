import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageCreateService {

  //region User Images
  private profileImageSubject = new BehaviorSubject(null);
  profileImage$ = this.profileImageSubject.asObservable().shareReplay(1);

  private userImageSubject = new BehaviorSubject(null);
  userImage$ = this.userImageSubject.asObservable();
  //endregion

  //region Smot Image
  private smotImageSubject = new BehaviorSubject(null);
  smotImage$ = this.smotImageSubject.asObservable();
  //endregions

  //region Operations Images
  private operationImageSubject = new BehaviorSubject(null);
  operationImage$ = this.operationImageSubject.asObservable();

  private operationBeforeImageSubject = new BehaviorSubject(null);
  operationBeforeImage$ = this.operationBeforeImageSubject.asObservable();

  private operationAfterImageSubject = new BehaviorSubject(null);
  operationAfterImage$ = this.operationAfterImageSubject.asObservable();
  //endregion

  result;

  constructor() { }

  createUserImg(blob) {
    this.createImgFromBlob(blob, (result) => this.userImageSubject.next(result));
  }

  createProfileImg(blob) {
    this.createImgFromBlob(blob, (result) => this.profileImageSubject.next(result));
  }

  createSmotImg(blob) {
    this.createImgFromBlob(blob, (result) => this.smotImageSubject.next(result));
  }

  createOperationImage(blob, option: null | 'before' | 'after' = null) {
    switch (option) {
      case null:
        this.createImgFromBlob(blob, (result) => this.operationImageSubject.next(result));
        break;
      case 'before':
        this.createImgFromBlob(blob, (result) => this.operationBeforeImageSubject.next(result));
        break;
      case 'after':
        this.createImgFromBlob(blob, (result) => this.operationAfterImageSubject.next(result));
        break;
    }
  }

  public resetImages() {
    this.operationImageSubject.next(null);
    this.operationBeforeImageSubject.next(null);
    this.operationAfterImageSubject.next(null);
  }

  handleImageRetrievalError(err: Error, type: number) {
    switch (type) {
      case 0:
        this.profileImageSubject.next(undefined);
        break;
      case 1:
        this.smotImageSubject.next(undefined);
        break;
      case 2:
        this.userImageSubject.next(undefined);
        break;
      case 3:
        this.operationImageSubject.next(undefined);
        break;
      case 4:
        this.operationBeforeImageSubject.next(undefined);
        break;
      case 5:
        this.operationAfterImageSubject.next(undefined);
        break;
    }
    console.log(err);
    console.log('Image creation failed');
  }

  updateSmotImg(image) {
    this.smotImageSubject.next(image);
  }

  updateUserImage(image) {
    this.userImageSubject.next(image);
  }

  urltoFile(url, filename, mimeType) {
    return (fetch(url)
        .then(function(res) {return res.arrayBuffer(); })
        .then(function(buf) {return new File([buf], filename, {type: mimeType}); })
    );
  }

  private createImgFromBlob(image: Blob, callbackFunction: Function): any {
    if (image && image.size > 0) {
      const reader = new FileReader();
      reader.addEventListener('loadend', () => {
        callbackFunction(reader.result);
      }, false);

      reader.readAsDataURL(image);
    }
  }
}
