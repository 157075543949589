<div style="position: absolute; bottom: -9999px">
  <div id="label-container">
    <div id="label-type">
      <span>{{ entityType }}</span>
    </div>
    <div id="label-content">
      <div id="panel-icon" *ngIf="hasInfoPanel != null">
        <ng-container *ngIf="hasInfoPanel else noPanel">
          <svg xmlns="http://www.w3.org/2000/svg" width="68" height="35" viewBox="0 0 68 35" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M-1.34708e-06 9C-1.12981e-06 4.02944 4.02943 -4.12056e-07 9 -4.33205e-07L59 -3.93403e-07C63.9706 -4.14551e-07 68 4.02944 68 9L68 26C68 30.9706 63.9706 35 59 35L9 35C4.02944 35 -2.30744e-06 30.9706 -2.09017e-06 26L-1.34708e-06 9ZM34 30C41.1797 30 47 24.1797 47 17C47 9.8203 41.1797 4 34 4C26.8203 4 21 9.8203 21 17C21 24.1797 26.8203 30 34 30ZM34 32C42.2843 32 49 25.2843 49 17C49 8.71573 42.2843 2 34 2C25.7157 2 19 8.71573 19 17C19 25.2843 25.7157 32 34 32ZM33.4438 22.9589C34.8352 21.2853 35.7315 20.1502 36.4539 19.2353C38.0327 17.2359 38.781 16.2883 42.0521 13.0691L39.9479 10.9309C36.5406 14.284 35.679 15.3745 34.0411 17.4476L34.0408 17.4479C33.5102 18.1195 32.8981 18.8942 32.0915 19.8824L26.9599 15.6092L25.0401 17.9145L31.3305 23.1527L32.4841 24.1133L33.4438 22.9589Z" fill="black"/>
          </svg>
        </ng-container>
        <ng-template #noPanel>
          <svg xmlns="http://www.w3.org/2000/svg" width="68" height="57" viewBox="0 0 68 57" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 12C4.02944 12 -1.76132e-07 16.0294 -3.93403e-07 21L-1.1365e-06 38C-1.35377e-06 42.9706 4.02944 47 9 47L13.9961 47L34.2034 12L9 12ZM54.2959 12L34.0886 47L59 47C63.9706 47 68 42.9706 68 38L68 21C68 16.0294 63.9706 12 59 12L54.2959 12Z" fill="black"/>
            <rect x="47.1899" y="0.911064" width="5.99631" height="60.169" rx="2" transform="rotate(30 47.1899 0.911064)" fill="black"/>
          </svg>
        </ng-template>
      </div>
      <div id="label-overview">
        <div *ngIf="logicalId">
          <span>Logical ID:</span>
          <span>{{ logicalId }}</span>
        </div>
        <div *ngIf="deviceId">
          <span>Device ID:</span>
          <span>{{ deviceId }}</span>
        </div>
        <div *ngIf="casingId">
          <span>Casing ID:</span>
          <span>{{ casingId }}</span>
        </div>
        <div *ngIf="backplateId">
          <span>Backplate ID:</span>
          <span>{{ backplateId }}</span>
        </div>
        <div *ngIf="client">
          <span>Klant:</span>
          <span>{{ client }}</span>
        </div>
        <div *ngIf="variant">
          <span>Variant:</span>
          <span>{{ variant }}</span>
        </div>
        <div *ngIf="language">
          <span>Taal:</span>
          <span>{{ language }}</span>
        </div>
        <div *ngIf="supplier">
          <span>Leverancier:</span>
          <span>{{ supplier }}</span>
        </div>
        <div *ngIf="productionYear">
          <span>Productiejaar:</span>
          <span>{{ productionYear }}</span>
        </div>
        <div *ngIf="state">
          <span>Staat:</span>
          <span>{{ state }}</span>
        </div>
      </div>

      <div id="barcode-container" *ngIf="barcodeValue">
        <ngx-barcode id="barcode" [bc-element-type]="'svg'" [bc-height]="250" [bc-width]="6" [bc-format]="'CODE128A'" [bc-value]="barcodeValue" [bc-display-value]="false"></ngx-barcode>
        <span>{{barcodeValue}}</span>
      </div>
    </div>
  </div>
</div>

