import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {ScannerModule} from '../../components/scanner/scanner.module';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import { SundoHeadersModule } from '../../components/headers/sundo-headers.module';
import { SundoButtonsModule } from '../../components/buttons/sundo-buttons.module';
import { FlowComponent } from './flow/flow.component';
import {DirectivesModule} from '../../directives/directives.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FormFieldsModule} from '../../components/form-fields/form-fields.module';
import {FormsModule} from '@angular/forms';
import { RefillComponent } from './flow/flow-steps/refill/refill.component';
import { CommentsComponent } from './flow/flow-steps/comments/comments.component';
import { UploadImageComponent } from './flow/flow-steps/upload-image/upload-image.component';
import { FinishFlowComponent } from './flow/flow-steps/finish-flow/finish-flow.component';
import { FlowScannerComponent } from './flow/flow-steps/flow-scanner/flow-scanner.component';

@NgModule({
  declarations: [
    FlowComponent,
    RefillComponent,
    CommentsComponent,
    UploadImageComponent,
    FinishFlowComponent,
    FlowScannerComponent
  ],
  imports: [
    MatIconModule,
    SundoHeadersModule,
    SundoButtonsModule,
    ScannerModule,
    CommonModule,
    TranslateModule,
    DirectivesModule,
    MatCheckboxModule,
    FormFieldsModule,
    FormsModule,
  ],
  exports: [
    FlowComponent,
    RefillComponent,
    CommentsComponent,
    UploadImageComponent,
    FinishFlowComponent,
    FlowScannerComponent
  ]
})
export class SharedViewsModule {
}
