import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {EMPTY, Observable, of} from 'rxjs';
import {IDropdownOption} from '../../../components/form-fields/drop-down-field/drop-down-field.component';
import {BaseFlowStepComponent} from '../../shared/flow/flow-steps/base-flow-step.interface';
import {ScannedQR} from '../../../models/ScannedQR';
import {ScannerService} from '../../../services/scanner.service';
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';
import {SmotDTO} from '../../../dtos/SmotDTOs/smotDTO';
import {ConfirmActionModalComponent} from '../../plugins/confirm-action-modal/confirm-action-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {ToastrNotificationService} from '../../../services/toastr-notification.service';
import {OperationDTO} from '../../../dtos/OperationDTOs/operationDTO';
import {SmotService} from '../../../services/smot.service';
import {ActionDTO} from '../../../dtos/OperationDTOs/actionDTO';
import {OperationService} from '../../../services/operation.service';
import {DeviceService} from '../../../services/device.service';
import {CreateOperationDTO} from '../../../dtos/OperationDTOs/createOperationDTO';

@Component({
  selector: 'app-select-repair-part',
  templateUrl: './select-repair-part.component.html',
  styleUrls: ['./select-repair-part.component.scss', '../operations.scss']
})
export class SelectRepairPartComponent implements OnInit, BaseFlowStepComponent {
  @Input() data: any;
  @Output() nextStep: EventEmitter<true> = new EventEmitter();
  @Output() previousStep: EventEmitter<false> = new EventEmitter();

  partControl = new FormControl(null, Validators.required);

  processingScan: boolean = false;
  badScan: boolean = false;
  showScanner: boolean = false;

  parts$: Observable<IDropdownOption[]> = of([
    {
      value: 'Backplate',
      label: this.translate.instant('translate.operations.smot-repair.parts.backplate'),
    },
    {
      value: 'Casing',
      label: this.translate.instant('translate.operations.smot-repair.parts.casing'),
    },
    {
      value: 'Motor',
      label: this.translate.instant('translate.operations.smot-repair.parts.motor'),
    },
    {
      value: 'Sensor',
      label: this.translate.instant('translate.operations.smot-repair.parts.sensor'),
    },
    {
      value: 'Antenne',
      label: this.translate.instant('translate.operations.smot-repair.parts.antenna'),
    },
    {
      value: 'Batterij',
      label: this.translate.instant('translate.operations.smot-repair.parts.battery'),
    },
    {
      value: 'Scherm',
      label: this.translate.instant('translate.operations.smot-repair.parts.screen'),
    },
    {
      value: 'Nozzle',
      label: this.translate.instant('translate.operations.smot-repair.parts.nozzle'),
    },
    {
      value: 'Andere',
      label: this.translate.instant('translate.operations.smot-repair.parts.other'),
    },
  ]);

  constructor(private translate: TranslateService,
              private matDialog: MatDialog,
              private smotService: SmotService,
              private operationService: OperationService,
              private toastr: ToastrNotificationService,
              private deviceService: DeviceService,
              private scanService: ScannerService) { }

  ngOnInit(): void {
  }

  public goToPreviousStep(): void {
    this.previousStep.emit(false);
  }

  public checkRepairPart() {
    if (this.partControl.invalid) {
      return;
    }

    if (this.partControl.value === 'Backplate' || this.partControl.value === 'Casing') {
      this.showScanner = true;
    } else {
      const operation: CreateOperationDTO = {
        operationType: 'REPAIR',
        smotLogicalId: this.data.smotId,
        info: null
      };

      this.saveOperation(operation, null, null).subscribe();
    }
  }

  public scanCompleteHandler(event) {
    this.badScan = false;
    this.processingScan = true;

    if (event === undefined) {
      this.badScan = true;
      this.processingScan = false;
      return;
    }

    const scannedQR: ScannedQR = this.scanService.verifyScan(event);
    if (scannedQR == null || scannedQR.type !== this.partControl.value.toLowerCase()) {
      this.badScan = true;
      this.processingScan = false;
      return;
    }

    this.handleScan(scannedQR);
  }


  private handleScan(scannedQR: ScannedQR): void {
    this.scanService.getTopLevelEntity(scannedQR).pipe(
      tap(topLevelEntity => {
        switch (topLevelEntity.entityType) {
          case 'smot':
            this.promptUserToInvalidateSMOTspotOrDevice(topLevelEntity.value, scannedQR);
            break;
          case 'device':
            this.promptUserToInvalidateSMOTspotOrDevice(topLevelEntity.value, scannedQR);
            this.processingScan = false;
            break;
          case 'notFound':
          case 'customCasing':
            this.createRepairOperation(!!scannedQR.backplateId ? +scannedQR.backplateId : +scannedQR.casingId);
            this.processingScan = false;
            break;
        }
      })
    ).subscribe();
  }

  private promptUserToInvalidateSMOTspotOrDevice(entity: any, scannedQR: ScannedQR) {
    let html;
    let key;
    if ('logicalId' in entity) {
      key = 'Dit toestel bevindt zich in een actieve SMOTSpot. Wil je deze backplate hier gebruiken\n Waarschuwing: hiermee maak je de onderstaande SMOTSpot ongeldig';
      html = '<ul>' +
        `<li>Logical ID: ${entity.logicalId}</li>` +
        `<li>Locatie Beschrijving: ${entity.locationDescription}</li>` +
        `<li>Klant: ${entity.client?.clientName}</li>` +
        `<li>Variant: ${entity.clientVariant?.clientVariantIdentifier}</li>` +
        `<li>Taal: ${entity.language}</li>` +
        '</ul>';
    } else {
      key = 'Deze backplate/casing bevindt zich in een ander toestel. Wil je deze casing/backplate hier gebruiken\n Waarschuwing: hiermee maak je het onderstaande toestel ongeldig';
      html = '<ul>' +
        `<li>Device ID: ${entity.deviceId}</li>` +
        `<li>Backplate ID: ${entity.backplate?.backplateId}</li>` +
        `<li>Casing ID: ${entity.customCasing?.casingId}</li>` +
        `<li>Variant: ${entity.customCasing?.clientVariantIdentifier}</li>` +
        `<li>Taal: ${entity.customCasing?.language}</li>` +
        '</ul>';
    }

    const dialogRef = this.matDialog.open(ConfirmActionModalComponent, {
      data: {
        key: key,
        html: html
      }
    });

    dialogRef.afterClosed().pipe(
      filter(x => x !== undefined),
      tap(response => {
        if (response) {
          if (this.partControl.value === 'Backplate') {
            this.createRepairOperation(+scannedQR.backplateId);
          } else {
            this.createRepairOperation(+scannedQR.casingId);
          }
        } else {
          this.toastr.showError('Dit toestel wordt al gebruikt in een andere SMOTSpot', 'Error');
        }
        this.processingScan = false;
      })
    ).subscribe();
  }

  private createRepairOperation(newId: number) {
    this.smotService.getSmot(this.data.smotId).pipe(
      filter(x => x !== undefined),
      map(smot => ({
        operation: {
          operationType: 'REPAIR',
          smotLogicalId: smot.logicalId,
          info: this.createInfoString(smot, newId)
        } as CreateOperationDTO,
        smot: smot
      })),
      switchMap(response => this.saveOperation(response.operation, newId, response.smot.lastEventCounter))
    ).subscribe();
  }

  private createInfoString(smot: SmotDTO, newId: number): String {
    const info: any = {
      repairPart: this.partControl.value
    };

    if (this.partControl.value === 'Backplate') {
      info.newBckId = newId.toString();
      info.oldBckId = smot.device.backplate.backplateId.toString();
    }

    if (this.partControl.value === 'Casing') {
      info.newCasId = newId.toString();
      info.oldCasId = smot.device.customCasing.casingId.toString();
    }

    return JSON.stringify(info);
  }

  private saveOperation(operation: CreateOperationDTO, newId: number, lastEventCounter: number): Observable<OperationDTO> {
    // Save the operation
    return this.operationService.postOperation(operation).pipe(
      filter(x => x !== undefined),
      switchMap(response => {
        this.toastr.showSucces(this.translate.instant('translate.operations.smot-repair.succesfullyRepairedPart',
            {item: this.partControl.value}),
          this.translate.instant('translate.general.succes'));
        this.processingScan = false;
        this.nextStep.emit(true);
        // Override the event counter if a backplate has been scanned
        if (this.partControl.value === 'Backplate') {
          return this.overrideEventCounterForBackplate(newId, lastEventCounter);
        }
        return of(response);
      }),
      catchError(() => {
        this.processingScan = false;
        return EMPTY;
      })
    );
  }

  private overrideEventCounterForBackplate(backplateId: number, events: number) {
    return this.deviceService.getBackplateById(backplateId).pipe(
      filter(x => x !== undefined),
      switchMap(backplate => {
        const params: ActionDTO = {
          iccid: backplate.iccid,
          overrideId: '21',
          lastEventCounter: events,
        };
        return this.smotService.performSmotAction(params);
      })
    );
  }

}
