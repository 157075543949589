import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {TablesComponent} from './tables.component';

// Routing
import {TableComponent} from './table/table.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {TranslateModule} from '@ngx-translate/core';
import {FormFieldsModule} from '../../components/form-fields/form-fields.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSortModule} from '@angular/material/sort';
import {SundoButtonsModule} from '../../components/buttons/sundo-buttons.module';

@NgModule({
    imports: [
        MatTableModule,
        CommonModule,
        MatPaginatorModule,
        TranslateModule,
        FormFieldsModule,
        MatProgressBarModule,
        MatSortModule,
        SundoButtonsModule
    ],
  exports: [
    TableComponent
  ],
  declarations: [
    TablesComponent,
    TableComponent
  ]
})
export class TablesModule { }
