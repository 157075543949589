import { Injectable, Injector } from '@angular/core';
import { BaseFlow } from './base-flow.class';
import {OperationFlow} from './operation-flow.class';
import {RouteFlow} from './route-flow.class';
import {ProductionFlow} from './production-flow.class';

@Injectable({
  providedIn: 'root',
})
export class FlowFactory {
  constructor(private injector: Injector) { }

  createFlow(flowType: FlowType): BaseFlow {
    switch (flowType) {
      case FlowType.ROUTE_FLOW:
        return this.injector.get(RouteFlow);
      case FlowType.OPERATION_FLOW:
        return this.injector.get(OperationFlow);
      case FlowType.PRODUCTION_FLOW:
        return this.injector.get(ProductionFlow);
    }
  }
}


export enum FlowType {
  ROUTE_FLOW,
  OPERATION_FLOW,
  PRODUCTION_FLOW
}
