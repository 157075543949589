import {Injectable} from '@angular/core';
import {ToastrConfig, ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ToastrNotificationService {

  constructor(private toastr: ToastrService,
              private translate: TranslateService) { }

  toastrOptions: ToastrConfig = {
    disableTimeOut: false,
    easeTime: 300,
    easing: 'ease-in',
    enableHtml: false,
    extendedTimeOut: 1000,
    messageClass: '',
    newestOnTop: true,
    onActivateTick: false,
    positionClass: 'toast-top-center',
    progressAnimation: 'decreasing',
    progressBar: true,
    tapToDismiss: true,
    timeOut: 5000,
    titleClass: 'toast-title',
    toastClass: 'ngx-toastr',
    closeButton: true
  };

  showSucces(message: string, title: string) {
    this.toastr.success(message, title, this.toastrOptions);
  }

  showError(message: string, title: string) {
    this.toastr.error(message, title, this.toastrOptions);
  }

  showInfo(message: string, title: string) {
    this.toastr.info(message, title, this.toastrOptions);
  }

  showWarning(message: string, title: string) {
    this.toastr.warning(message, title, this.toastrOptions);
  }

  showErrorBasedOnStatus(errorStatus: number, notFoundItem: string = '', displayCustomMessage: boolean = false, customMessage: string = '') {
    let message: string;
    const title: string = this.translate.instant('translate.general.error.error');

    if (displayCustomMessage) {
      this.toastr.error(this.translate.instant(customMessage), title, this.toastrOptions);
      return;
    }

    // Not sure if the switch catches null values, so this code will make sure it does not crash for now
    if (errorStatus === null || errorStatus === undefined) {
      message = this.translate.instant('translate.general.error.somethingWentWrong');
      this.toastr.error(message, title, this.toastrOptions);
      return;
    }

    switch (errorStatus) {
      case 400: // Bad Request
        message = this.translate.instant('translate.statusError.badRequest');
        break;
      case 401: // Unauthorized
        message = this.translate.instant('translate.statusError.unauthorized');
        break;
      case 403: // Forbidden
        message = this.translate.instant('translate.statusError.forbidden');
        break;
      case 404: // Not Found
        message = this.translate.instant('translate.statusError.notFound', {'item': notFoundItem});
        break;
      case 500: // Internal Server Error
        message = this.translate.instant('translate.statusError.internalServerError');
        break;
      case 502: // Bad Gateway
        message = this.translate.instant('translate.statusError.badGateway');
        break;
      case 504: // Gateway Timeout
        message = this.translate.instant('translate.statusError.gatewayTimeout');
        break;
      default: // Other
        message = this.translate.instant('translate.general.error.somethingWentWrong');
        break;
    }
    this.toastr.error(message, title, this.toastrOptions);
  }
}
