<!--  SMOT DATA -->
<div class="container-fluid" style="border: none">
  <div class="row justify-content-end">
    <div class="col-auto">
      <mat-form-field appearance="fill" color="accent" >
        <mat-label>{{'translate.datepicker.enterADateRange' | translate}}</mat-label>
        <mat-date-range-input [max]="maxDate" [rangePicker]="picker" [formGroup]="dateForm">
          <input matStartDate [formControl]="dateFromControl"
                 placeholder="{{ 'translate.smot-detail.datePicker.startDate' | translate }}">
          <input matEndDate [formControl]="dateUntilControl"
                 placeholder="{{ 'translate.smot-detail.datePicker.endDate' | translate }}">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>
  <ng-container *ngIf="userHasAccessToRoutes$ | async else hideRoutes">
    <div class="row">
      <div class="col-lg-5 col-sm-12">
        <div class="card h-100">
          <div class="card-header">
            {{ 'translate.smot-detail.smotInfo.overviewSmotSpot' | translate }}
          </div>
          <div class="card-body" *ngIf="smot$ | async as smot">
            <h5 class="card-title"> {{smot?.locationDescription}} </h5>
            <h6 class="card-subtitle mb-2 text-muted"></h6>
            <p class="card-text">
            <ul>
              <li [hidden]="!smot?.logicalId">{{ 'translate.smot-detail.smotInfo.smotId' | translate }}: {{smot?.logicalId}}</li>
              <li [hidden]="!smot?.device?.backplate?.backplateId && isPartnerOrAdmin">{{ 'translate.smot-detail.smotInfo.bckId' | translate }}: {{smot?.device?.backplate?.backplateId}}</li>
              <li [hidden]="!smot?.device?.casingId && isPartnerOrAdmin">{{ 'translate.operations.shared.casingId' | translate }}: {{smot?.device.casingId}}</li>
              <li [hidden]="!smot?.device?.backplate?.iccid && isPartnerOrAdmin">{{ 'translate.smot-detail.smotInfo.iccid' | translate }}: {{smot?.device.backplate?.iccid}}</li>
              <li [hidden]="!smot?.seasonId && isPartnerOrAdmin">{{ 'translate.smot-detail.smotInfo.seasonId' | translate }}: {{smot?.seasonId}}</li>
              <li [hidden]="!smot?.packaging?.remainingVolume">{{ 'translate.smot-detail.smotInfo.remainingVolume' | translate }}: {{smot?.packaging?.remainingVolume}}l</li>
              <li [hidden]="!smot?.client?.clientName">{{ 'translate.smot-detail.smotInfo.client' | translate }}: {{smot?.client?.clientName}}</li>
              <li [hidden]="!smot?.battery">{{ 'translate.smot-detail.smotInfo.currentBatterylevel' | translate }}: {{smot?.battery}}</li>
              <li [hidden]="!smot?.activationDate">{{ 'translate.smot-detail.smotInfo.activationDate' | translate }}: {{smot?.activationDate}}</li>
              <li [hidden]="!smot?.lastRefill">{{ 'translate.smot-detail.smotInfo.lastRefill' | translate }}: {{smot?.lastRefill}}</li>
              <li [hidden]="!smot?.installationDate">{{ 'translate.smot-detail.smotInfo.installationDate' | translate }}: {{smot?.installationDate}}</li>
              <li [hidden]="!smot?.lastInit">{{ 'translate.smot-detail.smotInfo.lastInit' | translate }}: {{smot?.lastInit}}</li>
              <li [hidden]="!smot?.resetCounter">{{ 'translate.smot-detail.smotInfo.resetCounter' | translate }}: {{smot?.resetCounter}}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-7 mb-lg-0 col-sm-12 mb-4">
        <app-sundo-table
          [$dataSource]="smotRoutes$"
          [columns]="smotRouteColumns"
          [hideSearch]="true"
          [clickableRows]="true"
          (rowClicked)="navigateToRoute($event)"
          (dataChangeRequested)="handleDataChangeRequested($event)">
        </app-sundo-table>
      </div>
    </div>
  </ng-container>
  <ng-template #hideRoutes>
    <div class="col-12 mb-4 p-0">
      <div class="card h-100">
        <div class="card-header">
          {{ 'translate.smot-detail.smotInfo.overviewSmotSpot' | translate }}
        </div>
        <div class="card-body" *ngIf="smot$ | async as smot">
          <h5 class="card-title"> {{smot?.locationDescription}} </h5>
          <h6 class="card-subtitle mb-2 text-muted"></h6>
          <p class="card-text">
          <ul>
            <li [hidden]="!smot?.logicalId">{{ 'translate.smot-detail.smotInfo.smotId' | translate }}: {{smot?.logicalId}}</li>
            <li [hidden]="!smot?.device?.backplate?.backplateId && isPartnerOrAdmin">{{ 'translate.smot-detail.smotInfo.bckId' | translate }}: {{smot?.device?.backplate?.backplateId}}</li>
            <li [hidden]="!smot?.device?.casingId && isPartnerOrAdmin">{{ 'translate.operations.shared.casingId' | translate }}: {{smot?.device.casingId}}</li>
            <li [hidden]="!smot?.device?.backplate?.iccid && isPartnerOrAdmin">{{ 'translate.smot-detail.smotInfo.iccid' | translate }}: {{smot?.device.backplate?.iccid}}</li>
            <li [hidden]="!smot?.seasonId && isPartnerOrAdmin">{{ 'translate.smot-detail.smotInfo.seasonId' | translate }}: {{smot?.seasonId}}</li>
            <li [hidden]="!smot?.packaging?.remainingVolume">{{ 'translate.smot-detail.smotInfo.remainingVolume' | translate }}: {{smot?.packaging?.remainingVolume}}l</li>
            <li [hidden]="!smot?.client?.clientName">{{ 'translate.smot-detail.smotInfo.client' | translate }}: {{smot?.client?.clientName}}</li>
            <li [hidden]="!smot?.battery">{{ 'translate.smot-detail.smotInfo.currentBatterylevel' | translate }}: {{smot?.battery}}</li>
            <li [hidden]="!smot?.activationDate">{{ 'translate.smot-detail.smotInfo.activationDate' | translate }}: {{smot?.activationDate}}</li>
            <li [hidden]="!smot?.lastRefill">{{ 'translate.smot-detail.smotInfo.lastRefill' | translate }}: {{smot?.lastRefill}}</li>
            <li [hidden]="!smot?.installationDate">{{ 'translate.smot-detail.smotInfo.installationDate' | translate }}: {{smot?.installationDate}}</li>
            <li [hidden]="!smot?.lastInit">{{ 'translate.smot-detail.smotInfo.lastInit' | translate }}: {{smot?.lastInit}}</li>
            <li [hidden]="!smot?.resetCounter">{{ 'translate.smot-detail.smotInfo.resetCounter' | translate }}: {{smot?.resetCounter}}</li>
          </ul>
        </div>
      </div>
    </div>

  </ng-template>
  <div class="row">
        <div class="col-md-6 mb-md-0 col-sm-12 mb-4">
          <div class="card h-100">
            <div class="card-header">
              {{ 'translate.smot-detail.line-chart.sunscreen' | translate }}
            </div>
            <div class="card-body">
              <div class="chart-wrapper" *ngIf="sunscreenChartData$ | async as sunscreenData else sunscreenSpinner">
                <canvas baseChart
                        class="chart"
                        [datasets]="sunscreenData.datasets"
                        [labels]="sunscreenData.labels"
                        [options]="sunscreenData.options"
                        [chartType]="'line'">
                </canvas>
              </div>
              <ng-template #sunscreenSpinner>
                <mat-spinner></mat-spinner>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-md-0 col-sm-12">
          <div class="card h-100">
            <div class="card-header">
              {{ 'translate.smot-detail.line-chart.battery' | translate }}
            </div>
            <div class="card-body">
              <div class="chart-wrapper" *ngIf="batteryChartData$ | async as batteryChart else batterySpinner">
                <canvas baseChart
                        class="chart"
                        [datasets]="batteryChart.datasets"
                        [labels]="batteryChart.labels"
                        [options]="batteryChart.options"
                        [chartType]="'line'">
                </canvas>
              </div>
              <ng-template #batterySpinner>
                <mat-spinner></mat-spinner>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          {{ 'translate.smot-detail.bar-chart.issues' | translate }}
        </div>
        <div class="card-body">
          <div class="chart-wrapper" *ngIf="issueChartData$ | async as issueChart else issueSpinner">
            <canvas baseChart
                    class="chart"
                    [datasets]="issueChart.datasets"
                    [labels]="issueChart.labels"
                    [options]="issueChart.options"
                    (chartClick)="handleChartClick($event)"
                    [chartType]="'bar'">
            </canvas>
          </div>
          <ng-template #issueSpinner>
            <mat-spinner></mat-spinner>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          {{ 'translate.smot-detail.bar-chart.issues' | translate }}
        </div>
        <div class="card-body">
          <ng-container *ngIf="this.activeLabelFilter">
            <div class="filter">
              <span>
                {{'translate.dataTable.activeFilter' | translate}}: {{this.activeLabelFilter}}
              </span>
              <button class="clear-filter" (click)="clearFilter()">
                {{'translate.dataTable.clearFilter' | translate}}
                <mat-icon>cancel</mat-icon>
              </button>
            </div>

          </ng-container>
          <table mat-table [dataSource]="smotIssues" class="w-100" matSort>

            <!-- Type Column -->
            <ng-container matColumnDef="errorCode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Error </th>
              <td mat-cell *matCellDef="let element" > {{element.errorCode}} </td>
            </ng-container>

            <!-- Date Column -->
            <ng-container matColumnDef="timeOfError">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
              <td mat-cell *matCellDef="let element" > {{element.timeOfError * 1000 | date:'YYYY-MM-dd HH:mm:ss':'UTC+4' }} </td>
            </ng-container>

            <!-- Severity Column -->
            <ng-container matColumnDef="severity">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Severity </th>
              <td mat-cell *matCellDef="let element"> {{element.severity}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">{{'translate.dataTable.noDataFound' | translate}}</td>
            </tr>
          </table>
          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
