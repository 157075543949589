import {Component, OnInit} from '@angular/core';
import {SmotService} from '../../services/smot.service';
import {Role} from '../../models/role';
import {AuthenticationService} from '../../services/authentication.service';
import {ToastrNotificationService} from '../../services/toastr-notification.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {DatePipe} from '@angular/common';
import {DataRequest, TableColumn} from '../tables/table/table.component';
import {Sort} from '@angular/material/sort';
import {SmotMappings} from '../../models/dtoMappings';
import {MatDialog} from '@angular/material/dialog';
import {CreateSmotFormComponent} from '../forms/create-smot-form/create-smot-form.component';

@Component({
  selector: 'app-smot',
  templateUrl: './smot.component.html',
  styleUrls: ['./smot.component.css']
})
export class SmotComponent implements OnInit {
  smotTableData$: Observable<any[]>;
  columns: TableColumn[] = [];
  showDeinstalled: boolean = false;
  lastEventValues: DataRequest = {
    pageNumber: 0,
    pageSize: 10,
    searchString: '',
    sort: null,
  };
  constructor(private smotService: SmotService,
              private authService: AuthenticationService,
              private toastr: ToastrNotificationService,
              private translate: TranslateService,
              private router: Router,
              private datePipe: DatePipe,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.setHeaders();
    this.fetchSmots(0, 10, '', null);
  }

  public goToSmotDetails(smot) {
    this.router.navigate(['/smotspots/' + smot.logicalId]);
  }

  public handleDataChangeRequested(event: DataRequest) {
    this.lastEventValues = event;
    this.fetchSmots(event.pageNumber, event.pageSize, event.searchString, event.sort);
  }

  public handleFilterUpdate() {
    this.fetchSmots(0, this.lastEventValues.pageSize, this.lastEventValues.searchString, this.lastEventValues.sort);
  }

  private fetchSmots(page: number, size: number, search: string, sort: Sort) {
    this.smotTableData$ = this.smotService.getSmotsWithpagination(page, size, search, sort, this.showDeinstalled, SmotMappings.TABLE_SMOT_DTO).pipe(
      filter(x => x !== undefined),
      map((s: any) => {
        const charging = this.translate.instant('translate.smot.charging');
        s.content =  s.content.map(smot =>  ({
          'logicalId': smot.logicalId,
          'name': smot.locationDescription,
          'client': smot.clientName,
          'battery': smot.battery > 144 ? charging : smot.battery,
          'remaining': smot.remainingVolume ? smot.remainingVolume + 'L' : '',
          'brand': smot.sunscreenBrand,
          'pumps': smot.pumps,
          'lastInit': smot.lastInit ? this.datePipe.transform(smot.lastInit * 1000, 'dd-MM-YYY H:mm:ss') : '',
          'link': '#/smotspots/' + smot.logicalId
        }));
        return s;
      }));
  }

  private setHeaders() {
    const userRole = this.authService.getRole();
    // Add base headers
    if (window.screen.width > 500) {
      this.columns = [
        {
          header: 'id',
          field: 'logicalId',
          sortable: true,
          dbProperty: 'logical_id',
          textAlign: 'center'
        },
        {
          header: 'translate.smot.location',
          field: 'name',
          sortable: true,
          dbProperty: 'location_description',
          textAlign: 'left'
        },
        {
          header: 'translate.create.client',
          field: 'client',
          sortable: true,
          dbProperty: 'client_name',
          textAlign: 'left'
        },
        {
          header: 'translate.smot.battery',
          field: 'battery',
          sortable: true,
          dbProperty: 'battery',
          textAlign: 'center'
        },
        {
          header: 'translate.smot.remaining',
          field: 'remaining',
          sortable: true,
          dbProperty: 'remaining_volume',
          textAlign: 'center'
        },
        {
          header: 'translate.smot.pumps',
          field: 'pumps',
          sortable: true,
          dbProperty: 'pumps',
          textAlign: 'center'
        },
        {
          header: 'translate.smot.brand',
          field: 'brand',
          sortable: true,
          dbProperty: 'brand',
          textAlign: 'left'
        },
        {
          header: 'translate.smot-detail.smotInfo.lastInit',
          field: 'lastInit',
          sortable: true,
          dbProperty: 'last_init',
          textAlign: 'left'
        }
      ];
    } else {
      this.columns = [
        {
          header: 'id',
          field: 'logicalId',
          sortable: true,
          dbProperty: 'logical_id',
          textAlign: 'center'
        },
        {
          header: 'translate.smot.location',
          field: 'name',
          sortable: true,
          dbProperty: 'location_description',
          textAlign: 'left'
        },
        {
          header: 'translate.create.client',
          field: 'client',
          sortable: true,
          dbProperty: 'client_name',
          textAlign: 'left'
        },
        {
          header: '<img height="20" width="20" src="../../../assets/img/table-icons/battery.png">',
          field: 'battery',
          sortable: true,
          dbProperty: 'battery',
          textAlign: 'center'
        },
        {
          header: '<img height="20" width="20" src="../../../assets/img/table-icons/sunscreen.png">',
          field: 'remaining',
          sortable: true,
          dbProperty: 'remaining_volume',
          textAlign: 'center'
        },
        {
          header: 'translate.smot.pumps',
          field: 'pumps',
          sortable: true,
          dbProperty: 'pumps',
          textAlign: 'center'
        },
        {
          header: 'translate.smot.brand',
          field: 'brand',
          sortable: true,
          dbProperty: 'brand',
          textAlign: 'left'
        },
        {
          header: 'translate.smot-detail.smotInfo.lastInit',
          field: 'lastInit',
          sortable: true,
          dbProperty: 'last_init',
          textAlign: 'left'
        }
      ];
    }
    // Add edit if Admin or Partner
    if (userRole === Role.ADMIN || userRole === Role.PARTNER) {
      if (window.screen.width > 500) {
        this.columns.push(
          {
            header: 'translate.general.edit',
            field: 'edit',
            textAlign: 'center'
          });
      } else {
        this.columns.push(
          {
            header: '',
            field: 'edit',
            textAlign: 'center'
          });
      }
    }
  }
}
