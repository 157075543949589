import {Injectable} from '@angular/core';
import {ApiHelperService} from './api-helper.service';
import {environment} from '../../environments/environment';
import {OperationService} from './operation.service';
import {from, Observable} from 'rxjs';
import {concatMap} from 'rxjs/operators';
import {SmotDTO} from '../dtos/SmotDTOs/smotDTO';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  private operationUrl = environment.apiRoot + '/fileUpload';

  constructor(private apiHelper: ApiHelperService,
              private operationService: OperationService) {
  }

  uploadImage(file, url) {
    const endpointUrl = this.operationUrl + url;
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.apiHelper.postRequest<any>(endpointUrl, formData);
  }

  uploadSmotProfilePicture(file, smot: SmotDTO) {
    const endpointUrl =  this.operationUrl + '/smot/' + smot.logicalId;
    const formData: FormData = new FormData();
    formData.append('file', file);
    this.operationService.removeOperationSmot();
    return this.apiHelper.postRequest<any>(endpointUrl, formData);
  }

  uploadUserAvatar(file, username) {
    const endpointUrl = this.operationUrl + '/user/' + username;
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.apiHelper.postRequest<any>(endpointUrl, formData);
  }

  uploadReportProblemPicture(file, seasonId: number, suffix: string) {
    const endpointUrl =  `${this.operationUrl}/problem/${seasonId}?suffix=${suffix}`;
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.apiHelper.postRequestWithoutToken<any>(endpointUrl, formData);
  }

  uploadOperationsPicture(file, logical_id: number, suffix: 'before' | 'after' | 'repair') {
    const endpointUrl =  this.operationUrl + '/operation/' + logical_id + '?suffix=' + suffix;
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.apiHelper.postRequest<any>(endpointUrl, formData);
  }

  uploadRouteImages(files, stepId, routeId) {
    return from(files).pipe(
      concatMap((file, index) => this.uploadRouteImage(file, stepId, routeId, index)),
    );
  }

  deleteRouteImage(routeId: number, stepId: number, filename: String) {
    const endpointUrl = `${this.operationUrl}/routes/${routeId}/${stepId}/${filename}`;
    return this.apiHelper.deleteRequest(endpointUrl, null);
  }

  uploadRouteImage(file, stepId, routeId, index): Observable<any> {
    const endpointUrl = `${this.operationUrl}/routes/${routeId}/${stepId}?index=${index}`;
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.apiHelper.postRequest<any>(endpointUrl, formData);
  }
}
