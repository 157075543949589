  <div class="sundo-container medium m-auto h-100 position-relative">
    <ng-container *ngIf="currentStep === 'configs'">
      <app-h2 class="text-center">SMOTSpot aanmaken</app-h2>
      <app-h3 class="text-center">Kies een configuratie</app-h3>
      <div class="search">
        <app-input-field [control]="searchControl"></app-input-field>
      </div>
      <div class="config-list">
        <ng-container *ngIf="filteredConfigurations$ | async as configurations else loadingConfigList">
          <ng-container *ngFor="let configuration of configurations">
            <div class="config-item"
                 [class.selected]="smot?.logicalId === configuration?.logicalId"
                 (click)="smot = configuration">
              <div class="config-item-left">
                <div class="location-description">
                  {{configuration.locationDescription}}
                </div>
                <div>
                  {{configuration.clientVariant?.clientVariantIdentifier}}
                </div>
              </div>
              <div class="config-item-right">
                <div>
                  {{configuration.client?.clientName}}
                </div>
                <div>
                  {{configuration.language}}
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #loadingConfigList>
          <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
        </ng-template>
      </div>
      <app-success-button [disabled]="!smot" [fullWidth]="true" (click)="goToDeviceStep()">{{'translate.form.next' | translate}}</app-success-button>
      <app-warning-button [fullWidth]="true" (click)="goToCreateConfigStep()">Nieuwe configuratie aanmaken</app-warning-button>
    </ng-container>
    <ng-container *ngIf="currentStep === 'createConfig'">
      <app-create-configuration [componentUsedAsChild]="true" (createdConfiguration)="handleConfigurationCreated($event)"></app-create-configuration>
    </ng-container>
    <ng-container *ngIf="currentStep === 'device'">
      <app-h2 class="text-center">Scan een toestel met de volgende configuratie</app-h2>
      <div class="overview">
        <div class="d-flex justify-content-between ">
          <span>Klant:</span> <span [class.incompatible]="device && smot.client.clientName !== device.customCasing?.clientName">{{smot.client?.clientName}}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>Variant:</span> <span [class.incompatible]="device && smot.clientVariant?.clientVariantId !== device.customCasing?.clientVariantId">{{smot.clientVariant?.clientVariantIdentifier}}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>Taal:</span> <span [class.incompatible-warning]="device && smot.language !== device.customCasing?.language">{{smot.language}}</span>
        </div>
      </div>
      <app-js-qr-scanner (codeScanned)="handleDeviceCodeScanned($event)"
        [badScan]="badScan"
        [processingScan]="processingScan">
      </app-js-qr-scanner>
      <app-warning-button [fullWidth]="true" (click)="currentStep = 'configs'">Kies een andere configuratie</app-warning-button>
      <app-warning-button [fullWidth]="true" (click)="currentStep = 'createDevice'">Maak een toestel aan</app-warning-button>
    </ng-container>
    <ng-container *ngIf="currentStep === 'createDevice'">
      <app-create-device (createdDevice)="handleDeviceCreated($event)" [componentUsedAsChild]="true"></app-create-device>
    </ng-container>
    <ng-container *ngIf="currentStep === 'checkInfoPanel'">
      <app-h2 class="text-center">Wens je een info bord toe te voegen?</app-h2>
      <div class="buttons d-flex justify-content-between">
        <app-success-button [size]="'small'" (click)="addInfoPanelToCustomCasing()">{{'translate.form.yes' | translate}}</app-success-button>
        <app-danger-button [size]="'small'" (click)="currentStep='overview'">{{'translate.form.no' | translate}}</app-danger-button>
      </div>
    </ng-container>
    <ng-container *ngIf="currentStep === 'overview'">
      <div class="overview">
        <div class="d-flex justify-content-between ">
          <span>Naam:</span> <span>{{smot.locationDescription}}</span>
        </div>
        <div class="d-flex justify-content-between ">
          <span>Klant:</span> <span>{{smot.client?.clientName}}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>Variant:</span> <span>{{smot.clientVariant?.clientVariantIdentifier}}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>Taal:</span> <span>{{smot.language}}</span>
        </div>
        <div class="d-flex justify-content-between ">
          <span>Casing Taal:</span> <span>{{smot.device?.customCasing?.language}}</span>
        </div>
        <div class="d-flex justify-content-between ">
          <span>Tijd:</span> <span>{{smot.wakeUpTime + ':00 - ' + smot.sleepTime + ':00'}}</span>
        </div>
        <div class="d-flex justify-content-between ">
          <span>Evenement:</span> <span>{{smot.isEvent ? 'Ja' : 'Nee'}}</span>
        </div>
        <div class="d-flex justify-content-between ">
          <span>Onderhoud:</span> <span>{{smot.isOwnMaintenance ? 'Door de Klant' : 'Door SUNDO'}}</span>
        </div>
        <div class="d-flex justify-content-between ">
          <span>Device ID:</span> <span>{{smot.device?.deviceId}}</span>
        </div>
      </div>
      <app-warning-button [fullWidth]="true" (click)="currentStep = 'device'">Scan een ander toestel</app-warning-button>
      <app-success-button [fullWidth]="true" (click)="saveSmot()">{{'translate.form.submit' | translate}}</app-success-button>
    </ng-container>
  </div>

