<app-h1 mat-dialog-title>{{'translate.dataTable.moreInfo' | translate}}</app-h1>
<!--<div mat-dialog-content>-->

<!--  Single image popup -->
  <div *ngIf="operationImage$ | async as image; else loading">
    <img *ngIf="onlyShowOperationImage" [src]="image" alt="image related to operation">
  </div>
  <ng-template #loading>
    <ng-container *ngIf="!operationImageLoaded else emptyOperation">
      <mat-spinner></mat-spinner>
    </ng-container>
    <ng-template #emptyOperation>
      <app-h5 class="m-4" *ngIf="onlyShowOperationImage">{{ 'translate.general.noImagesFound' | translate }}</app-h5>
    </ng-template>
  </ng-template>
<!-- Before and after image popup -->
  <div class="before-after-images row" *ngIf="!onlyShowOperationImage">
    <div id="before-image" class="col-12 col-sm-6" *ngIf="beforeImage$ | async as before; else loading">
            <app-h4>{{'translate.operations.info-modal.before' | translate}}</app-h4>
            <img style="object-fit: contain; width: 100%" *ngIf="before" [src]="before" alt="image before the operation took place">
    </div>
    <ng-template #loading>
      <ng-container *ngIf="!beforeImageLoaded else emptyBefore">
        <mat-spinner></mat-spinner>
      </ng-container>
      <ng-template #emptyBefore>
        <app-h5 class="m-4" *ngIf="!onlyShowOperationImage">{{ 'translate.general.noImagesFound' | translate }}</app-h5>
      </ng-template>
    </ng-template>
    <div></div>
    <div id="after-image" class="col-12 col-sm-6" *ngIf="afterImage$ | async as after; else loading">
            <app-h4>{{'translate.operations.info-modal.after' | translate}}</app-h4>
            <img style="object-fit: cover; width: 100%" *ngIf="after" [src]="after" alt="image after the operation took place">
    </div>
    <ng-template #loading>
      <ng-container *ngIf="!afterImageLoaded else emptyAfter">
        <mat-spinner></mat-spinner>
      </ng-container>
      <ng-template #emptyAfter>
        <app-h5 class="m-4" *ngIf="!onlyShowOperationImage">{{ 'translate.general.noImagesFound' | translate }}</app-h5>
      </ng-template>
    </ng-template>
  </div>
<!--</div>-->
