import {Injectable} from '@angular/core';
import {ApiHelperService} from './api-helper.service';
import {environment} from '../../environments/environment';
import {MaintenanceDTO} from '../dtos/MaintenanceDTO';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  private maintenanceURL = environment.apiRoot + '/maintenance';

  constructor(private apiHelper: ApiHelperService) { }

  public getMaintenanceBySmotLogicalId(smotId) {
    const url = `${this.maintenanceURL}/smot/${smotId}`;
    return this.apiHelper.getRequest<MaintenanceDTO[]>(url);
  }

  public getMaintenanceByRouteId(routeId) {
    const url = `${this.maintenanceURL}/route/${routeId}`;
    return this.apiHelper.getRequest<MaintenanceDTO[]>(url);
  }

  public removeMaintenanceById(maintenanceId) {
    const url = `${this.maintenanceURL}/${maintenanceId}`;
    return this.apiHelper.deleteRequest(url, null);
  }

  public updateMaintenance(maintenanceId: number, maintenanceDTO: MaintenanceDTO) {
    const url = `${this.maintenanceURL}/${maintenanceId}`;
    return this.apiHelper.putRequest(url, maintenanceDTO);
  }
}
