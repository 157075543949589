import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-action-modal',
  template: `
    <div class="row text-center">
      <app-h4 class="mx-4">{{question}}</app-h4>
    </div>
    <div [innerHTML]="html"></div>
    <div class="d-flex row justify-content-around mt-4">
        <app-primary-button data-cy="confirm-action" (click)="confirm()" class="mx-3">{{yes}}</app-primary-button>
        <app-danger-button data-cy="cancel-action" (click)="cancel()"  class="mx-3">{{no}}</app-danger-button>
    </div>`,
  styles: []
})
export class ConfirmActionModalComponent implements OnInit {

  @Input()
  public key: string;

  question: string;
  yes: string;
  no: string;

  html: string;

  constructor(public dialogref: MatDialogRef<ConfirmActionModalComponent>,
              private translate: TranslateService,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.key = data.key;
    this.html = data.html;
    this.yes = this.translate.instant('translate.form.yes');
    this.no = this.translate.instant('translate.form.no');
    this.question = this.translate.instant(this.key);
  }


  ngOnInit(): void {
  }

  cancel() {
    this.dialogref.close(false);
  }

  confirm() {
    this.dialogref.close(true);
  }
}
