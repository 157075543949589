import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {SmotDetailComponent} from './smot-detail.component';

const routes: Routes = [
  {
    path: ':id',
    component: SmotDetailComponent,
    data: {
      title: 'Smot detail'
    }
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SmotDetailRoutingModule { }
