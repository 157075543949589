<div class="d-flex justify-content-center align-items-center" style="height: 100vh;">
  <div class="sundo-container">
    <app-h4 class="text-center">{{'translate.settings.changePassword.enterNewPassword' | translate}}</app-h4>
    <form (ngSubmit)="changePassword()">
      <app-input-field
        [label]="'translate.settings.changePassword.newPassword' | translate"
        [control]="password"
        [type]="'password'"
      ></app-input-field>
      <app-input-field
        [control]="confirmPassword"
        [label]="'translate.settings.changePassword.confirmNewPassword' | translate"
        [type]="'password'"
      ></app-input-field>
      <div class="error-message-minimal" *ngIf="form.valid && confirmPassword.value !== password.value">{{'translate.settings.changePassword.passwordsDoNotMatch' | translate}}</div>
      <app-primary-button [disabled]="form.invalid || confirmPassword.value !== password.value" [fullWidth]="true" type="submit">{{'translate.form.submit' | translate}}</app-primary-button>
    </form>
  </div>
</div>
