import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import jwtDecode from 'jwt-decode';
import 'rxjs-compat/add/operator/do';
import 'rxjs-compat/add/operator/shareReplay';
import {filter, switchMap, tap} from 'rxjs/operators';
import {Role} from '../models/role';
import {JwtHelperService} from '@auth0/angular-jwt';
import {UserDTO} from '../dtos/UserDTOs/userDTO';
import {Observable} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';
import {UserService} from './user.service';
import {UserAuthenticationDTO} from '../dtos/UserDTOs/userAuthenticationDTO';
import {UserMappings} from '../models/dtoMappings';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private loggedInUser$: Observable<UserDTO> = of(undefined);
  private loggedInUserAuth$: Observable<UserAuthenticationDTO> = of(undefined);

  constructor(private http: HttpClient,
              private userService: UserService) {}

  JWT_TOKEN_KEY = 'access_token';
  ROLE_KEY = 'role';
  jwtHelper = new JwtHelperService();

  /**
   *
   * @param role The role from which you want the role number
   * @private Returns the role number of the role
   */
  private static getRoleNumberByRole(role) {
    switch (role) {
      case 'ADMIN':
        return Role.ADMIN;

      case 'PRODUCER':
        return Role.PRODUCER;

      case 'PRODUCERADMIN':
        return Role.PRODUCERADMIN;

      case 'STAFF':
        return Role.STAFF;

      case 'VIEWER':
        return Role.VIEWER;

      case'FACILITATORADMIN':
        return Role.FACILITATORADMIN;

      case 'SYSTEM':
        return Role.SYSTEM;

      case 'PARTNER':
        return Role.PARTNER;
    }
  }

  private static getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (Error) {
      return null;
    }
  }

  login(username: string, password: string) {
    const _this = this;
    const cred = `{"username" : "${username}", "pwd": "${password}"}"`;
    return this.http.post<any>(environment.apiLoginUrl, cred, {observe: 'response'})
      .pipe(
        filter(x => x !== undefined),
        tap(resp => {
          const authToken = resp.headers.get('Authorization');
          if (authToken !== undefined) {
            const token = authToken.split(' ')[1];
            // TODO verify token and switch to RSA256
            _this.setSession(token);
          }
      }));
  }

  private setSession(token) {
    localStorage.setItem(this.JWT_TOKEN_KEY, token);
  }

  public setSessionRole(role) {
    const roleNumber = AuthenticationService.getRoleNumberByRole(role);
    localStorage.setItem(this.ROLE_KEY, roleNumber);
  }

  logout() {
    localStorage.removeItem(this.JWT_TOKEN_KEY);
    localStorage.removeItem(this.ROLE_KEY);
  }

  public isLoggedIn() {
    const token = localStorage.getItem(this.JWT_TOKEN_KEY);
    const role = localStorage.getItem(this.ROLE_KEY);
    if ( token == null || role == null ) {
      this.logout();
      return false;
    }

    if (this.jwtHelper.isTokenExpired(token)) {
      this.logout();
      return false;
    }
    return true;
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getUsername(): string {
    const token = localStorage.getItem(this.JWT_TOKEN_KEY);
    return AuthenticationService.getDecodedAccessToken(token).sub;
  }

  getRole(): string {
    return localStorage.getItem(this.ROLE_KEY);
  }

  public getLoggedInUser(): Observable<UserDTO> {
    return this.loggedInUser$.pipe(
      switchMap(loggedInUser => {
        if (loggedInUser) {
          return of(loggedInUser);
        } else {
          return this.userService.getUser<UserDTO>(this.getUsername()).pipe(
            tap(user => this.loggedInUser$ = of(user))
          );
        }
      }),
    );
  }

  public getLoggedInUserAuth(): Observable<UserAuthenticationDTO> {
    return this.loggedInUserAuth$.pipe(
      switchMap(loggedInUser => {
        if (loggedInUser) {
          return of(loggedInUser);
        } else {
          return this.userService.getUser<UserAuthenticationDTO>(this.getUsername(), false, UserMappings.USER_AUTHENTICATION_DTO).pipe(
            tap(user => this.loggedInUserAuth$ = of(user))
          );
        }
      }),
    );
  }
}
