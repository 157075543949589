import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {ApiHelperService} from './api-helper.service';
import {Observable} from 'rxjs';
import {FirmwareDTO} from '../dtos/firmwareDTO';

@Injectable({
  providedIn: 'root'
})
export class FirmwareService {
  private firmwareUrl = environment.apiRoot + '/firmware/';
  constructor(private helper: ApiHelperService) { }

  getActiveFirmwareVersion(): Observable<FirmwareDTO> {
    const url = this.firmwareUrl + 'active';
    return this.helper.getRequest(url);
  }
}
