<div class="sundo-container medium m-auto h-100 position-relative">
  <div class="back-button" (click)="goToPreviousStep()">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </div>
  <ng-container *ngIf="!showScanner else scanner">
    <app-h2 class="text-center">{{ 'translate.operations.smot-repair.whatPartRepair' | translate }}</app-h2>
    <div id="part-dropdown">
      <app-drop-down-field [options$]="parts$" [control]="partControl" [hideLabel]="true"
                           [label]="'translate.operations.smot-repair.chooseAPart'"></app-drop-down-field>
    </div>
  </ng-container>

  <ng-template #scanner>
    <ng-container>
      <app-h2 class="text-center">
        <ng-container *ngIf="partControl.value === 'Backplate' else casing">
            {{ 'translate.operations.shared.scanQRonBackplate' | translate }}
        </ng-container>

        <ng-template #casing>
            {{ 'translate.operations.shared.scanQRInsideCasing' | translate }}
        </ng-template>
      </app-h2>
      <app-js-qr-scanner [badScan]="badScan"
                         (codeScanned)="scanCompleteHandler($event)"
      ></app-js-qr-scanner>
    </ng-container>
  </ng-template>
  <div class="buttons">
    <app-success-button [disabled]="!partControl.value" [fullWidth]="true"
                        (click)="checkRepairPart()">{{ 'translate.form.submit' | translate }}
    </app-success-button>
  </div>
</div>
