import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {PageEvent} from '@angular/material/paginator';
import {FormControl, Validators} from '@angular/forms';
import {ValidationFormsService} from '../../forms/validation-forms/validation-forms.service';
import {debounceTime, filter, startWith, tap} from 'rxjs/operators';
import {Sort} from '@angular/material/sort';

@Component({
  selector: 'app-sundo-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnChanges, OnInit {
  //region Input
  @Input() $dataSource: Observable<any> = new Observable<any>();
  @Input() columns: TableColumn[] = [];
  @Input() allowCreate: boolean = false;
  @Input() hideSearch: boolean = false;
  @Input() initialPageSize: number = 10;
  @Input() searchControl: FormControl = new FormControl('', [Validators.pattern(this.vf.formRules.ignoreBackSlash)]);
  @Input() clickableRows: boolean = false;
  //endregion

  //region Output
  @Output() dataChangeRequested = new EventEmitter<DataRequest>();
  @Output() rowClicked = new EventEmitter();
  @Output() deleteClicked = new EventEmitter();
  @Output() editClicked = new EventEmitter();
  @Output() createClicked = new EventEmitter();
  @Output() infoClicked = new EventEmitter();
  @Output() statusClicked = new EventEmitter();
  //endregion

  displayedColumns: string[] = [];

  //region Observables
  private pageNumberChangedSubject = new BehaviorSubject(0);
  $pageNumber = this.pageNumberChangedSubject.asObservable();

  private pageSizeChangedSubject = new BehaviorSubject(this.initialPageSize);
  $pageSize: Observable<number> = this.pageSizeChangedSubject.asObservable();

  private sortChangedSubject: BehaviorSubject<Sort> = new BehaviorSubject(null);
  $sortChanged: Observable<Sort> = this.sortChangedSubject.asObservable();
  //endregion Observables

  activeSort: Sort;


  constructor(private vf: ValidationFormsService) {}

  ngOnInit(): void {
    this.pageSizeChangedSubject.next(this.initialPageSize);
    this.displayedColumns = this.columns.map(c => c.dbProperty ?? c.field);
    this.searchControl.markAsTouched();
    this.observePaginator();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['columns']) {
      this.displayedColumns = this.columns.map(c => c.field);
    }
  }

  handleSortChange(event: Sort) {
    this.activeSort = event;
    this.sortChangedSubject.next(event);
  }

  handlePageChange(event: PageEvent) {
    this.pageSizeChangedSubject.next(event.pageSize);
    this.pageNumberChangedSubject.next(event.pageIndex);
  }

  //region Emit Events
  emitRowClicked(event: any) {
    if (this.clickableRows) {
      this.rowClicked.emit(event);
    }
  }

  emitDeleteClicked(event: any) {
    this.deleteClicked.emit(event);
  }

  emitEditClicked(event: any) {
    this.editClicked.emit(event);
  }

  emitInfoClicked(event: any) {
    this.infoClicked.emit(event);
  }

  emitStatusClicked(event: any) {
    this.statusClicked.emit(event);
  }


  emitCreateClicked() {
    this.createClicked.emit();
  }
  //endregion

  private observePaginator() {
    const searchControl$ = this.searchControl.valueChanges
      .pipe(
        debounceTime(300),
        startWith(''),
        tap(() => {
          this.pageNumberChangedSubject.next(0);
        }));
    combineLatest([this.$pageNumber, this.$pageSize, searchControl$, this.$sortChanged]).pipe(
      filter(() => this.searchControl.valid),
      tap(([pageNumber, pageSize, search, sort]) => this.dataChangeRequested.emit({pageNumber: pageNumber, pageSize: pageSize, searchString: search, sort}))
    ).subscribe();
  }
}

export interface TableColumn {
  field: string;
  header: string;
  sortable?: boolean;
  dbProperty?: string;
  textAlign: 'left' | 'center';
}

export interface DataRequest {
  pageNumber: number;
  pageSize: number;
  searchString: string;
  sort: Sort;
}
