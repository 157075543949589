<div class="position-relative d-flex justify-content-center">
  <div class="video-loading-overlay" *ngIf="processingScan && showQRScanner">
    <app-h4 class="center" [color]="'white'">{{'translate.general.loadingDotDotDot' | translate}}</app-h4>
  </div>
  <video [style.display]="showQRScanner ? 'initial' : 'none'" #videoElement
         [class.invalid]="badScan"
  ></video>
</div>


<div class="d-flex justify-content-center mb-3">
  <ng-container *ngIf="!showQRScanner else displayButton">
    <app-warning-button (click)="showQRScanner = true">{{ 'translate.showQRCodeScanner' | translate }}</app-warning-button>
  </ng-container>
  <ng-template #displayButton>
    <app-warning-button (click)="showQRScanner = false">{{ 'translate.hideQRCodeScanner' | translate }}</app-warning-button>
  </ng-template>
</div>

<app-input-field
  [class.invalid]="badScan"
  [hideLabel]="true"
  [autoFocus]="true"
  [placeholder]="'QR Code'"
  (enteredInput)="emitScan($event)"
  #scanInput
></app-input-field>
<p class="error-message-minimal w-100 text-center" *ngIf="badScan">
  {{ 'translate.operations.shared.badQRCodeScanned' | translate }}
</p>
<app-success-button [loading]="processingScan" [fullWidth]="true" (click)="emitScan(scanInput.control.value)">{{ 'translate.form.next' | translate }}</app-success-button>

