import {Component, OnInit} from '@angular/core';
import {SmotService} from '../../../services/smot.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FileUploadService} from '../../../services/file-upload.service';
import {ToastrNotificationService} from '../../../services/toastr-notification.service';
import {TranslateService} from '@ngx-translate/core';
import {ImageCreateService} from '../../../services/image-create.service';
import {EMPTY, Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {AuthenticationService} from '../../../services/authentication.service';
import {DeviceService} from '../../../services/device.service';
import {OperationService} from '../../../services/operation.service';
import {UserService} from '../../../services/user.service';
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ValidationFormsService} from '../../forms/validation-forms/validation-forms.service';
import {languages} from '../../lng-picker/lng-picker.component';
import {ConfirmActionModalComponent} from '../../plugins/confirm-action-modal/confirm-action-modal.component';
import {Role} from '../../../models/role';
import {EditImgModalComponent} from '../../plugins/edit-img-modal/edit-img-modal.component';
import {DeviceDTO} from '../../../dtos/deviceDTO';
import {FirmwareService} from '../../../services/firmware.service';
import {of} from 'rxjs/internal/observable/of';
import {SmotAccessDTO} from '../../../dtos/smotAccessDTO';
import {ActionDTO} from '../../../dtos/OperationDTOs/actionDTO';
import {SmotDTO} from '../../../dtos/SmotDTOs/smotDTO';
import {IDropdownOption} from '../../../components/form-fields/drop-down-field/drop-down-field.component';
import {InputModalComponent} from '../../plugins/input-modal/input-modal.component';
import {CommentType} from '../../../models/commentType';
import {ResolutionStatus} from '../../../models/resolutionStatus';
import {CreateRouteStepCommentDTO} from '../../../dtos/RouteDTOs/createRouteDTO';
import {RouteService} from '../../../services/route.service';
import {UserDTO} from '../../../dtos/UserDTOs/userDTO';
import moment from 'moment';
import {CreateOperationDTO} from '../../../dtos/OperationDTOs/createOperationDTO';

@Component({
  selector: 'app-view-smot',
  templateUrl: './view-smot.component.html',
  styleUrls: ['./view-smot.component.scss']
})
export class ViewSmotComponent implements OnInit {
  canAccessDetails = false;

  // Observables
  smot$: Observable<any>;
  smotImage$: Observable<any>;
  device$: Observable<DeviceDTO>;
  user$: Observable<UserDTO>;
  smotId: number;

  // Form Group
  smotForm: FormGroup;

  // Form Controls
  smotNameControl = new FormControl(null, [Validators.required, Validators.pattern(this.vf.formRules.nonEmpty)]);
  languageControl = new FormControl(null, [Validators.required]);
  eventControl = new FormControl(null, [Validators.required]);
  startTimeControl = new FormControl(null, [Validators.required]);
  sleepTimeControl = new FormControl(null, [Validators.required]);
  notificationControl = new FormControl(0, [Validators.required]);
  isOwnMaintenanceControl = new FormControl(null);

  // Select
  supportedLanguages$: Observable<IDropdownOption[]>;

  notificationSettingColor: string = 'grey';
  notificattionSettingValue: number = 2;

  saveIsLoading: boolean = false;
  actionIsLoading: boolean = false;

  constructor(private smotService: SmotService,
              private route: ActivatedRoute,
              private router: Router,
              private routeService: RouteService,
              private fileUploadService: FileUploadService,
              private imageCreateService: ImageCreateService,
              private vf: ValidationFormsService,
              private toastr: ToastrNotificationService,
              private translate: TranslateService,
              private dialog: MatDialog,
              private auth: AuthenticationService,
              private deviceService: DeviceService,
              private operationService: OperationService,
              private userService: UserService,
              private firmwareService: FirmwareService) {
    this.route.params.pipe(
      tap(params => this.smotId = +params['id'])
    ).subscribe();
  }

  ngOnInit(): void {
    this.checkIfUserCanAccessDetails();
    this.fillLanguageSelect();
    this.createForm();
    this.getUser();
    this.getSmot();
    this.getSmotAccess();
    this.getSmotImage();
  }

  public cancel() {
      this.router.navigate(['/smotspots']);
  }

  //region Smot Actions
  public saveSmot(smot: SmotDTO) {
    if (this.smotForm.invalid) { return; }

    // Start loading animation
    this.saveIsLoading = true;

    smot = this.updateSmot(smot);

    this.smotService.saveSmot(smot).pipe(
      filter(x => x !== undefined),
      tap(() => {
            this.toastr.showSucces(this.translate.instant('translate.general.saveSuccesful'), this.translate.instant('translate.general.succes'));
            this.saveIsLoading = false;
            this.router.navigate(['/smotspots']);
      }),
      catchError(error => {
        this.toastr.showErrorBasedOnStatus(error.status);
        this.saveIsLoading = false;
        return EMPTY;
      })
    ).subscribe();

    const username = this.auth.getUsername();
    this.userService.getUserSmotAccessForSmotWithId(username, smot.logicalId).pipe(
      filter(x => x !== undefined),
      map(smotAccess => ({
        ...smotAccess,
        mailType: this.notificationControl.value,
        visible: true,
      }) as SmotAccessDTO),
      switchMap(smotAccess => this.userService.updateUserSmotAccess(username, [smotAccess]))
    ).subscribe();
  }

  displayAddCommentModal(smot: SmotDTO, isInfo: boolean = false) {
    const dialog = this.dialog.open(InputModalComponent, {
      data: {
        key: isInfo ?  'translate.operations.smot-maintenance.addInfoToThisSMOTSpot'
          : 'translate.operations.smot-maintenance.addACommentToThisSMOTSpot',
        multiline: true
      }
    });

    dialog.afterClosed().pipe(
      filter(x => x !== undefined),
      switchMap(comment => {
        const commentDTO = {
          type: isInfo ? CommentType.INFO : CommentType.COMMENT,
          smotLogicalId: smot.logicalId,
          content: comment,
          resolutionStatus: ResolutionStatus.UNRESOLVED,
          creationDate: moment().utc()
        } as CreateRouteStepCommentDTO;

        return this.routeService.createComment(commentDTO).pipe(
          filter(x => x !== undefined),
          tap(() => this.toastr.showSucces(this.translate.instant('translate.routes.addCommentSuccessful'), 'Success'))
        );
      })
    ).subscribe();
  }

  getColorBasedOnMailType() {
    switch (this.notificationControl.value) {
      case 0:
        this.notificationSettingColor = 'grey';
        return;
      case 1:
        this.notificationSettingColor = '#F9B92F';
        return;
      case 2:
        this.notificationSettingColor = '#1BCB71';
        return;
    }
  }

  rebootSmot(device: DeviceDTO) {
    this.actionIsLoading = true;

    const params: ActionDTO = {
      iccid: device.backplate.iccid,
      overrideId: '9'
    };

    this.smotService.performSmotAction(params).pipe(
      filter(x => x !== undefined),
      tap(() => this.toastr.showSucces(this.translate.instant('translate.operations.view-smot.rebootWasSuccessful'), this.translate.instant('translate.general.succes'))),
      tap(() => this.actionIsLoading = false),
      catchError(error => {
        this.toastr.showErrorBasedOnStatus(error.status);
        this.actionIsLoading = false;
        return EMPTY;
      })
    ).subscribe();
  }

  activateDispenser(device: DeviceDTO, smot: SmotDTO) {
    this.actionIsLoading = true;

    const params: ActionDTO = {
      iccid: device.backplate.iccid,
      overrideId: '19'
    };

    this.smotService.performSmotAction(params).pipe(
      filter(x => x !== undefined),
      tap(() => this.actionIsLoading = false),
      catchError(error => {
        this.toastr.showErrorBasedOnStatus(error.status);
        this.actionIsLoading = false;
        return EMPTY;
      })
    ).subscribe();

     this.activateSmot(smot);
  }

  deactivateDispenser(device: DeviceDTO, smot: SmotDTO) {
    this.actionIsLoading = true;

      const params: ActionDTO = {
        iccid: device.backplate.iccid,
        overrideId: '2'
      };

      this.smotService.performSmotAction(params).pipe(
        filter(x => x !== undefined),
        tap(() => this.actionIsLoading = false),
        catchError(error => {
          this.toastr.showErrorBasedOnStatus(error.status);
          this.actionIsLoading = false;
          return EMPTY;
        })
      ).subscribe();

      this.deactivateSmot(smot);
  }

  reinstallSmot(device: DeviceDTO, smot: SmotDTO) {
    this.actionIsLoading = true;

      const params = {
        iccid: device.backplate.iccid,
        env: 'PROD',
        language: smot.language,
        firmware: '',
        eventCounter: smot.lastEventCounter
      };

      this.smotService.performEnvironmentSwitch(params).pipe(
        filter(x => x !== undefined),
        tap(() => this.toastr.showSucces(this.translate.instant('translate.operations.view-smot.smotSuccesfullyInstalled'), this.translate.instant('translate.general.succes'))),
        tap(()  => {
          this.actionIsLoading = false;
          smot.isActive = 1;
        }),
        catchError(error => {
          this.toastr.showErrorBasedOnStatus(error.status);
          this.actionIsLoading = false;
          return EMPTY;
        })
      ).subscribe();
  }

  updateFirmware(device: DeviceDTO) {
    this.actionIsLoading = true;

    this.firmwareService.getActiveFirmwareVersion().pipe(
      filter(x => x !== undefined),
      tap(firmware => {
        const params: ActionDTO = {
          iccid: device.backplate.iccid,
          overrideId: '15',
          path: firmware.firmwareName
        };

        this.smotService.performSmotAction(params).pipe(
          filter(x => x !== undefined),
          tap(() => this.toastr.showSucces('Successfully updated SMOTSpot firmware', 'Success')),
          tap(() => this.actionIsLoading = false),
          catchError(error => {
            this.actionIsLoading = false;
            return EMPTY;
          })
        ).subscribe();
      }),
      catchError(error => {
        this.actionIsLoading = false;
        return EMPTY;
      })
    ).subscribe();
  }


  showConfirmDeinstallModal(smot: SmotDTO) {
      const dialog = this.dialog.open(ConfirmActionModalComponent, {
        data: {
          key: 'translate.form.areYouSureYouWantToPerformThisAction'
        }
      });

      dialog.afterClosed().pipe(
        filter(x => x !== undefined && x === true),
        tap(() => this.deinstallSmot(smot)),
        catchError(error => {
          this.toastr.showErrorBasedOnStatus(error.status);
          this.actionIsLoading = false;
          return EMPTY;
        })
      ).subscribe();
  }

  private deinstallSmot(smot: SmotDTO) {
    this.actionIsLoading = true;

    const operation = {
      operationType: 'DEINSTALL',
      smotLogicalId: smot.logicalId,
    } as CreateOperationDTO;

    this.operationService.postOperation(operation).pipe(
      filter(x => x !== undefined && x !== null),
      tap(() => {
        this.operationService.removeOperationSmot();
        smot.isActive = 4;
        this.toastr.showSucces(this.translate.instant('translate.operations.shared.smotSuccesfullyDeleted'), this.translate.instant('translate.general.succes'));
        this.router.navigate(['/operations']);
      }),
      tap(() => this.actionIsLoading = false),
      catchError(error => {
        this.toastr.showErrorBasedOnStatus(error.status);
        this.actionIsLoading = false;
        return EMPTY;
      })
    ).subscribe();
  }

  private activateSmot(smot: SmotDTO) {
    this.actionIsLoading = true;

    const operation = {
      operationType: 'ACTIVATE',
      smotLogicalId: smot.logicalId,
      info: null
    } as CreateOperationDTO;

    this.operationService.postOperation(operation).pipe(
      filter(x => x !== undefined),
      tap(performedOperation => {
        smot.isActive = performedOperation.smot.isActive;
        this.toastr.showSucces(this.translate.instant('translate.operations.shared.smotSuccessfullyActivated'), this.translate.instant('translate.general.succes'));
      }),
      tap(() => this.actionIsLoading = false),
      catchError(error => {
        this.toastr.showErrorBasedOnStatus(error.status);
        this.actionIsLoading = false;
        return EMPTY;
      })
    ).subscribe();
  }


  private deactivateSmot(smot: SmotDTO) {
    this.actionIsLoading = true;

    const operation = {
      operationType: 'DEACTIVATE',
      smotLogicalId: smot.logicalId,
      info: null
    } as CreateOperationDTO;

    this.operationService.postOperation(operation).pipe(
      filter(x => x !== undefined),
      tap(performedOperation => {
        smot.isActive = performedOperation.smot.isActive;
        this.toastr.showSucces(this.translate.instant('translate.operations.shared.smotSuccesfullyDeactivated'), this.translate.instant('translate.general.succes'));
      }),
      tap(() => this.actionIsLoading = false),
      catchError(error => {
        this.toastr.showErrorBasedOnStatus(error.status);
        this.actionIsLoading = false;
        return EMPTY;
      })
    ).subscribe();
  }
  //endregion

  //region Image functions
  onFileSelect(event, smot) {
      const image = event.target.files[0];
      this.handleImageUpload(image, smot);
  }

  private handleImageUpload(image, smot) {
    const reader = new FileReader();

    reader.addEventListener('loadend', () => {
      this.openEditImageDialog(reader.result, smot);
    });

    reader.readAsDataURL(image);
  }

  private handleImageUpdate(image, smot) {
    // Convert image (data-url) back to a file
    this.imageCreateService.urltoFile(image, 'temp.png', 'image/png').then(
      // Upload file to backend
      (file) => this.fileUploadService.uploadSmotProfilePicture(file, smot).pipe(
        filter(x => x !== undefined),
        // If successful update in frontend
        tap(() => {
          this.imageCreateService.updateSmotImg(image);
          this.toastr.showSucces('Image successfully updated', 'success');
        }),
        catchError(error => {
          this.toastr.showErrorBasedOnStatus(error.status);
          return EMPTY;
        })
      ).subscribe()
    );

  }

  private openEditImageDialog(imgToCrop, smot) {
    const dialog = this.dialog.open(EditImgModalComponent, {
      data: {
        imageSource: imgToCrop
      }
    });

    dialog.afterClosed().pipe(
      filter(x => x !== undefined),
      tap(image => this.handleImageUpdate(image, smot))
    ).subscribe();
  }
  //endregion

  private getUser() {
    this.user$ = this.userService.getUser(this.auth.getUsername());
  }

  private getSmot() {
     this.smot$ = this.smotService.getSmot(this.smotId).pipe(
       filter(x => x !== undefined),
       tap(smot => {
         this.fillFormValues(smot);
         if (smot.device == null) {
           this.device$ = this.getDevice(smot);
         } else {
           this.device$ = of(smot.device);
         }
       })
     );
  }

  private getSmotAccess() {
    const username = this.auth.getUsername();
    this.userService.getUserSmotAccessForSmotWithId(username, this.smotId).pipe(
      filter(x => x !== undefined),
      tap(smotAccess => {
        this.notificationControl.setValue(smotAccess.mailType);
        this.getColorBasedOnMailType();
      }),
    ).subscribe();
  }
  private getDevice(smot) {
    if (!smot.device) {
      return of(null);
    }
    return this.deviceService.getDeviceById(smot.device.deviceId).pipe(
      filter(x => x !== undefined)
    );
  }

  private getSmotImage() {
    this.smotImage$ = this.imageCreateService.smotImage$;
    this.smotService.fetchSmotImage(this.smotId.toString()).pipe(
      tap(() => this.imageCreateService.updateSmotImg(undefined)),
      filter(x => x !== undefined),
      tap(blob => this.imageCreateService.createSmotImg(blob)),
      catchError(error => {
        this.toastr.showErrorBasedOnStatus(error.status);
        return EMPTY;
      })
    ).subscribe();
  }

  private createForm() {
    this.smotForm = new FormGroup({
      smotName: this.smotNameControl,
      language: this.languageControl,
      event: this.eventControl,
      sleepTime: this.sleepTimeControl,
      wakeUpTime: this.startTimeControl
    });
  }

  private fillFormValues(smot: any) {
    this.smotNameControl.setValue(smot.locationDescription);
    this.languageControl.setValue(smot.language);
    this.eventControl.setValue(smot.isEvent);
    this.startTimeControl.setValue(smot.wakeUpTime + ':00');
    this.sleepTimeControl.setValue(smot.sleepTime + ':00');
    this.isOwnMaintenanceControl.setValue(smot.isOwnMaintenance);
  }

  private fillLanguageSelect() {
    this.supportedLanguages$ = of(languages.map(language => ({
      value: language.fileName,
      label: language.language
    }) as IDropdownOption));
  }

  private updateSmot(smot): SmotDTO {
    return ({
      ...smot,
      language: this.languageControl.value,
      isEvent: +this.eventControl.value,
      wakeUpTime: +this.startTimeControl.value.substring(0, +this.startTimeControl.value.indexOf(':')),
      sleepTime: +this.sleepTimeControl.value.substring(0, +this.sleepTimeControl.value.indexOf(':')),
      locationDescription: this.smotNameControl.value,
      isOwnMaintenance: +this.isOwnMaintenanceControl.value
    }) as SmotDTO;
  }

  private checkIfUserCanAccessDetails() {
    const role = this.auth.getRole();
    if (role === Role.ADMIN || role === Role.PARTNER) {
      this.canAccessDetails = true;
    }
  }
}
