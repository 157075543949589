import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {Observable} from 'rxjs';
import {FormControl, Validators} from '@angular/forms';
import {filter, shareReplay, startWith, switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'app-drop-down-field',
  templateUrl: './drop-down-field.component.html',
  styleUrls: ['./drop-down-field.component.scss', '../form-fields.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DropDownFieldComponent implements OnInit, OnChanges {
  // TODO: Add clear input control
  @Input() options$: Observable<IDropdownOption[]>;
  @Input() label: string = 'Dropdown';
  @Input() search: boolean = false;
  @Input() multiSelect: boolean = false;
  @Input() control: FormControl = new FormControl();
  @Input() hideLabel: boolean = false;
  @Input() initialSelectedValue = null;
  @Input() disabled: boolean = false;
  @Output() selected: EventEmitter<any> = new EventEmitter<any>();

  searchControl: FormControl = new FormControl('');
  filteredOptions: any[] = [];
  isRequired: boolean = false;

  hidden: boolean = true;
  constructor(private elementRef: ElementRef,
              private cdr: ChangeDetectorRef) { }


  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    if (this.hidden) { return; }
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.hidden = true;
    }
  }

  ngOnInit(): void {
    if (this.disabled) {
      this.control.disable();
    }

    this.checkIfControlIsRequired();
    this.subscribeToControlValueChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options$']) {
      this.options$ = this.options$?.pipe(shareReplay(1));
      this.options$?.pipe(
        tap(options => {
          this.filteredOptions = options;
        })
      ).subscribe();
      this.checkForInitialRequestedValue();
    }

    if (this.disabled && this.control.enabled) {
      this.control.disable();
    } else if (!this.disabled && this.control.disabled) {
      this.control.enable();
    }
  }

  handleOptionSelect(option: IDropdownOption) {
    if (option.disabled || this.multiSelect) {
      return;
    }
    this.toggleDropdown();
    this.searchControl.setValue(option.label);
    this.control.setValue(option.value);
    this.selected.emit(option.value);
  }

  handleMultiSelectChange(options: IDropdownOption[]) {
    const selectedOptions = options.filter(o => o.selected).map(o => o.value);
    this.control.setValue(selectedOptions);
    this.selected.emit(selectedOptions);
  }

  toggleDropdown() {
    if (this.disabled) {
      this.hidden = true;
      return;
    }
    this.hidden = !this.hidden;
  }

  handleKeyPress(event: KeyboardEvent) {
    this.control.markAsTouched();
    if (!this.control.value) {
      return;
    }
    this.control.setValue(null);
  }

  private subscribeToControlValueChanges() {
    this.searchControl.valueChanges.pipe(
      filter(x => x !== undefined),
      startWith(''),
      switchMap(value => this.options$?.pipe(
        filter(x => x !== undefined),
        tap(options => {
          this.filteredOptions = this.search ? options.filter(option => option.label.toLowerCase().includes(value.toLowerCase())) : options;
        })
      ))
    ).subscribe();

    // this.control.valueChanges.pipe(
    //   switchMap(() => this.options$),
    //   tap(options => {
    //     console.log('change detected');
    //     const initialValueOption = options?.find(o => o.value === this.initialSelectedValue);
    //     const selectedOption = options?.find(o => o.selected);
    //     if (!initialValueOption && !selectedOption) {
    //       return;
    //     }
    //
    //     this.searchControl.setValue(initialValueOption?.label ?? selectedOption?.label);
    //   })
    // ).subscribe();
  }

  private checkForInitialRequestedValue() {
    this.options$?.pipe(
      filter(x => x !== undefined),
      tap(options => {
        const initialValueOption = options.find(o => o.value === this.initialSelectedValue);
        const selectedOption = options.find(o => o.selected);
        if (!initialValueOption && !selectedOption) {
          return;
        }

        this.searchControl.setValue(initialValueOption?.label ?? selectedOption?.label);
        this.control.setValue(initialValueOption?.value ?? selectedOption?.value);
      }),
    ).subscribe();
  }

  private checkIfControlIsRequired() {
      this.isRequired = this.control.hasValidator(Validators.required);
  }

}

export interface IDropdownOption {
  label: string;
  value: any;
  selected?: boolean;
  disabled?: boolean;
}
