<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <div class="card-group">
            <div class="card p-4">
              <div class="card-body">
                <form [formGroup]="loginForm" #form="ngForm" (ngSubmit)="tryLogin()">
                  <app-h2 [subtitle]="'translate.login.signInToYourAccount' | translate">{{'translate.login.login' | translate}}</app-h2>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-primary"><i class="icon-user"></i></span>
                    </div>
                    <input #user
                           type="text"
                           class="form-control"
                           placeholder="{{'translate.user.username' | translate}}"
                           autocomplete="username"
                           [formControl]="usernameControl"
                           required
                           data-cy="username"
                    >
                    <div style="width: 100%" class="error-message-minimal" *ngIf="form.form.get('username').invalid && form.submitted">
                      {{'translate.form.isRequired' | translate:{'item' : user.placeholder} }}</div>
                  </div>
                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-primary"><i class="icon-lock"></i></span>
                    </div>
                    <input #pass
                           type="password"
                           class="form-control"
                           placeholder="{{'translate.login.password' | translate}}"
                           autocomplete="current-password"
                           [formControl]="passwordControl"
                           required
                           data-cy="password"
                    >
                    <div style="width: 100%" class="error-message-minimal" *ngIf="form.form.get('password').invalid && form.submitted">{{'translate.form.isRequired' | translate:{'item' : pass.placeholder} }}</div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <app-primary-button data-cy="login-button" [size]="'extra-small'" [disabled]="loading" [type]="'submit'">{{'translate.login.login' | translate}}</app-primary-button>
                    </div>
                    <div class="col-6 text-right">
                      <a [routerLink]="['/forgot-password']">{{'translate.login.forgotPassword' | translate}}</a>
                    </div>
                  </div>
                </form>
                <div class="error-message mt-3" *ngIf="serverError">
                  {{ 'translate.login.serverErrorMessage' | translate}}
                </div>
              </div>
            </div>
            <div class="card text-white bg-primary py-5 d-md-down-none" style="width:44%">
              <div class="card-body text-center">
                <div>
                  <app-h2 [color]="'white'">{{'translate.login.signUp' | translate}}</app-h2>
                  <p>{{'translate.login.loginForOverview' | translate}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
