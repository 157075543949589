import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ImageCreateService} from '../../../services/image-create.service';
import {combineLatest, Observable} from 'rxjs';
import {filter, finalize, tap} from 'rxjs/operators';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {

  afterImage$: Observable<any> = new Observable<any>();
  beforeImage$: Observable<any> = new Observable<any>();
  operationImage$: Observable<any> = new Observable<any>();

  operationImageLoaded: boolean = false;
  beforeImageLoaded: boolean = false;
  afterImageLoaded: boolean = false;

  onlyShowOperationImage: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public imageService: ImageCreateService) {
  }

  ngOnInit(): void {
    this.subscribeToImages();
    this.createImages();
  }

  subscribeToImages() {
    this.afterImage$ = this.imageService.operationAfterImage$.pipe(
      filter(x => x !== undefined),
      tap(() => this.afterImageLoaded = true),
    );


    this.beforeImage$ = this.imageService.operationBeforeImage$.pipe(
      filter(x => x !== undefined),
      tap(() => this.beforeImageLoaded = true),
    );


    this.operationImage$ = this.imageService.operationImage$.pipe(
      filter(x => x !== undefined),
      tap(() => this.operationImageLoaded = true)
    );
  }

  createImages() {
    this.imageService.resetImages();

    if (this.data.imgurl) {
      this.onlyShowOperationImage = true;
      this.imageService.createOperationImage(this.data.imgurl);
    }

    if (this.data.afterImg) {
      this.imageService.createOperationImage(this.data.afterImg, 'after');
    }

    if (this.data.beforeImg) {
      this.imageService.createOperationImage(this.data.beforeImg, 'before');
    }
  }
}
