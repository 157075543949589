import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SmotComponent} from './smot.component';
import {ViewSmotComponent} from './view-smot/view-smot.component';

const routes: Routes = [
  {
    path: '',
    component: SmotComponent,
    data: {
      title: 'SMOTspots'
    }
  },
  {
    path: ':id',
    component: ViewSmotComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SmotRoutingModule { }
