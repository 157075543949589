<div class="sundo-container medium m-auto h-100 position-relative">
  <div class="back-button" (click)="goToPreviousStep()">
    <mat-icon >keyboard_arrow_left</mat-icon>
  </div>
  <app-h2 class="text-center">{{data?.headerString ?? 'translate.routes.comments'| translate}}</app-h2>
  <ng-container *ngIf="infoComments$ | async as infoComments">
    <app-h4 *ngIf="infoComments?.length">Info</app-h4>
    <ul>
      <li *ngFor="let comment of infoComments">
        {{comment.content}}
      </li>
    </ul>
  </ng-container>
  <ng-container *ngIf="comments$ | async as comments">
    <app-h4 *ngIf="comments?.length">opmerkingen</app-h4>
    <ul>
      <li *ngFor="let comment of comments">
            <mat-checkbox *ngIf="data.requiredToCheckAllComments" (change)="updateComment(comment, $event)"  required [checked]="comment.resolutionStatus === 'RESOLVED' || comment.skippedComment">
            </mat-checkbox>
        <div>
          {{comment.content}}
                  <ng-container *ngIf="!comment.skippedComment else reason">
                    <span *ngIf="data.requiredToCheckAllComments" class="skip" (click)="skipComment(comment)">{{'translate.form.skip' | translate}}</span>
                  </ng-container>
                  <ng-template #reason>
                    <app-input-field class="comment-input"
                                     [hideLabel]="true"
                                     [multiLine]="true"
                                     [emitOnEveryKeyInput]="true"
                                     (enteredInput)="comment.reason = $event"></app-input-field>
                  </ng-template>
        </div>
      </li>
    </ul>
  </ng-container>

<!--    <ng-container *ngIf="maintenance$ | async as maintenances">-->
<!--      <app-h4 *ngIf="maintenances.length">Onderhoud</app-h4>-->
<!--      <ul *ngIf="maintenances.length">-->
<!--        <li *ngFor="let maintenance of maintenances">-->
<!--          <mat-checkbox *ngIf="data.requiredToCheckAllComments" required [(ngModel)]="maintenance.checked" (change)="updateMaintenance(maintenance, $event)">-->
<!--          </mat-checkbox>-->
<!--          <div *ngIf="!maintenance.skipped else skipped">-->
<!--            {{maintenance.description}}-->
<!--            <span class="skip" *ngIf="data.requiredToCheckAllComments" (click)="skipMaintenance(maintenance)">{{'translate.form.skip' | translate}}</span>-->
<!--          </div>-->
<!--          <ng-template #skipped>-->
<!--            <div>-->
<!--              <s>{{maintenance.description}}</s>-->
<!--              <span class="skip" (click)="skipMaintenance(maintenance)">{{'translate.form.skip' | translate}}</span>-->
<!--            </div>-->
<!--          </ng-template>-->
<!--        </li>-->
<!--      </ul>-->
<!--    </ng-container>-->

  <div class="buttons">
    <p class="text-center" *ngIf="step?.smot?.packaging?.sunscreen">
      <i class="cil-sun"></i>
      {{step.smot.packaging.sunscreen.sunscreenType}}
    </p>
    <ng-container *ngIf="data.plannedRouteId">
            <p class="text-center" *ngIf="step?.contactPerson">
              <i class="cil-user"></i>
              {{ step?.contactPerson }}
            </p>

            <div class="my-3 d-flex justify-content-center">
              <app-primary-button [size]="'extra-small'" class="mr-3" (click)="navigateWithWaze(step)"><img src="assets/img/brand/waze-icon.svg" alt="waze navigation app - icon" style="height: 24px"/></app-primary-button>
              <app-primary-button [size]="'extra-small'" (click)="navigateWithGoogleOrAppleMaps(step)"><i class="cil-map"></i></app-primary-button>
            </div>
    </ng-container>
    <app-success-button [disabled]="hasUnresolvedComments() && data.requiredToCheckAllComments" [fullWidth]="true" (click)="goToNextStep()">{{'translate.form.next' | translate}}</app-success-button>
  </div>
</div>
