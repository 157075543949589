import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class OperationGuard implements CanActivate {

  constructor(private router: Router, private authenticationService: AuthenticationService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable<boolean>(obs => {
      if (this.authenticationService.isLoggedIn()) {
        const roleFromUser = this.authenticationService.getRole();
        if (route.data.roles.includes(roleFromUser)) {
          return obs.next(true);
        }
        return obs.next(false);
      }

      const seasonId: string = route.queryParams['sea'];
      if (!seasonId) {
        this.router.navigate(['/login']);
        return obs.next(false);
      }
      this.router.navigate(['report-problem/report-language'], {state: {seasonId: seasonId}});
      return obs.next(false);
    });
  }
}
