
<div class="input">
  <p *ngIf="!hideLabel"
     class="label"
     [style.color]="darkMode ? 'white' : ''"
     matTooltip="{{tooltip | translate}}"
     matTooltipPosition="before"
     aria-label="{{tooltip | translate}}">{{label}}<span *ngIf="isRequired">*</span></p>
  <ng-container *ngIf="!multiLine else multi">
    <ng-container *ngIf="autoFocus else noAutoFocus">
      <input class="form-field"
             [class.disabled]="disabled"
             [formControl]="control"
             [type]="type"
             [min]="min"
             appAutoFocus
             [placeholder]="placeholder"
             (keyup)="handleKeyInput($event)"
             [ngClass]="{'invalid' : control.touched && control.errors}">
    </ng-container>
    <ng-template #noAutoFocus>
      <input class="form-field"
             [class.disabled]="disabled"
             [formControl]="control"
             [type]="type"
             [min]="min"
             [placeholder]="placeholder"
             (keyup)="handleKeyInput($event)"
             [ngClass]="{'invalid' : control.touched && control.errors}">
    </ng-template>

  </ng-container>
  <ng-template #multi>
    <ng-container *ngIf="autoFocus else noAutoFocus">
    <textarea class="form-field"
              [class.disabled]="disabled"
              [style.height]="amountOfLines * 25 + 'px'"
              [formControl]="control"
              appAutoFocus
              (keyup)="handleKeyInput($event)"
              [ngClass]="{'invalid' : control.touched && control.errors}">
    </textarea>
    </ng-container>
    <ng-template #noAutoFocus>
    <textarea class="form-field"
              [class.disabled]="disabled"
              [style.height]="amountOfLines * 25 + 'px'"
              [formControl]="control"
              (keyup)="handleKeyInput($event)"
              [ngClass]="{'invalid' : control.touched && control.errors}">
    </textarea>
    </ng-template>
  </ng-template>
  <div *ngIf="control.errors">
    <span class="error-message-minimal" *ngIf="control.errors['required'] && control.touched">{{'translate.form.isRequired' | translate:{'item': label} }}</span>
  </div>
</div>
