import { Params } from '@angular/router';
import {ToastrNotificationService} from '../../services/toastr-notification.service';
import {Location} from '@angular/common';
import {FlowService} from '../../services/flow.service';

export class BaseFlow {
  flow;
  params;
  key: string = 'step_stage';
  constructor(public toastr: ToastrNotificationService,
              public location: Location,
              public flowService: FlowService) {
  }
  /**
   * Handles the request to go to the next step
   */
  nextStep(): void {
    let stepStage = +this.flowService.getStepStage(this.key);
    stepStage++;
    if (stepStage >= this.flow.steps.length) {
      this.finishFlow();
      return;
    }
    this.flowService.setStepStage(this.key, stepStage.toString());
  }

  /**
   * Handles the request to go to the previous step
   */
  previousStep(): void {
    let stepStage = +this.flowService.getStepStage(this.key);
    if (stepStage === 0) {
      this.location.back();
    }
    stepStage--;
    this.flowService.setStepStage(this.key, stepStage.toString());
  }

  /**
   * Handles the request to skip the step
   */
  skipStep(): void {
    this.nextStep();
  }

  /**
   * Retrieves the flow based on any parameters it received
   * @param params
   */
  getFlow(params: Params): any {
    return null;
  }

  /**
   * Retrieves the current step bases on stepStages and params received
   * @param params
   */
  getCurrentStep(params: Params): any {
    this.params = params;
    // Get the current stepStage from operationService, if an invalid value has returned reset the stepStage to 0
    let stepStage = +this.flowService.getStepStage(this.key);
    if (!stepStage || stepStage > this.flow.steps.length || stepStage < 0) {
      stepStage = 0;
      this.flowService.setStepStage(this.key, stepStage.toString());
    }

    // Get current flow step
    const step = this.flow.steps[stepStage];

    // If the step is undefined(possibly index out of bounds) retry with 0
    if (!step) {
      this.toastr.showErrorBasedOnStatus(500);
      this.location.back();
      return null;
    }

    // Update any step specific properties
    if (step.data.uploadUrl) {
      step.data.uploadUrl = step.data.uploadUrl.replace('{smotId}', params['smotId']);
    }

    return step;
  }

  /**
   * This method should be called after the last step has finished,
   * Handles any updates/cleanup that should happen after the flow has been completed
   */
  finishFlow(): void {
    this.flowService.clearStepStage(this.key);
    this.location.back();
  }
}
