import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ValidationFormsService} from '../../../views/forms/validation-forms/validation-forms.service';

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss', '../form-fields.scss']
})
export class InputFieldComponent implements OnInit, OnChanges {
  @Input() label: string = 'input';
  @Input() initialValue: string = null;
  @Input() type: 'text' | 'password' | 'email' | 'number' | 'tel' = 'text';
  @Input() control: FormControl = new FormControl();
  @Input() hideLabel: boolean = false;
  @Input() darkMode: boolean = false;
  @Input() emitOnLosingFocus: boolean = false;
  @Input() clearOnEmit: boolean = false;
  @Input() multiLine: boolean = false;
  @Input() amountOfLines: number = 3;
  @Input() placeholder: string = '';
  @Input() emitOnEveryKeyInput: boolean = false;
  @Input() disabled: boolean = false;
  @Input() tooltip: string = '';
  @Input() autoFocus: boolean = false;
  @Input() required: boolean = false;
  @Input() min: number = null;

  @Output() enteredInput: EventEmitter<string> = new EventEmitter<string>();

  isRequired: boolean = false;
  constructor(private elementRef: ElementRef,
              private vf: ValidationFormsService) { }

  ngOnInit(): void {
    this.checkIfControlIsRequired();
    if (this.disabled) {
      this.control.disable();
    }
    if (!!this.initialValue) {
      this.control.setValue(this.initialValue);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.disabled && this.control.enabled) {
      this.control.disable();
    } else if (!this.disabled && this.control.disabled) {
      this.control.enable();
    }
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    if (!this.emitOnLosingFocus) { return; }
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.emitInput();
    }
  }

  handleKeyInput(event) {
    if (event.key === 'Enter' || this.emitOnEveryKeyInput) {
      this.emitInput();
    }
  }

  emitInput() {
    if (this.control.invalid) {
      return;
    }

    this.enteredInput.emit(this.control.value);
    if (this.clearOnEmit) {
      this.control.reset();
    }
  }

  private checkIfControlIsRequired() {
    if (this.required && !this.control.hasValidator(Validators.required)) {
      this.control.addValidators(Validators.required);
      this.control.addValidators(Validators.pattern(this.vf.formRules.nonEmpty));
    }
    this.isRequired = this.control.hasValidator(Validators.required);
  }

}
