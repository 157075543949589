import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import {catchError, debounceTime, filter, map, startWith, switchMap, tap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {InfoModalComponent} from '../../plugins/info-modal/info-modal.component';
import {OperationService} from '../../../services/operation.service';
import {MatDialog} from '@angular/material/dialog';
import {DataRequest, TableColumn} from '../../tables/table/table.component';
import {combineLatest, EMPTY, Observable} from 'rxjs';
import {OperationDTO} from '../../../dtos/OperationDTOs/operationDTO';
import {Sort} from '@angular/material/sort';
import {OperationMappings} from '../../../models/dtoMappings';
import {OperationTableDTO} from '../../../dtos/OperationDTOs/operationTableDTO';
import moment from 'moment';

@Component({
  selector: 'app-smot-operations',
  templateUrl: './smot-operations.component.html',
  styleUrls: ['./smot-operations.component.scss']
})
export class SmotOperationsComponent implements OnInit {

  // ----- Operations Table
  columns: TableColumn[] = [
    {
      field: 'operationType',
      header: 'translate.sunscreen.type',
      sortable: true,
      dbProperty: 'operationType',
      textAlign: 'left'
    },
    {
      field: 'creationDate',
      header: 'translate.general.time',
      sortable: true,
      dbProperty: 'operationTime',
      textAlign: 'left'
    },
    {
      field: 'infoString',
      header: 'translate.issue-overview.dataTable.info',
      textAlign: 'left'
    },
    {
      field: 'userId',
      header: 'translate.user.user',
      sortable: true,
      dbProperty: 'user_username',
      textAlign: 'left'
    },
    {
      field: 'info',
      header: '',
      textAlign: 'center'
    }
  ];
  selectedOperationTypes = new FormControl();
  operationTypes = ['REFILL', 'REPAIR', 'ACTIVATE', 'DEACTIVATE', 'CLEAN', 'INSTALL', 'DEINSTALL'];
  lastOperationTypesValue = ['REFILL', 'REPAIR', 'ACTIVATE', 'DEACTIVATE', 'CLEAN', 'INSTALL', 'DEINSTALL'];
  loadingDownload: boolean = false;
  smotOperations$!: Observable<OperationDTO[]>;

  private smotId: number;

  @ViewChild('select') select: MatSelect;

  constructor(
    private route: ActivatedRoute,
    private operationService: OperationService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    const _this = this;
    this.route.params.subscribe(params => {
      _this.smotId = params['id'];
      this.smotOperations$ = this.fetchOperationsForSmotSpot(_this.smotId, 0, 10, null);
    });
  }


  fetchOperationsForSmotSpot(smotId: number, page: number, size: number, sort: Sort = null) {
    return this.selectedOperationTypes.valueChanges.pipe(
      startWith(this.operationTypes),
      debounceTime(500),
      map((values: string[]) => values.length === 0 ? this.operationTypes : values),
      tap(values => {
        if (values !== this.lastOperationTypesValue) {
          this.lastOperationTypesValue = values;
          page = 0;
        }
      }),
      switchMap(operationTypes => {
        return this.operationService.getOperationsForSmotspotWithPagination<OperationTableDTO>(smotId, operationTypes, page, size, sort, OperationMappings.OPERATION_TABLE_DTO).pipe(
          filter(x => x !== undefined),
          map((operations: any) => {
            operations.content = operations.content.map(operation => ({
              ...operation,
              creationDate: moment(operation.operationTime * 1000).format('yyyy-MM-DD HH:mm'),
              infoString: this.getInfoString(operation.info),
              userId: operation.username ?? 'deleted'
            }));
            return operations;
          })
        );
      })
    );
  }

  handleDataRequest(event: DataRequest) {
    this.smotOperations$ = this.fetchOperationsForSmotSpot(this.smotId, event.pageNumber, event.pageSize, event.sort);
  }

  openDialog(event: OperationDTO) {
    // Open dialog with 2 image blobs
    if (event.operationType === 'CLEAN') {
      const beforeImage$ = this.operationService.fetchOperationImage(event.operationId, 'before');
      const afterImage$ = this.operationService.fetchOperationImage(event.operationId, 'after');

      combineLatest([beforeImage$, afterImage$]).pipe(
        filter(([x, y]) => x !== undefined && y !== undefined),
        tap(([before, after]) => {
          this.dialog.open(InfoModalComponent, {
            data: {
              beforeImg: before,
              afterImg: after
            }
          });
        })
      ).subscribe();

      //  Open dialog with 1 image blob
    } else if (event.operationType === 'REPAIR') {
      this.operationService.fetchOperationImage(event.operationId, 'repair').subscribe((result) => {
        if (!!result) {
          this.dialog.open(InfoModalComponent, {
            data: {
              imgurl: result
            }
          });
        }
      });
    }
  }

  public downloadPdf() {
    this.loadingDownload = true;
    this.operationService.getOperationsDataForSmotspotAsPDF(this.smotId, this.lastOperationTypesValue).pipe(
      filter(x => x !== undefined),
      tap((data: any) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'operationReport_' + this.smotId + '.pdf';
        link.click();
        window.URL.revokeObjectURL(url);
      }),
      tap(() => this.loadingDownload = false),
      catchError(err => {
        this.loadingDownload = false;
        return EMPTY;
      })
    ).subscribe();
  }

  // returns a more readable string from a JSON object
  private getInfoString(info: string): String {
    if (info?.length <= 0) {
      return '';
    }

    let jsonObject: string | Object = JSON.stringify('');
    try {
      jsonObject = JSON.parse(info);
    } catch (error) {
      console.error(error);
      return '';
    }
    let infoString = '';
    Object.entries(jsonObject)
      .forEach(([key, value]) => {
        infoString += `${key}: ${value}</br>`;
      });
    return infoString;
  }
}
