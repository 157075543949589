import {Type} from '@angular/core';
import {SmotDTO} from '../dtos/SmotDTOs/smotDTO';
import { SelectRepairPartComponent } from '../views/operations/select-repair-part/select-repair-part.component';
import { FinishFlowComponent } from '../views/shared/flow/flow-steps/finish-flow/finish-flow.component';
import { UploadImageComponent } from '../views/shared/flow/flow-steps/upload-image/upload-image.component';

export const OPERATION_REPAIR_FLOW: OperationFlow = {
  steps: [
    {
      component: SelectRepairPartComponent,
      data: {}
    },
    {
      component: UploadImageComponent,
      data: {
        headerString: 'translate.operations.smot-repair.doYouWishToUploadAnImage'
      }
    },
    {
      component: FinishFlowComponent,
      data: {
        headerString: 'Reparatie voltooid'
      }
    }
  ]
};


export interface OperationFlow {
  steps: OperationFlowStep[];
}

interface OperationFlowStep {
  component: Type<any>;
  data: OperationFlowData;
}

export interface OperationFlowData {
  uploadUrl?: string;
  requiredToCheckAllComments?: boolean;
  headerString?: string;
  smot?: SmotDTO;
}
