import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map, retry} from 'rxjs/operators';
import {Guid} from 'guid-typescript';
import {ToastrNotificationService} from './toastr-notification.service';
import {Sort} from '@angular/material/sort';

@Injectable({
  providedIn: 'root'
})
export class ApiHelperService {

  retryKey: Guid = Guid.create();

  private authHelper = new JwtHelperService();
  authenticated = false;

  constructor(private http: HttpClient,
              private toastr: ToastrNotificationService) {
  }


  getPaginationString(page: number, size: number, search: string) {
    let url = '';
    if (page != null && size != null) {
      url = url + `?page=${page}&size=${size}&search=${search}`;
    }
    if ((page == null || size == null) && search.trim().length > 0) {
      url = url + `?search=${search}`;
    }
    return url;
  }

  getSortString(sort: Sort, firstParameter: boolean = false) {
    let url = '';
    if (sort === null || sort.active === '' || sort.direction === '') {
      return url;
    }
    if (firstParameter) {
      url += '?orderBy=' + sort.active;
    } else {
      url += '&orderBy=' + sort.active;
    }
    url += '&direction=' + sort.direction;
    return url;
  }

  refreshRetryKey() {
    this.retryKey = Guid.create();
  }

  /**
   * Simply does a http request as in HttpClient.request() but wrapped with
   * error handling, retries 3 times automatically. The type parameter
   * T does not have to be a RestObject.
   *
   * @param {HttpRequest<T>} request - The HttpRequest that needs te be executed
   * @return {Observable<T>} The returned object or an ErrorObservable
   */
  requestURL<T>(request: HttpRequest<T>, auth: boolean, key: Guid, preventDefaultErrorBehavior: boolean = false): Observable<T> {
    if (auth) {
      // console.log('in request url: ', key, ' for ', request.method, ' to url: ', request.url);
      request = request.clone({
        withCredentials: true,
        setHeaders: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'X-Requested-With': 'XMLHttpRequest',
          'X-Line-Retry-Key': key.toString(),
        }
      });
    }
    return this.http.request(request).pipe(
      retry(3),
      map(response => (<any>response).body),
      catchError(err => {
        if (!preventDefaultErrorBehavior) {
          this.toastr.showErrorBasedOnStatus(err.status, '', err?.error?.displayCustomError, err?.error?.errorTranslateKey);
        }
        throw err;
      })
    );
  }

  sendWithoutToken<T>(request: HttpRequest<T>, auth: boolean, key: Guid): Observable<T> {
    if (auth) {
      request = request.clone({
        withCredentials: true,
        setHeaders: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-Line-Retry-Key': key.toString(),
        }
      });
    }
    return this.http.request(request).pipe(
      retry(3),
      map(response => (<any>response).body)
    );
  }

  /**
   * Simply does a get request as in HttpClient.get() but wrapped with
   * error handling, also retries 3 times automatically. The type parameter
   * T does not have to be a RestObject.
   *
   * @param {string} url - The URL to perform a GET request on.
   * @return {Observable<T>} The returned object or an ErrorObservable
   */

  getRequest<T>(url: string, headers: any = null, preventDefaultErrorBehavior: boolean = false): Observable<T> {
    this.refreshRetryKey();
    const key = this.retryKey;
    return this.requestURL(new HttpRequest('GET', url, headers), true, key, preventDefaultErrorBehavior);
  }





  /**
   * Does a post request as in HttpClient.post() but wrapped with
   * error handling, also retries 3 times automatically.
   *
   * @param {string} url - The URL to post the object to
   * @param {T extends RestObject} object - The REST object to be posted (properties determined
   *                                        by the server like url or id are ignored.)
   * @param {Object} headers - Additional headers, (e.g: responseType: 'blob')
   * @return {Observable<T>} The posted REST object including the server-determind properties
   *                         or ErrorObservable.
   */
  postRequest<T>(url: string, object, headers = null, preventDefaultErrorBehavior: boolean = false): Observable<T> {
    this.refreshRetryKey();
    const key = this.retryKey;
    return this.requestURL(new HttpRequest('POST', url, object, headers), true, key, preventDefaultErrorBehavior);
  }

  postRequestWithoutToken<T>(url: string, object, preventDefaultErrorBehavior: boolean = false): Observable<T> {
    this.refreshRetryKey();
    const key = this.retryKey;
    return this.sendWithoutToken(new HttpRequest('POST', url, object), true, key);
  }
  /**
   * Does a put request as in HttpClient.put() but wrapped with
   * error handling, also retries 3 times automatically. The URL
   * is or determined by the RestObject's url property,
   * and otherwise *not* determined by the object, but by the parameters url.
   *
   * @param {T} object - The object to be updated (not required to be a REST object).
   * @param {string} url - The URL of the object.
   * @return {Observable<T>} Returns the updated object if successfull,
   *                         otherwise an ErrorObservable is returned.
   */
  putRequest<T>(url: string, object, preventDefaultErrorBehavior: boolean = false): Observable<T> {
    this.refreshRetryKey();
    const key = this.retryKey;
    return this.requestURL(new HttpRequest('PUT', url, object), true, key, preventDefaultErrorBehavior);
  }

  putRequestWithoutToken<T>(url: string, object): Observable<T> {
    this.refreshRetryKey();
    const key = this.retryKey;
    return this.sendWithoutToken(new HttpRequest('PUT', url, object), true, key);
  }

  /**
   * Does a delete request as in HttpClient.delete() but wrapped with
   * error handling, also retries 3 times automatically. The URL is
   * determined by the RestObject's url property.
   *
   * @param {RestObject} object - The REST object to be deleted.
   */
  deleteRequest<T>(url: string, object, preventDefaultErrorBehavior: boolean = false): Observable<T> {
    this.refreshRetryKey();
    const key = this.retryKey;
    return this.requestURL(new HttpRequest('DELETE', url), true, key, preventDefaultErrorBehavior);
  }
}
