<div class="d-flex justify-content-center align-items-center" style="height: 100vh;">
  <div class="sundo-container" >
    <ng-container *ngIf="!submitted else response">
        <app-h4 class="text-center">{{'translate.login.enterYourUsername' | translate}}</app-h4>
        <form class="mt-4" (ngSubmit)="sendForgotPassword()">
          <app-input-field
            [control]="username"
            [label]="'username'"
          ></app-input-field>
          <app-primary-button [fullWidth]="true" [disabled]="!username.valid" type="submit">{{'translate.form.submit' | translate}}</app-primary-button>
        </form>
    </ng-container>
    <ng-template #response>
      <ng-container *ngIf="error else success">
        <app-h4 class="text-center" [color]="'red'">{{'translate.general.error.somethingWentWrong' | translate}}</app-h4>
        <app-primary-button [fullWidth]="true" (click)="submitted = false; error = false">{{'translate.form.back' | translate}}</app-primary-button>

      </ng-container>
      <ng-template #success>
        <app-h4 class="text-center">{{'translate.general.emailHasBeenSent' | translate}}</app-h4>
        <app-primary-button [fullWidth]="true" [routerLink]="['/login']">{{'translate.login.login' | translate}}</app-primary-button>
      </ng-template>
    </ng-template>
  </div>
</div>

