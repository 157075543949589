import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DropDownFieldComponent} from './drop-down-field/drop-down-field.component';
import {InputFieldComponent} from './input-field/input-field.component';
import {TagComponent} from './tag/tag.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {SearchBarComponent} from './search-bar/search-bar.component';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {DatepickerComponent} from './daterangepicker/datepicker.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DatePickerComponent} from './date-picker/date-picker.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DirectivesModule} from '../../directives/directives.module';


@NgModule({
  declarations: [
    DropDownFieldComponent,
    InputFieldComponent,
    TagComponent,
    SearchBarComponent,
    DatepickerComponent,
    DatePickerComponent
  ],
    exports: [
        DropDownFieldComponent,
        InputFieldComponent,
        TagComponent,
        SearchBarComponent,
        DatepickerComponent,
        DatePickerComponent
    ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    FormsModule,
    MatDatepickerModule,
    MatTooltipModule,
    DirectivesModule
  ]
})
export class FormFieldsModule { }
