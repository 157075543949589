import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {ApiHelperService} from './api-helper.service';
import {Observable} from 'rxjs';
import {CustomSmotPerformanceDTO} from '../dtos/SmotDTOs/customSmotPerformanceDTO';
import {PacketDTO} from '../dtos/PacketDTOs/packetDTO';
import {Moment} from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PacketService {
  private packetUrl = environment.apiRoot + '/packets';

  constructor(private helper: ApiHelperService) {
  }

  /**
   * Gets the list of facilitators asynchronously. The request is only sent
   * once you `subscribe()` to the returned `Observable`.
   */
  getPackets(): Observable<PacketDTO[]> {
    return this.helper.getRequest<PacketDTO[]>(this.packetUrl + '/{id}/packets');
  }

  getPacketsFromDate(fromDate: Date): Observable<PacketDTO[]> {
    return this.helper.getRequest<PacketDTO[]>(this.packetUrl + '/{id}/packets', /* fromDate */);
  }

  getSmotspotPackets(smot_logical_id, dateFrom: Moment, dateUntil: Moment): Observable<PacketDTO[]> {
    return this.helper.getRequest<PacketDTO[]>(this.packetUrl + '/' + smot_logical_id + '?from=' + dateFrom.utc().toISOString() + '&until=' + dateUntil.utc().toISOString());
  }

  getTotalEvents(smotLogicalId: number, dateFrom: Moment, dateUntil: Moment): Observable<any> {
    return this.helper.getRequest<number>(this.packetUrl + '/totalEvents' + '?from=' + dateFrom.utc().toISOString() + '&until=' + dateUntil.utc().toISOString() + '&ids=' + smotLogicalId);
  }

  getCustomPerformancePacketsByLogicalId(from: Moment, until: Moment, ids: number[]): Observable<CustomSmotPerformanceDTO[]> {
    let reqUrl = this.packetUrl + '/getAllEventsBySmot' + '?from=' + from.toISOString() + '&until=' + until.toISOString();
    if (ids !== null) {
      reqUrl += '&ids=';
      ids.forEach(id => {
        reqUrl += id + ',';
      });
      reqUrl = reqUrl.slice(0, reqUrl.length - 1);
    }
    return this.helper.getRequest<any>(reqUrl);
  }
}
