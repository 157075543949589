<app-h2 class="text-center">Custom Casing aanmaken</app-h2>
<ng-form>
  <app-drop-down-field [label]="'Taal'"
                       [options$]="languageOptions$"
                       [control]="languageControl"></app-drop-down-field>
  <app-drop-down-field [search]="true"
                       [label]="'Klant'"
                       [disabled]="disableClientControl"
                       [control]="clientControl"
                       [options$]="clientOptions$"
                       (selected)="handleClientSelection($event)"></app-drop-down-field>
  <app-drop-down-field #variant
                       [disabled]="disableVariantControl"
                       [search]="true"
                       [label]="'Klant variant'"
                       [options$]="variantOptions$"
                       [control]="clientVariantControl"
                       (selected)="handleClientVariantSelection($event)"
  ></app-drop-down-field>
  <mat-slide-toggle [formControl]="infoPanelControl" class="my-3">Bevat info bord</mat-slide-toggle>
  <app-success-button  [fullWidth]="true"
                       [disabled]="customCasingForm.invalid || clientVariantControl.value === null"
                       (click)="createCustomCasing()">{{'translate.form.submit' | translate}}</app-success-button>
</ng-form>
