import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DataTableService} from './datatable.service';

@Component({
  templateUrl: 'datatable.component.html',
  providers: [ DataTableService ],
  selector: 'app-data-table',
})
export class DataTableComponent {
  error: any;
  @Input() data: any;
  @Input() dataTitle: string;
  @Input() dataHeaders: string[];
  @Input() dataFields: string[];
  @Input() rowsOnPage: number = 10;
  @Input() searchIsHidden: boolean;
  @Input() isClickable: boolean = false;
  @Input() editableRows: boolean;
  @Input() createIsHidden: boolean = true;
  @Input() deletableRows: boolean = false;
  @Input() tableLoadingText: string = 'translate.dataTable.dataIsLoading';
  @Input() clickForInfo: boolean = false;
  @Input() hideContainer: boolean = false;
  @Input() loading: boolean = false;
  @Input() severity: boolean;

  @Output() deleteClicked = new EventEmitter();
  @Output() infoClicked = new EventEmitter();
  @Output() rowClicked = new EventEmitter();

  public filterQuery = '';


  constructor() {
  }

  emitDelete(value) {
    this.deleteClicked.emit(value);
  }

  emitItemInfo(itemInfo) {
    this.infoClicked.emit(itemInfo);
  }

  onRowClicked(event) {
    if (this.isClickable) {
      this.rowClicked.emit(event);
    }
  }

  public toInt(num: string) {
    return +num;
  }

  public sortByWordLength = (a: any) => {
    return a.name.length;
  }

  public getDate(regDate: string) {
    const date = new Date(regDate);
    return date.toLocaleDateString('en-US', {year: 'numeric', month: 'short', day: '2-digit'});
  }

}
