import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SuccessButtonComponent} from './success-button/success-button.component';
import {WarningButtonComponent} from './warning-button/warning-button.component';
import {DangerButtonComponent} from './danger-button/danger-button.component';
import {PrimaryButtonComponent} from './primary-button/primary-button.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@NgModule({
  declarations: [
    PrimaryButtonComponent,
    DangerButtonComponent,
    WarningButtonComponent,
    SuccessButtonComponent,
  ],
    imports: [
        CommonModule,
        MatProgressSpinnerModule
    ],
  exports: [
    PrimaryButtonComponent,
    DangerButtonComponent,
    WarningButtonComponent,
    SuccessButtonComponent,
  ]
})
export class SundoButtonsModule { }
