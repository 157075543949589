import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {filter, switchMap, tap} from 'rxjs/operators';
import moment from 'moment/moment';
import { BaseFlowStepComponent } from '../base-flow-step.interface';
import { RouteFlowStepData } from '../../../../../models/routeFlows';
import { ValidationFormsService } from '../../../../forms/validation-forms/validation-forms.service';
import { RouteService } from '../../../../../services/route.service';
import { SmotService } from '../../../../../services/smot.service';
import { PlannedRouteDTO } from '../../../../../dtos/RouteDTOs/plannedRouteDTO';
import { RouteStepCommentDTO } from '../../../../../dtos/RouteDTOs/routeDTO';
import { CommentType } from '../../../../../models/commentType';
import { ResolutionStatus } from '../../../../../models/resolutionStatus';
import {ToastrNotificationService} from '../../../../../services/toastr-notification.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-finish-route',
  templateUrl: './finish-flow.component.html',
  styleUrls: ['./finish-flow.component.scss']
})
export class FinishFlowComponent implements OnInit, BaseFlowStepComponent {
  @Input() data: RouteFlowStepData;
  @Output() nextStep: EventEmitter<true> = new EventEmitter();
  @Output() previousStep: EventEmitter<false> = new EventEmitter();

  loading: boolean = false;

  commentFormControl: FormControl = new FormControl('', [Validators.required, Validators.pattern(this.vf.formRules.nonEmpty)]);

  constructor(private vf: ValidationFormsService,
              private toastr: ToastrNotificationService,
              private routeService: RouteService,
              private translate: TranslateService,
              private smotService: SmotService) {
  }

  ngOnInit(): void {
  }

  public goToPreviousStep() {
    this.previousStep.emit(false);
  }

  public goToNextStep() {
    this.loading = true;
    this.nextStep.emit(true);
  }

  public postComment() {
    this.loading = true;
    this.commentFormControl.markAsTouched();
    if (this.commentFormControl.invalid) {
      this.loading = false;
      return;
    }

    if (this.data.smotId) {
      // Add comment to smot
      this.smotService.getSmot(this.data.smotId).pipe(
        filter(x => x !== undefined),
        switchMap(smot => {
          const comment = this.createComment(smot.logicalId);
          smot.comments.push(comment);
          return this.smotService.saveSmot(smot);
        }),
        filter(x => x !== undefined),
        tap(() => {
          this.loading = false;
          this.toastr.showSucces(this.translate.instant('translate.routes.addCommentSuccessful'), this.translate.instant('translate.general.succes'));
          this.commentFormControl.reset();
        })
      ).subscribe();
    } else if (this.data.plannedRouteId) {
      // Add comment to routeStep
      this.routeService.getPlannedRouteById<PlannedRouteDTO>(this.data.plannedRouteId).pipe(
        filter(x => x !== undefined),
        switchMap(plannedRoute => {
          const comment = this.createComment(null);
          const stepIndex = plannedRoute.route.steps.findIndex(s => s.routeStepId === +this.data.stepId);
          plannedRoute.route.steps[stepIndex].routeStepComments.push(comment);

          return this.routeService.updatePlannedRoute(plannedRoute);
        }),
        filter(x => x !== undefined),
        tap(() => {
          this.loading = false;
          this.toastr.showSucces(this.translate.instant('translate.routes.addCommentSuccessful'), this.translate.instant('translate.general.succes'));
          this.commentFormControl.reset();
        })
      ).subscribe();
    }
  }

  private createComment(smotId): RouteStepCommentDTO {
    return {
      type: CommentType.COMMENT,
      content: this.commentFormControl.value,
      resolutionStatus: ResolutionStatus.UNRESOLVED,
      creationDate: moment().utc(),
      routeStepId: smotId ? null : +this.data.stepId,
      resolveDate: null,
      smotLogicalId: smotId,
      routeStepCommentId: null,
    };
  }
}
