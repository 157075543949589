import {Component, ComponentRef, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {
  RenderDynamicComponentDirective
} from '../../../directives/render-dynamic-components/render-dynamic-component.directive';
import {FlowFactory, FlowType} from '../../../classes/flow/flow-factory.class';
import {ActivatedRoute, Params} from '@angular/router';
import {combineLatest, race } from 'rxjs';
import { BaseFlow } from '../../../classes/flow/base-flow.class';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'app-flow',
  template: '<ng-template class="h-100" appRenderDynamicComponent></ng-template>',
})
export class FlowComponent implements OnInit, OnDestroy {
  @ViewChild(RenderDynamicComponentDirective, {static: true}) renderStep!: RenderDynamicComponentDirective;
  params: Params;
  flowType: FlowType;
  flowParent: BaseFlow;
  flow: any;

  viewContainerRef: ViewContainerRef;
  componentRef: ComponentRef<any>;

  constructor(private flowFactory: FlowFactory,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    combineLatest([
      this.route.params.pipe(startWith({})),
      this.route.queryParams.pipe(startWith({})),
      this.route.data
    ]).subscribe(([params, queryParams, data]) => {
      this.params = { ...params, ...queryParams };
      this.flowType = data.flowType;
      this.initComponent();
    });
  }

  private initComponent() {
    // Get flowParent from flowFactory (Handles events in the flow like nextStep, PreviousStep, etc)
    this.flowParent = this.flowFactory.createFlow(this.flowType);

    // Get flow from flowParent (Contains all the individual steps and configurations)
    this.flow = this.flowParent.getFlow(this.params);

    // Load the component for the first time
    this.loadComponent();
  }

  /**
   * Handles loading the dynamic component based on the current flowStep
   * @private
   */
  private loadComponent() {
    // Get the current step
    const step = this.flowParent.getCurrentStep(this.params);

    // Get the viewContainer and clear
    this.viewContainerRef = this.renderStep.viewContainerRef;
    this.viewContainerRef.clear();

    // Create a new component within the viewContainer
    this.componentRef = this.viewContainerRef.createComponent(step.component);

    // Add data to the component (@Input)
    this.componentRef.instance.data = step.data;

    // Subscribe to any stepChanges like: nextStep, previousStep
    this.detectStepChanges();
  }

  /**
   * Destroy the references to the dynamic component when this component is being destroyed
   */
  ngOnDestroy(): void {
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
    }
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  /**
   * Listens to nextStep and previousStep events and tells the flowParent what function to call
   * @private
   */
  private detectStepChanges() {
    race(this.componentRef.instance.nextStep, this.componentRef.instance.previousStep).subscribe(winner => {
      if (winner) {
        this.flowParent.nextStep();
      } else {
        this.flowParent.previousStep();
      }
      this.loadComponent();
    });
  }

}
