import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {shareReplay, tap } from 'rxjs/operators';
import { ScannerService } from '../../../../../services/scanner.service';
import { ToastrNotificationService } from '../../../../../services/toastr-notification.service';
import {BaseFlowStepComponent} from '../base-flow-step.interface';


@Component({
  selector: 'app-flow-scanner',
  templateUrl: './flow-scanner.component.html',
  styleUrls: ['./flow-scanner.component.scss']
})
export class FlowScannerComponent implements OnInit, BaseFlowStepComponent {
  @Input() data: any;
  @Output() nextStep: EventEmitter<true> = new EventEmitter();
  @Output() previousStep: EventEmitter<false> = new EventEmitter();

  badScan: boolean = false;
  processingScan: boolean = false;
  incorrectSmotScanned = false;

  constructor(private scannerService: ScannerService,
              private toastr: ToastrNotificationService) { }

  ngOnInit(): void {
  }

  public handleScannedCode(code) {
    if (this.processingScan) {
      return;
    }
    this.badScan = false;
    this.processingScan = true;
    const scannedQR = this.scannerService.verifyScan(code);
    if (!scannedQR) {
      this.badScan = true;
      this.processingScan = false;
      return;
    }

    this.scannerService.getTopLevelEntity(scannedQR).pipe(
      tap((entity) => {
        if (!entity || entity.entityType === 'notFound') {
          this.toastr.showError('Er is niets gevonden voor deze code', 'Niet gevonden');
        } else {
          if (this.data.expectedEntityType === entity.entityType && +this.data.expectedId === this.getEntityId(entity)) {
            this.goToNextStep();
          } else {
            // TODO: Update to display: incorrect {{ expectedEntityType }} scanned
            this.incorrectSmotScanned = true;
          }
        }
        this.processingScan = false;
      }),
      shareReplay(1)
    ).subscribe();

  }

  goToNextStep() {
    this.nextStep.emit(true);
  }

  goToPreviousStep() {
    this.previousStep.emit(false);
  }


  getEntityId(entity) {
    switch (entity.entityType) {
      case 'customCasing':
        return entity.value.customCasingId;
      case 'smot':
        return entity.value.logicalId;
      case 'device':
        return entity.value.deviceId;
      case 'notFound':
        return null;
    }
  }
}
