import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SmotComponent} from './smot.component';
import {SmotRoutingModule} from './smot-routing.module';
import {DatatableInitModule} from '../tables/datatable/datatable.module';
import {ViewSmotComponent} from './view-smot/view-smot.component';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ToasterModule} from 'angular2-toaster';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {TranslateModule} from '@ngx-translate/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatExpansionModule} from '@angular/material/expansion';
import {SmotDetailModule} from '../smot-detail/smot-detail.module';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {SmotOperationsComponent} from './smot-operations/smot-operations.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {TablesModule} from '../tables/tables.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {FormFieldsModule} from '../../components/form-fields/form-fields.module';
import {SundoHeadersModule} from '../../components/headers/sundo-headers.module';
import {SundoButtonsModule} from '../../components/buttons/sundo-buttons.module';
import {MatTableModule} from '@angular/material/table';
import {ScannerModule} from '../../components/scanner/scanner.module';
import { CreateSmotFormComponent } from '../forms/create-smot-form/create-smot-form.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';


@NgModule({
    declarations: [
        SmotComponent,
        ViewSmotComponent,
        SmotOperationsComponent,
    ],
    exports: [
        SmotComponent,
    ],
    imports: [
        FormsModule,
        CommonModule,
        SmotRoutingModule,
        DatatableInitModule,
        TabsModule,
        FormsModule,
        ToasterModule,
        ReactiveFormsModule,
        NgxMaterialTimepickerModule,
        TranslateModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        NgxMaterialTimepickerModule,
        MatExpansionModule,
        SmotDetailModule,
        MatOptionModule,
        MatSelectModule,
        MatCheckboxModule,
        TablesModule,
        MatIconModule,
        MatSlideToggleModule,
        FormFieldsModule,
        SundoHeadersModule,
        SundoButtonsModule,
        MatTableModule,
        ScannerModule,
        MatProgressBarModule
    ]
})
export class SmotModule {
}
