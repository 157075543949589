import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

// DataTable
import {HttpClientModule} from '@angular/common/http';
import {DataFilterPipe} from './datafilterpipe';
import {FormsModule} from '@angular/forms';

import {DataTableComponent} from './datatable.component';
import {DataTableModule} from '@pascalhonegger/ng-datatable';
// Routing
import {DatatableRoutingModule} from './datatable-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {SundoButtonsModule} from '../../../components/buttons/sundo-buttons.module';

//
@NgModule({
    imports: [
        DatatableRoutingModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        DataTableModule,
        TranslateModule,
        MatIconModule,
        MatButtonModule,
        SundoButtonsModule
    ],
  exports: [
    DataTableComponent
  ],
  declarations: [
    DataTableComponent,
    DataFilterPipe
  ]
})
export class DatatableInitModule { }
