import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-h2',
  template: `
    <h2 [style.color]="color ? color : 'var(--secondary-color)'">
      <ng-content></ng-content>
      <ng-container *ngIf="subtitle">
        <div role="doc-subtitle">{{subtitle}}</div>
      </ng-container>
    </h2>
  `,
  styleUrls: ['../header-styling.scss']
})
export class H2Component implements OnInit {
  @Input() color: string = null;
  @Input() subtitle: string = null;

  constructor() { }

  ngOnInit(): void {
  }

}
