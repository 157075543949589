import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-basic-info-modal',
  template: `
    <div class="text-center">
      <app-h4 class="mx-4">{{info}}</app-h4>
    </div>
    <p *ngIf="body" [innerHTML]="body"></p>
    <div class="d-flex row justify-content-around mt-4">
      <app-primary-button data-cy="confirm-action" (click)="confirm()" class="mx-3">{{confirmText}}</app-primary-button>
    </div>`
})
export class BasicInfoModalComponent implements OnInit {
  @Input() public key: string;
  @Input() public body: string;
  info: string;
  confirmText: string = 'Confirm';

  constructor(public dialogref: MatDialogRef<BasicInfoModalComponent>,
              private translate: TranslateService,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.key = data.key;
    this.body = data.body;
    this.info = this.translate.instant(this.key);
    this.confirmText = this.translate.instant('translate.general.confirm');
  }

  ngOnInit(): void {
  }

  confirm() {
    this.dialogref.close(true);
  }

}
