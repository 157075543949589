import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';
import {combineLatest, EMPTY} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {BaseFlowStepComponent} from '../base-flow-step.interface';
import { RouteFlowStepData } from '../../../../../models/routeFlows';
import { OperationService } from '../../../../../services/operation.service';
import { SmotService } from '../../../../../services/smot.service';
import { ScannerService } from '../../../../../services/scanner.service';
import { PackageService } from '../../../../../services/package.service';
import { SmotDTO } from '../../../../../dtos/SmotDTOs/smotDTO';
import { ScannedQR } from '../../../../../models/ScannedQR';
import { SunscreenIncompatibility } from '../../../../error/sunscreenIncompatibility';
import { ConfirmActionModalComponent } from '../../../../plugins/confirm-action-modal/confirm-action-modal.component';
import {CreateOperationDTO} from '../../../../../dtos/OperationDTOs/createOperationDTO';
import {ToastrNotificationService} from '../../../../../services/toastr-notification.service';
import { PackagingDTO } from '../../../../../dtos/packagingDTO';
import { InputModalComponent } from '../../../../plugins/input-modal/input-modal.component';
import { RoutePackageDTO } from '../../../../../dtos/SmotDTOs/routeSmotDTO';
import {BasicInfoModalComponent} from '../../../../plugins/basic-info-modal/basic-info-modal.component';

@Component({
  selector: 'app-refill',
  templateUrl: './refill.component.html',
  styleUrls: ['./refill.component.scss', '../../../../operations/operations.scss']
})
export class RefillComponent implements OnInit, BaseFlowStepComponent  {
  @Input() data: RouteFlowStepData;
  @Output() nextStep: EventEmitter<true> = new EventEmitter();
  @Output() previousStep: EventEmitter<false> = new EventEmitter();

  loading: boolean = false;
  badScan: boolean = false;
  step: 'QUESTION' | 'SCANNER' | 'FINISH' = 'QUESTION';
  constructor(private operationService: OperationService,
              private smotService: SmotService,
              private scanService: ScannerService,
              private translate: TranslateService,
              private dialog: MatDialog,
              private toastr: ToastrNotificationService,
              private packageService: PackageService) {
  }


  ngOnInit(): void {
  }

  public goToNextStep() {
    this.nextStep.emit(true);
  }

  public goToPreviousStep() {
    this.previousStep.emit(false);
  }

  // TODO: ask user about sunscreen volume
  public checkSunscreenVolume() {
    const smot  = this.data.plannedRoute.route.steps.find(s => +s.routeStepId === +this.data.stepId)?.smot;
    if (this.smotService.checkSunscreenVolumeBelowLal(smot)) {
      this.promptUserAboutSunscreenVolume(smot.packaging);
      return;
    }

    this.goToNextStep();
  }

  private promptUserAboutSunscreenVolume(packaging: PackagingDTO | RoutePackageDTO ) {
    const dialogRef = this.dialog.open(InputModalComponent, {
      data: {
        key: this.translate.instant('translate.operations.smot-maintenance.smotVolumeIsLowEstimateValue') + packaging.remainingVolume,
        type: 'number'
      }
    });

    this.loading = true;
    dialogRef.afterClosed().pipe(
      tap(x => {
        if (x === undefined || x === null) {
          this.loading = false;
        }
      }),
      filter(x => x !== undefined),
      switchMap(number => {
        packaging.remainingVolume = number;
        return this.packageService.savePackaging(packaging).pipe(
          filter(x => x !== undefined),
          tap(() => {
            this.goToNextStep();
            this.loading = false;
          }),
          catchError(err => {
            this.toastr.showErrorBasedOnStatus(err.status);
            this.loading = false;
            this.goToNextStep();
            return EMPTY;
          })
        );
      })
    ).subscribe();
  }

  private createRefillOperation(sunscreenId: number, smot: SmotDTO) {
    return {
      smotLogicalId: smot.logicalId,
      operationType: 'REFILL',
      info: JSON.stringify({
        newPackageId: sunscreenId,
        previousPackageId: smot.packaging?.packageId
      })
    } as CreateOperationDTO;
  }

  handleSuccessfulCodeScan(event) {
    if (this.loading) {
      return;
    }

    this.badScan = false;
    this.loading = true;

    const scannedQR: ScannedQR = this.scanService.verifyScan(event);
    if (scannedQR == null) {
      this.badScan = true;
      this.loading = false;
      return;
    }
    const smotLogicalId = this.data.smotId ?? this.data?.plannedRoute?.route?.steps?.find(s => +s.routeStepId === +this.data.stepId)?.smot?.logicalId;
    const packaging$ = this.packageService.getPackaging(+scannedQR?.sunscreenId).pipe(
      filter(x => x !== undefined),
    );
    const smotspot$ = this.smotService.getSmot(smotLogicalId).pipe(
      filter(x => x !== undefined),
    );

    combineLatest([packaging$, smotspot$]).pipe(
      switchMap(([packaging, smot]) => {
        if (!smot?.packaging) {
          return of({packageId: packaging?.packageId, smot: smot});
        }
        const incompatibleSunscreen = this.smotService.checkSunscreenCompatibility(smot, packaging);
        if (incompatibleSunscreen != null) {
          this.promptUserAboutIncorrectSunscreen(incompatibleSunscreen, smot);
          return null;
        }
        return of({packageId: packaging?.packageId, smot: smot});
      }),
      switchMap(response => {
        if (!response.packageId) {
          this.loading = false;
          return EMPTY;
        }
        return this.operationService.postOperation(this.createRefillOperation(response.packageId, response.smot))
          .pipe(
            filter(x => x !== undefined),
            tap(() => {
              this.goToNextStep();
              this.loading = false;
            })
          );
      }),
      catchError(() => {
        this.loading = false;
        return EMPTY;
      })
    ).subscribe();
  }

  /**
   * Prompts the user about differences in the scanned packaging
   * @param incorrectSunscreenType
   * @param smot
   * @private
   */
  private promptUserAboutIncorrectSunscreen(incorrectSunscreenType: SunscreenIncompatibility,
                                            smot: SmotDTO) {
    const incorrectSunscreenPrompts = new Map<SunscreenIncompatibility, String>([
      [SunscreenIncompatibility.SunscreenTypeIncompatibility, this.translate.instant('translate.operations.smot-maintenance.expectedSunscreen') + ` ${smot?.packaging?.sunscreen?.sunscreenType} `],
      [SunscreenIncompatibility.ProducerIncompatibility, this.translate.instant('translate.operations.smot-maintenance.expectedSunscreen') + ` ${smot?.packaging?.sunscreen?.sunscreenType}` + ' ' + this.translate.instant('translate.operations.smot-maintenance.fromProducer') + ` ${smot?.client?.clientName}.`]]);
    let key = this.translate.instant('translate.operations.smot-maintenance.sunscreenLooksWrong') + '\n\n';
    key += incorrectSunscreenPrompts.get(incorrectSunscreenType);
    this.dialog.open(BasicInfoModalComponent, {
      data: {
        key: key
      },
    });
  }
}
