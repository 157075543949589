import { Injectable } from '@angular/core';
import {ApiHelperService} from './api-helper.service';
import {environment} from '../../environments/environment';
import {ClientDTO} from '../dtos/ClientDTOs/clientDTO';
import {ClientVariantDTO} from '../dtos/ClientDTOs/ClientVariantDTO';
import { Sort } from '@angular/material/sort';

@Injectable({
  providedIn: 'root'
})
export class ClientVariantService {
  private clientVariantUrl = environment.apiRoot + '/client-variants';

  constructor(private helper: ApiHelperService) { }

  /**
   * GET client variants by clientNumber
   */
  getClientVariantsByClientNumber(clientNumber: number) {
    const url = `${this.clientVariantUrl}/client/${clientNumber}`;
    return this.helper.getRequest<ClientVariantDTO[]>(url);
  }

  /**
   * GET client variants with pagination
   */
  getClientVariantsByClientNumberWithPagination(clientNumber: number, pageNumber: number, pageSize: number, search: string, sort: Sort) {
    let url = `${this.clientVariantUrl}/client/${clientNumber}`;
    url += this.helper.getPaginationString(pageNumber, pageSize, search);
    url += this.helper.getSortString(sort);
    return this.helper.getRequest(url);
  }

  /**
   * GET all client variants
   */
  getAllClientVariants() {
    return this.helper.getRequest<ClientVariantDTO[]>(this.clientVariantUrl);
  }

  /**
   * POST Create a new clientVariant
   */
  createClientVariant(clientVariant: ClientVariantDTO) {
    return this.helper.postRequest<ClientVariantDTO>(this.clientVariantUrl, clientVariant);
  }

  /**
   * PUT update an existing clientVariant
   */
  updateClientVariant(clientVariant: ClientVariantDTO) {
    return this.helper.putRequest<ClientVariantDTO>(this.clientVariantUrl, clientVariant);
  }

  /**
   * DELETE a client variant by id
   */
  deleteClientVariant(clientVariantId: number) {
    const url = `${this.clientVariantUrl}/${clientVariantId}`;
    return this.helper.deleteRequest(url, null);
  }
}
