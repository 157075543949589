import {Component, OnInit} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {OperationService} from '../../../services/operation.service';
import {catchError, filter, switchMap, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {SmotService} from '../../../services/smot.service';
import {of} from 'rxjs/internal/observable/of';
import {ToastrNotificationService} from '../../../services/toastr-notification.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmActionModalComponent} from '../../plugins/confirm-action-modal/confirm-action-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {UserService} from '../../../services/user.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {CommentType} from '../../../models/commentType';
import {SmotDTO} from '../../../dtos/SmotDTOs/smotDTO';
import {InputModalComponent} from '../../plugins/input-modal/input-modal.component';
import {ResolutionStatus} from '../../../models/resolutionStatus';
import {RouteService} from '../../../services/route.service';
import {CreateRouteStepCommentDTO} from '../../../dtos/RouteDTOs/createRouteDTO';
import moment from 'moment';
import {CreateOperationDTO} from '../../../dtos/OperationDTOs/createOperationDTO';

@Component({
  selector: 'app-smot-operation',
  templateUrl: './smot-operation.component.html',
  styleUrls: ['./smot-operation.component.scss', '../operations.scss']
})
export class SmotOperationComponent implements OnInit {

  smotId: string;
  smot$: Observable<SmotDTO>;
  operationLinks: any[] = [
    {
      buttonKey: this.translate.instant('translate.operations.smot-operation.maintainSmot'),
      link: 'maintenance'
    },
    {
      buttonKey: this.translate.instant('translate.operations.smot-operation.repairSmot'),
      link: 'repair',
    },
    {
      buttonKey: this.translate.instant('translate.operations.shared.uploadImage'),
      link: 'smot-image'
    }
  ];

  disableButtons = false;

  user;

  constructor(private operationService: OperationService,
              private activatedRoute: ActivatedRoute,
              private smotService: SmotService,
              private router: Router,
              private toastr: ToastrNotificationService,
              private translate: TranslateService,
              private dialog: MatDialog,
              private userService: UserService,
              private routeService: RouteService,
              private authService: AuthenticationService) {
    this.activatedRoute.paramMap.subscribe(params => this.smotId =  params.get('id'));
  }

  ngOnInit(): void {
    this.fetchUser();
    this.operationService.setStepStage('0');
    this.smot$ = this.operationService.operationSmot$.pipe(
     switchMap(smot => {
       if (smot !== undefined) { return of(smot); }
       return this.smotService.getSmot(+this.smotId);
     })
    );
  }

  displayAddCommentModal(smot: SmotDTO, isInfo: boolean = false) {
    const dialog = this.dialog.open(InputModalComponent, {
      data: {
        key: isInfo ?  'translate.operations.smot-maintenance.addInfoToThisSMOTSpot'
          : 'translate.operations.smot-maintenance.addACommentToThisSMOTSpot',
        multiline: true
      }
    });

    dialog.afterClosed().pipe(
      filter(x => x !== undefined),
      switchMap(comment => {
        const commentDTO = {
          type: isInfo ? CommentType.INFO : CommentType.COMMENT,
          smotLogicalId: smot.logicalId,
          content: comment,
          resolutionStatus: ResolutionStatus.UNRESOLVED,
          creationDate: moment().utc()
        } as CreateRouteStepCommentDTO;

        return this.routeService.createComment(commentDTO).pipe(
          filter(x => x !== undefined),
          tap(() => this.toastr.showSucces(this.translate.instant('translate.routes.addCommentSuccessful'), 'Success'))
        );
      })
    ).subscribe();
  }

  private fetchUser() {
    this.userService.getUser(this.authService.getUsername()).pipe(
      tap(user => this.user = user),
    ).subscribe();
  }
}
