import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SmotOperationComponent} from './smot-operation/smot-operation.component';
import {StartRouteComponent} from './routes/start-route/start-route.component';
import {RouteOverviewComponent} from './routes/route-overview/route-overview.component';
import { FlowType } from '../../classes/flow/flow-factory.class';
import {FlowComponent} from '../shared/flow/flow.component';
import { SmotScannerComponent } from './smot-scanner/smot-scanner.component';

const routes: Routes = [
  {
    path: '',
    component: SmotScannerComponent,
    data: {
      title: 'Operations'
    }
  },
  {
    path: 'smot/:id',
    component: SmotOperationComponent,
    data: {
      title: 'SMOTSpot Operations',
    }
  },
  {
    path: 'smot/:id/:flow',
    component: FlowComponent,
    data: {
      title: 'SMOTSpot Operation',
      flowType: FlowType.OPERATION_FLOW,
    }
  },
  {
    path: 'start-route',
    component: StartRouteComponent,
    data: {
      title: 'Start Route'
    }
  },
  {
    path: 'route-overview/:id',
    component: RouteOverviewComponent,
    data: {
      title: 'Route Overview'
    }
  },
  {
    path: 'perform-route',
    component: FlowComponent,
    data: {
      title: 'Perform Route',
      flowType: FlowType.ROUTE_FLOW,
    }
  }
  // {
  //   path: 'route-overview/:plannedRouteId/step/:stepId/:flow',
  //   component: FlowComponent,
  //   data: {
  //     title: 'Perform Route',
  //     flowType: FlowType.ROUTE_FLOW,
  //   }
  // },
  // {
  //   path: 'route-overview/:plannedRouteId/step/:stepId/:flow/:smotId',
  //   component: FlowComponent,
  //   data: {
  //     title: 'Perform Route',
  //     flowType: FlowType.ROUTE_FLOW,
  //   }
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OperationsRoutingModule { }
