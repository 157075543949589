import {Injectable} from '@angular/core';
import {ApiHelperService} from './api-helper.service';
import {environment} from '../../environments/environment';
import {PlannedRouteMappings, RouteMappings} from '../models/dtoMappings';
import {CreatePlannedRouteDTO} from '../dtos/RouteDTOs/createPlannedRouteDTO';
import {PlannedRouteDTO} from '../dtos/RouteDTOs/plannedRouteDTO';
import {RouteDTO, RouteStepDTO} from '../dtos/RouteDTOs/routeDTO';
import {CreateRouteDTO, CreateRouteStepCommentDTO} from '../dtos/RouteDTOs/createRouteDTO';
import {Observable, Subject} from 'rxjs';
import {SkippedCommentDTO} from '../dtos/skippedCommentDTO';
import {Sort} from '@angular/material/sort';
import {ImageDTO} from '../dtos/imageDTO';
import {SkippedStepDTO} from '../dtos/skippedStepDTO';
import {RoutePreparationDTO} from '../dtos/RouteDTOs/routePreparationDTO';
import {Moment} from 'moment';
import {MinimalSmotRouteDTO} from '../dtos/RouteDTOs/minimalSmotRouteDTO';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  private routeApiUrl = environment.apiRoot + '/routes';

  constructor(private api: ApiHelperService) {
  }

  getFixedRoutes<T>(mappingTarget: RouteMappings = RouteMappings.DEFAULT) {
    const url = this.routeApiUrl + '/Fixed?mappingTarget=' + mappingTarget;
    return this.api.getRequest<T>(url);
  }

  getAlternativeRoutes<T>(mappingTarget: RouteMappings = RouteMappings.DEFAULT) {
    const url = this.routeApiUrl + '/Alternative?mappingTarget=' + mappingTarget;
    return this.api.getRequest<T>(url);
  }

  getPlannedRoutes<T>(mappingTarget: PlannedRouteMappings = PlannedRouteMappings.DEFAULT, date: Moment = null, username: string = null, includeUnfinished: boolean = false) {
    let url = this.routeApiUrl + '/Planned?mappingTarget=' + mappingTarget;
    if (date !== null) {
      url += '&date=' + date.utc().toISOString(false);
    }
    if (username !== null) {
      url += '&username=' + username;
    }
    if (includeUnfinished) {
      url += '&includeUnfinished=' + includeUnfinished;
    }
    return this.api.getRequest<T>(url);
  }

  getPlannedRoutesWithPagination<T>(page: number | null = null, size: number | null = null, search: string = '', sort: Sort = null, date: Moment = null, exludeDeleted: boolean = false, mappingTarget: PlannedRouteMappings = PlannedRouteMappings.DEFAULT) {
    let url = this.routeApiUrl + '/Planned';
    url += this.api.getPaginationString(page, size, search);
    url += this.api.getSortString(sort);
    url += '&mappingTarget=' + mappingTarget;
    url += '&excludeDeleted=' + exludeDeleted;
    if (date != null) {
      url += '&date=' + date.utc().toISOString(false);
    }
    return this.api.getRequest<T>(url);
  }

  getUnfinishedPlannedRoutes<T>(mappingTarget: PlannedRouteMappings = PlannedRouteMappings.DEFAULT, epochSeconds = null) {
    let url = this.routeApiUrl + '/Planned/Unfinished?mappingTarget=' + mappingTarget;
    if (epochSeconds !== null) {
      url += '&date=' + epochSeconds;
    }

    return this.api.getRequest<T>(url);
  }

  getRoutesBySmotId(id: number, page: number | null = null, size: number | null = null, search: string = '', sort: Sort = null, date: Moment = null): Observable<MinimalSmotRouteDTO[]> {
    let url = this.routeApiUrl + '/smot/' + id;
    url += this.api.getPaginationString(page, size, search);
    url += this.api.getSortString(sort);
    return this.api.getRequest<MinimalSmotRouteDTO[]>(url);
  }

  getPlannedRouteById<T>(id: number, mappingTarget: PlannedRouteMappings = PlannedRouteMappings.DEFAULT) {
    const url = this.routeApiUrl + '/Planned/' + id + '?mappingTarget=' + mappingTarget;
    return this.api.getRequest<T>(url);
  }

  updatePlannedRoute<T>(plannedRoute: PlannedRouteDTO, mappingTarget: PlannedRouteMappings = PlannedRouteMappings.DEFAULT) {
    const url = this.routeApiUrl + '/Planned' + '?mappingTarget=' + mappingTarget;
    return this.api.putRequest<T>(url, plannedRoute);
  }

  updateRoute<T>(route: RouteDTO, mappingTarget: RouteMappings = RouteMappings.DEFAULT, sendEmail: 'true' | 'false' = 'false', routeStepId: number = null) {
    let url = this.routeApiUrl + '?mappingTarget=' + mappingTarget + '&sendEmail=' + sendEmail;
    if (routeStepId !== null) {
      url += '&routeStepId=' + routeStepId;
    }
    return this.api.putRequest(url, route);
  }
  getRouteById<T>(id: number, mappingTarget: RouteMappings = RouteMappings.DEFAULT) {
    const url = `${this.routeApiUrl}/${id}?mappingTarget=${mappingTarget}`;
    return this.api.getRequest<T>(url);
  }

  planRoute(route: CreatePlannedRouteDTO) {
    const url = this.routeApiUrl + '/Planned';
    return this.api.postRequest(url, route);
  }

  createRoute<T>(route: CreateRouteDTO | RouteDTO) {
    const url = this.routeApiUrl;
    return this.api.postRequest<T>(url, route);
  }

  cancelPlannedRoute(routeId: number) {
    const url = this.routeApiUrl + '/Planned/' + routeId;
    return this.api.deleteRequest(url, null);
  }

  deleteRoute(routeId: number) {
    const url = this.routeApiUrl + '/' + routeId;
    return this.api.deleteRequest(url, null);
  }

  createSkippedComments(plannedRouteId: number, stepId: number, skippedComments: SkippedCommentDTO[]) {
    const url = this.routeApiUrl + `/Planned/${plannedRouteId}/Step/${stepId}/comments`;
    return this.api.postRequest(url, skippedComments);
  }

  createSkippedStep(plannedRouteId: number, stepId: number, skippedStep: SkippedStepDTO) {
    const url = this.routeApiUrl + `/Planned/${plannedRouteId}/Step/${stepId}`;
    return this.api.postRequest(url, skippedStep);
  }

  getRouteStepImages(routeId: number, routeStepId: number) {
    const url = environment.apiRoot + `/fileUpload/route/${routeId}/${routeStepId}`;
    return this.api.getRequest<ImageDTO[]>(url);
  }

  createComment(comment: CreateRouteStepCommentDTO) {
    const url = this.routeApiUrl + '/comments';
    return this.api.postRequest(url, comment);
  }

  downloadRoutePDF(routeId: number) {
    const url = this.routeApiUrl + `/${routeId}/pdf`;
    return this.api.getRequest(url, { responseType: 'blob' });
  }

  downloadPreparationFile(prepDTO: RoutePreparationDTO) {
    const url = this.routeApiUrl + `/preparationPDF`;
    return this.api.postRequest(url, prepDTO, { responseType: 'blob' }, false);
  }

  clearStepStage() {
    localStorage.removeItem('route_step_stage');
  }

  getPlannedStepTypeFromStep(step: RouteStepDTO) {
    if (step.maintenance) {
      return 0;
    } else if (step.installation) {
      return 1;
    } else if (step.deinstallation) {
      return 2;
    } else {
      return 3;
    }
  }
}
