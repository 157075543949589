import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SmotDTO} from '../../../dtos/SmotDTOs/smotDTO';
import {SmotService} from '../../../services/smot.service';
import {DeviceService} from '../../../services/device.service';
import {combineLatest, EMPTY, Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {filter, map, startWith, switchMap, tap} from 'rxjs/operators';
import {ScannedQR} from '../../../models/ScannedQR';
import {ScannerService} from '../../../services/scanner.service';
import {DeviceDTO} from '../../../dtos/deviceDTO';
import {ToastrNotificationService} from '../../../services/toastr-notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmActionModalComponent} from '../../plugins/confirm-action-modal/confirm-action-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {RouteFlowStepData} from '../../../models/routeFlows';
import {BaseFlowStepComponent} from '../../shared/flow/flow-steps/base-flow-step.interface';
import {CustomCasingService} from '../../../services/custom-casing.service';

@Component({
  selector: 'app-create-smot-spot',
  templateUrl: './create-smot-spot.component.html',
  styleUrls: ['./create-smot-spot.component.scss']
})
export class CreateSmotSpotComponent implements OnInit, BaseFlowStepComponent {
  @Input() data: any;
  @Output() nextStep: EventEmitter<true> = new EventEmitter();
  @Output() previousStep: EventEmitter<false> = new EventEmitter();
  currentStep: 'configs' | 'createConfig' | 'device' | 'createDevice' | 'checkInfoPanel' | 'overview' | 'refill' | 'finish' = 'configs';
  configurations$: Observable<SmotDTO[]>;
  filteredConfigurations$: Observable<SmotDTO[]>;
  searchControl = new FormControl();
  smot: SmotDTO;
  device: DeviceDTO;
  badScan: boolean = false;
  deviceId: number;

  constructor(private smotService: SmotService,
              private scanService: ScannerService,
              public router: Router,
              private toastr: ToastrNotificationService,
              private matDialog: MatDialog,
              private deviceService: DeviceService,
              private customCasingService: CustomCasingService,
              public route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.deviceId = +params['deviceId'];
    });
  }

  ngOnInit(): void {
    this.fetchConfigurations();
    this.listenToSearchControlChanges();
  }

  public handleDeviceCodeScanned(code) {
    this.badScan = false;
    const scannedQR: ScannedQR = this.scanService.verifyScan(code);
    if (!scannedQR || scannedQR?.type !== 'device') {
      this.badScan = true;
      return;
    }
    this.fetchDevice(+scannedQR.deviceId);
  }

  public handleDeviceCreated(device: DeviceDTO) {
    this.device = device;
    if (this.smotService.checkCompatibilityBetweenConfigAndDevice(device, this.smot)) {
      this.smot.device = device;
      if (!device.customCasing.hasInfoPanel) {
        this.currentStep = 'checkInfoPanel';
      } else {
        this.currentStep = 'overview';
      }
    } else {
      this.toastr.showError('De configuraties komen niet overeen', 'Error');
      this.currentStep = 'device';
    }
  }

  public handleConfigurationCreated(smotDto: SmotDTO) {
    this.smot = smotDto;
    this.goToDeviceStep();
  }

  public saveSmot() {
    this.smot.isActive = 2; // Smot is inactive
    this.smotService.saveSmot(this.smot).pipe(
      filter(x => x !== undefined),
      tap((smot) => {
        this.toastr.showSucces('SMOTspot werd succesvol aangemaakt', 'Success');
        this.router.navigate([`production/create/smotspot`], {
          queryParams: {
            smotId: this.smot.logicalId,
          }
        });
        this.nextStep.emit(true);
      })
    ).subscribe();
  }

  public goToDeviceStep() {
    if (this.deviceId) {
      this.fetchDevice(this.deviceId);
    } else {
      this.currentStep = 'device';
    }
  }

  public goToCreateConfigStep() {
    this.currentStep = 'createConfig';
  }

  public addInfoPanelToCustomCasing() {
    this.device.customCasing.hasInfoPanel = true;
    this.customCasingService.updateCustomCasing(this.device.customCasing).pipe(
      filter(x => x !== undefined),
      tap(() => {
        this.toastr.showSucces('Info bord toegevoegd', 'Success');
        this.currentStep = 'overview';
      })
    ).subscribe();
  }

  private fetchConfigurations() {
    this.configurations$ = this.smotService.getConfigurations<SmotDTO[]>().pipe(
      filter(x => x !== undefined),
    );
  }

  private fetchDevice(deviceId: number) {
    this.deviceService.getDeviceById(deviceId).pipe(
      filter(x => x !== undefined),
      tap(device => {
        if (this.smotService.checkCompatibilityBetweenConfigAndDevice(device, this.smot)) {
          this.smot.device = device;
        } else {
          this.toastr.showError('De configuraties komen niet overeen', 'Error');
          this.currentStep = 'device';
        }
        this.device = device;
      }),
      filter(() => !!this.smot.device),
      switchMap(() => this.checkDeviceAvailability(+deviceId)),
    ).subscribe();
  }

  private checkDeviceAvailability(deviceId: number) {
    return this.smotService.getSmotByDevice(deviceId).pipe(
      filter(x => x !== undefined),
      switchMap(smot => {
        if (smot) {
          this.promptUserToReplaceDevice(smot);
        } else {
          if (!this.device?.customCasing?.hasInfoPanel) {
            this.currentStep = 'checkInfoPanel';
          } else {
            this.currentStep = 'overview';
          }
        }
        return EMPTY;
      })
    );
  }

  private promptUserToReplaceDevice(smot: SmotDTO) {
    const dialogRef = this.matDialog.open(ConfirmActionModalComponent, {
      data: {
        key: 'Een SMOTSpot met dit toestel bestaat al, wil je dit toestel toch gebruiken?',
        html: '<ul>' +
          `<li>Logical ID: ${smot.logicalId}</li>` +
          `<li>Locatie Beschrijving: ${smot.locationDescription}</li>` +
          `<li>Klant: ${smot.client.clientName}</li>` +
          `<li>Variant: ${smot.clientVariant.clientVariantIdentifier}</li>` +
          `<li>Taal: ${smot.language}</li>` +
          '</ul>'
      }
    });

    dialogRef.afterClosed().pipe(
      filter(x => x !== undefined),
      tap(response => {
        if (response) {
          if (!smot.device.customCasing.hasInfoPanel) {
            this.currentStep = 'checkInfoPanel';
          } else {
            this.currentStep = 'overview';
          }
        } else {
          this.toastr.showError('Dit toestel wordt al gebruikt in een andere SMOTSpot', 'Error');
        }
      })
    ).subscribe();
  }

  private listenToSearchControlChanges() {
    this.filteredConfigurations$ = combineLatest([this.searchControl.valueChanges.pipe(
      startWith('')
    ), this.configurations$]).pipe(
      map(([searchString, configurations]) => {
        this.smot = null;
        return configurations.filter(configuration => this.checkIfSearchStringMatchesSmotProperties(searchString, configuration));
      })
    );
  }

  private checkIfSearchStringMatchesSmotProperties(searchString: string, smot: SmotDTO): boolean {
    return smot.locationDescription?.includes(searchString)
      || smot.client?.clientName?.includes(searchString)
      || smot.language?.includes(searchString)
      || smot.clientVariant?.clientVariantIdentifier?.includes(searchString);
  }
}
