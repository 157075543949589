import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FlowService {

  constructor() { }

  setStepStage(key: string , value: string) {
    localStorage.setItem(key, value);
  }

  getStepStage(key: string) {
    return localStorage.getItem(key);
  }

  clearStepStage(key: string) {
    localStorage.removeItem(key);
  }

}
