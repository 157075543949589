import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {ApiHelperService} from './api-helper.service';
import {BehaviorSubject, EMPTY, Observable, Subject} from 'rxjs';
import {SmotService} from './smot.service';
import {catchError, filter, switchMap} from 'rxjs/operators';
import {DeviceDTO} from '../dtos/deviceDTO';
import {TranslateService} from '@ngx-translate/core';
import {ToastrNotificationService} from './toastr-notification.service';
import {SmotDTO} from '../dtos/SmotDTOs/smotDTO';
import {OperationDTO} from '../dtos/OperationDTOs/operationDTO';
import {SmotProblemReportDTO} from '../dtos/SmotDTOs/smotProblemReportDTO';
import {Sort} from '@angular/material/sort';
import {OperationMappings} from '../models/dtoMappings';
import {CreateOperationDTO} from '../dtos/OperationDTOs/createOperationDTO';

@Injectable({
  providedIn: 'root'
})
export class OperationService {

  private operationUrl = environment.apiRoot + '/operations';

  // OPERATION SCAN
  private operationScanSubject = new Subject<{ casing, backplate, seasonId, customCasingId }>();
  operationScanAction$ =  this.operationScanSubject.asObservable();

  operationScan$ = this.operationScanAction$.pipe(
    switchMap((value) => this.smotService.getSmotFromCasingOrBackplateOrSeasonIdOrCustomCasingId(value.casing, value.backplate, value.seasonId).pipe(
      filter(x => x !== undefined),
      catchError(error => {
        const translatedSmot = this.translate.instant('translate.smot.smotSpot');
        this.toastr.showErrorBasedOnStatus(error.status, translatedSmot);
        return EMPTY;
      })
    ))
  );

  // OPERATION SMOT
  private operationSmotChangedSubject = new BehaviorSubject<SmotDTO>(undefined);
  operationSmot$ = this.operationSmotChangedSubject.asObservable().shareReplay(1);

  constructor(private helper: ApiHelperService,
              private smotService: SmotService,
              private translate: TranslateService,
              private toastr: ToastrNotificationService) {
  }

  setStepStage(stepStage: string) {
    localStorage.setItem('operation_step_stage', stepStage);
  }

  clearStepStage() {
    localStorage.removeItem('operation_step_stage');
  }

  operationEntityScanned(casing: number, backplate: number, seasonId: number, customCasingId: number): Observable<any> {
    this.operationScanSubject.next({
      casing: casing,
      backplate: backplate,
      seasonId: seasonId,
      customCasingId: customCasingId
    });

    return this.smotService.getSmotFromCasingOrBackplateOrSeasonIdOrCustomCasingId(casing, backplate, seasonId, customCasingId).pipe(
      filter(x => x !== undefined)
    );
  }

  operationSmotChanged(smot): void {
    this.operationSmotChangedSubject.next(smot);
  }

  getOperationsForSmotspot<T>(smotspotId: number, operationType: string, mappingTarget: OperationMappings = OperationMappings.DEFAULT): Observable<T[]> {
    const requestUrl = this.operationUrl + '/' + smotspotId + '/' + operationType + '?mappingTarget=' + mappingTarget;
    return this.helper.getRequest<T[]>(requestUrl);
  }

  getOperationsForSmotspotWithPagination<T>(smotspotId: number, operationTypes: String[], page: number, size: number, sort: Sort = null, mappingTarget: OperationMappings = OperationMappings.DEFAULT): Observable<T[]> {
    let url = this.operationUrl + '/' + smotspotId;
    url += this.helper.getPaginationString(page, size, '');
    url += this.helper.getSortString(sort);
    url += '&operationTypes=';
    for (let i = 0; i < operationTypes.length; i++) {
      url += operationTypes[i];
      if (i < operationTypes.length - 1) {
        url += ',';
      }
    }
    url += '&mappingTarget=' + mappingTarget;
    return this.helper.getRequest<T[]>(url);
  }

  getOperationsDataForSmotspotAsPDF(smotspotId: number, operationTypes: String[]) {
    let url = this.operationUrl + '/' + smotspotId + '/pdf';
    url += '?operationTypes=';
    for (let i = 0; i < operationTypes.length; i++) {
      url += operationTypes[i];
      if (i < operationTypes.length - 1) {
        url += ',';
      }
    }
    return this.helper.getRequest(url, { responseType: 'blob' });
  }

  postOperation(operation: CreateOperationDTO): Observable<OperationDTO> {
    return this.helper.postRequest<OperationDTO>(this.operationUrl, operation);
  }

  postOperationProblem(report: SmotProblemReportDTO): Observable<SmotProblemReportDTO> {
    const requestUrl = this.operationUrl + '/problem';
    return this.helper.postRequestWithoutToken<SmotProblemReportDTO>(requestUrl, report);
  }

  removeOperationSmot() {
    this.smotService.setOperationSmot(null);
    this.operationSmotChanged(null);
  }

  fetchOperationImage(operationId: number, suffix: string): Observable<Blob> {
    const url = environment.apiRoot + `/fileUpload/operation/${operationId}?suffix=${suffix}`;
    return this.helper.getRequest<Blob>(url, {responseType: 'blob'});
  }
}
