import {Component, EventEmitter, Inject, Input, OnInit, Optional, Output} from '@angular/core';
import {catchError, filter, map, shareReplay, tap} from 'rxjs/operators';
import {ClientVariantDTO} from '../../../dtos/ClientDTOs/ClientVariantDTO';
import {IDropdownOption} from '../../../components/form-fields/drop-down-field/drop-down-field.component';
import {InputModalComponent} from '../../plugins/input-modal/input-modal.component';
import {languages} from '../../lng-picker/lng-picker.component';
import {of} from 'rxjs/internal/observable/of';
import {ClientMappings} from '../../../models/dtoMappings';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {EMPTY, Observable} from 'rxjs';
import {ClientService} from '../../../services/client.service';
import {ClientVariantService} from '../../../services/client-variant.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ToastrNotificationService} from '../../../services/toastr-notification.service';
import {CustomCasingDTO} from '../../../dtos/CustomCasingDTOs/customCasingDTO';

@Component({
  selector: 'app-create-custom-casing-form',
  templateUrl: './create-custom-casing-form.component.html',
  styleUrls: ['./create-custom-casing-form.component.scss']
})
export class CreateCustomCasingFormComponent implements OnInit {
  @Output() customCasingCreated: EventEmitter<CustomCasingDTO> = new EventEmitter<CustomCasingDTO>();
  @Input() customCasing: CustomCasingDTO;

  // Dropdown options
  languageOptions$: Observable<IDropdownOption[]>;
  clientOptions$: Observable<IDropdownOption[]>;
  variantOptions$: Observable<IDropdownOption[]> = of(undefined);

  // Form Controls
  customCasingForm: FormGroup;
  languageControl = new FormControl(null, Validators.required);
  clientControl = new FormControl(null, Validators.required);
  clientVariantControl = new FormControl(null, [Validators.required, Validators.min(1)]);
  infoPanelControl = new FormControl(false);

  // Variant control
  disableVariantControl: boolean = true;
  createVariantOption: IDropdownOption = {
    value: 'CREATE_NEW',
    label: '+ Nieuwe variant aanmaken'
  };

  disableClientControl: boolean = false;

  constructor(private clientService: ClientService,
              private clientVariantService: ClientVariantService,
              private matDialog: MatDialog,
              private toastr: ToastrNotificationService,
              @Optional() private matDialogRef: MatDialogRef<CreateCustomCasingFormComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data) {
    if (this.data?.customCasing) {
      this.customCasing = this.data?.customCasing;
    }
  }

  ngOnInit(): void {
    this.disableClientControl = !!this.customCasing?.customCasingId;
    this.createForm();
    this.initClients();
    this.initLanguages();
  }

  public handleClientSelection(event) {
    this.clientVariantControl.setValue(0);
    this.variantOptions$ = this.fetchClientVariantByClientNumber(event, this.customCasing?.clientVariantId);
  }

  private fetchClientVariantByClientNumber(clientNumber: number, selectedClientVariantId: number = null) {
    return this.clientVariantService.getClientVariantsByClientNumber(clientNumber).pipe(
      filter(x => x !== undefined),
      map((clientVariants: ClientVariantDTO[]) => clientVariants.map(variant => ({
        value: variant.clientVariantId,
        label: variant.clientVariantIdentifier,
        selected: selectedClientVariantId ? selectedClientVariantId === variant.clientVariantId : variant.clientVariantIdentifier === 'Default'
      }) as IDropdownOption)),
      map(options => {
        options.push(this.createVariantOption);
        this.disableVariantControl = false;
        return options;
      }),
      shareReplay(1)
    );
  }

  public handleClientVariantSelection(event) {
    if (event === this.createVariantOption.value) {
      this.clientVariantControl.setValue(0);
      const dialogRef = this.matDialog.open(InputModalComponent, {
        data: {
          key: 'translate.create.addClientVariant',
        }
      });

      dialogRef.afterClosed().pipe(
        tap(value => {
          this.createNewClientVariant(this.clientControl.value, value);
        }),
      ).subscribe();
    }
  }

  public createCustomCasing() {
    this.customCasingForm.markAsTouched();
    if (this.customCasingForm.invalid) {
      return;
    }

    if (this.matDialogRef) {
      this.matDialogRef.close({
        ...this.customCasing,
        clientVariantId: this.clientVariantControl.value,
        language: this.languageControl.value,
        hasInfoPanel: this.infoPanelControl.value,
      });
    } else {
      this.customCasingCreated.emit({
        ...this.customCasing,
        clientVariantId: this.clientVariantControl.value,
        language: this.languageControl.value,
        hasInfoPanel: this.infoPanelControl.value,
      });
    }

  }

  private initLanguages() {
    const tempLanguages = [];
    languages.forEach(language => {
      tempLanguages.push({
        value: language.fileName,
        label: language.language,
        selected: language.fileName === this.customCasing?.language,
      });
    });
    this.languageOptions$ = of(tempLanguages);
  }

  private initClients() {
    this.clientOptions$ = this.clientService.getClients(ClientMappings.DROPDOWN_CLIENT_DTO).pipe(
      filter(x => x !== undefined),
      map(clients => clients.map(client => ({
        value: client.clientNumber,
        label: client.clientName,
        selected: this.customCasing?.clientName === client.clientName
      }) as IDropdownOption)),
      tap(clients => {
        const selectedClient = clients.find(client => client.selected);
        if (selectedClient) {
          this.handleClientSelection(selectedClient.value);
        }
      }),
      shareReplay(1)
    );
  }

  private createForm() {
    this.infoPanelControl.setValue(!!this.customCasing?.hasInfoPanel);
    this.customCasingForm = new FormGroup({
      'language': this.languageControl,
      'clientVariant': this.clientVariantControl,
      'client': this.clientControl,
      'hasInfoPanel': this.infoPanelControl
    });
  }

  private createNewClientVariant(clientNumber: number, variantName: string) {
    const clientVariant = {
      clientNumber: clientNumber,
      clientVariantIdentifier: variantName
    } as ClientVariantDTO;

    this.clientVariantService.createClientVariant(clientVariant).pipe(
      filter(x => x !== undefined),
      tap((createdVariant) => {
        this.clientVariantControl.reset();
        this.variantOptions$ = this.fetchClientVariantByClientNumber(clientNumber, createdVariant.clientVariantId);
        this.toastr.showSucces('Successfully created a new client variant', 'Succes');
      }),
      catchError(err => {
        this.toastr.showErrorBasedOnStatus(err.status);
        return EMPTY;
      })
    ).subscribe();
  }
}
