import * as _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataFilter'
})
export class DataFilterPipe implements PipeTransform {

  transform(array: any[], query: string): any {
    if (query && array.length >= 1) {
      return array.filter(row =>  {
        let contains = false;
        Object.values(row).forEach(item => {
          if (item !== null && (isNaN(Number('' + item)) || ('' + item).length >= 9) ) {
            if ( ('' + item).toLowerCase().includes(query.toLowerCase())) {
              contains = true;
            }
          } else {
            if (item !== null && !(isNaN(Number('' + item)))) {
              if ((item + '').indexOf(query.toLowerCase()) > -1) {
                contains = true;
              }
            }
          }
        });
        return contains;
      } );
    }
    return array;
  }
}
