import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OperationsRoutingModule} from './operations-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {SmotOperationComponent} from './smot-operation/smot-operation.component';
import {PluginsModule} from '../plugins/plugins.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatExpansionModule} from '@angular/material/expansion';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatListModule} from '@angular/material/list';
import {FormFieldsModule} from '../../components/form-fields/form-fields.module';
import {StartRouteComponent} from './routes/start-route/start-route.component';
import {RouteOverviewComponent} from './routes/route-overview/route-overview.component';
import {RouteMediaComponent} from './routes/route-media/route-media.component';
import {DirectivesModule} from '../../directives/directives.module';
import {ScannerModule} from '../../components/scanner/scanner.module';
import {MatIconModule} from '@angular/material/icon';
import {SundoHeadersModule} from '../../components/headers/sundo-headers.module';
import {SundoButtonsModule} from '../../components/buttons/sundo-buttons.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { SelectRepairPartComponent } from './select-repair-part/select-repair-part.component';
import {SmotModule} from '../smot/smot.module';
import {PipeModule} from '../../pipes/pipe.module';
import {SharedViewsModule} from '../shared/sharedViews.module';
import {SmotScannerComponent} from './smot-scanner/smot-scanner.component';


@NgModule({
  declarations: [
    SmotOperationComponent,
    StartRouteComponent,
    RouteOverviewComponent,
    RouteMediaComponent,
    SelectRepairPartComponent,
    SmotScannerComponent
  ],
  exports: [
    RouteMediaComponent
  ],
  imports: [
    SharedViewsModule,
    CommonModule,
    OperationsRoutingModule,
    TranslateModule,
    PluginsModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatExpansionModule,
    NgxMaterialTimepickerModule,
    MatCheckboxModule,
    MatListModule,
    FormsModule,
    FormFieldsModule,
    DirectivesModule,
    ScannerModule,
    MatIconModule,
    SundoHeadersModule,
    SundoButtonsModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    SmotModule,
    PipeModule,
  ]
})
export class OperationsModule { }
