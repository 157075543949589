<div class="d-flex justify-content-center">
  <div *ngIf="smot$ | async as smot" class="sundo-container medium">
    <div class="smot-name">
      {{smot.locationDescription}}
    </div>
    <div class="d-flex justify-content-center">
      <div class="buttons">
        <ng-container *ngFor="let button of operationLinks">
          <app-warning-button [class.disabled]="smot.isActive !== 1 || disableButtons"
                              [routerLink]="[button.link]"
                              [size]="'big'">{{button.buttonKey}}</app-warning-button>
        </ng-container>
        <app-warning-button *ngIf="user?.hasAccessToRoutes"
                            [disabled]="disableButtons"
                            [size]="'big'"
                            (click)="displayAddCommentModal(smot)">
          {{'translate.routes.addComment' | translate}}
        </app-warning-button>
        <app-warning-button *ngIf="user?.hasAccessToRoutes"
                            [disabled]="disableButtons"
                            [size]="'big'"
                            (click)="displayAddCommentModal(smot, true)">
          {{'translate.routes.addInfo' | translate}}
        </app-warning-button>
        <app-warning-button [disabled]="disableButtons"
                            [size]="'big'"
                            [routerLink]="['/report-problem/report-language']"
                            [state]="{seasonId: smot.seasonId, user: user}">
          {{'translate.report.reportAProblem' | translate}}
        </app-warning-button>
      </div>
    </div>
  </div>
</div>
