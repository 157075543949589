import {Type} from '@angular/core';
import {CreateCustomCasingComponent} from '../views/production/create-custom-casing/create-custom-casing.component';
import {CreateDeviceComponent} from '../views/production/create-device/create-device.component';
import {CreateConfigurationComponent} from '../views/production/create-configuration/create-configuration.component';
import {CreateSmotSpotComponent} from '../views/production/create-smot-spot/create-smot-spot.component';
import {RefillComponent} from '../views/shared/flow/flow-steps/refill/refill.component';
import {
  FinishCreateSmotComponent
} from '../views/production/create-smot-spot/finish-create-smot/finish-create-smot.component';

export const CREATE_CUSTOM_CASING_FLOW: Flow = {
  steps: [
    {
      component: CreateCustomCasingComponent,
      data: {}
    }
  ]
};

export const CREATE_DEVICE_FLOW: Flow = {
  steps: [
    {
      component: CreateDeviceComponent,
      data: {}
    }
  ]
};

export const CREATE_CONFIGURATION_FLOW: Flow = {
  steps: [
    {
      component: CreateConfigurationComponent,
      data: {}
    }
  ]
};

export const CREATE_SMOT_FLOW: Flow = {
  steps: [
    {
      component: CreateSmotSpotComponent,
      data: {}
    },
    {
      component: RefillComponent,
      data: {}
    },
    {
      component: FinishCreateSmotComponent,
      data: {}
    }
  ]
};



interface Flow {
  steps: FlowStep[];
}

interface FlowStep {
  component: Type<any>;
  data: FlowStepData;
}

interface FlowStepData {
  headerString?: string;
  expectedEntityType?: 'smot' | 'device' | 'customCasing' | 'casing' | 'seasonId' | 'backplate';
  expectedId?: number;
}
