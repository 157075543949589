import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {JsQrScannerComponent} from './js-qr-scanner/js-qr-scanner.component';
import {FormFieldsModule} from '../form-fields/form-fields.module';
import {TranslateModule} from '@ngx-translate/core';
import {SundoButtonsModule} from '../buttons/sundo-buttons.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {SundoHeadersModule} from '../headers/sundo-headers.module';

@NgModule({
  declarations: [
    JsQrScannerComponent
  ],
  exports: [
    JsQrScannerComponent
  ],
  imports: [
    CommonModule,
    FormFieldsModule,
    TranslateModule,
    SundoButtonsModule,
    SundoHeadersModule
  ]
})
export class ScannerModule { }
