import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import {Observable} from 'rxjs';
import { SmotDTO } from '../../../../dtos/SmotDTOs/smotDTO';
import {BaseFlowStepComponent} from '../../../shared/flow/flow-steps/base-flow-step.interface';
import {SmotService} from '../../../../services/smot.service';
import {filter, switchMap, tap} from 'rxjs/operators';
import {InputModalComponent} from '../../../plugins/input-modal/input-modal.component';
import {CommentType} from '../../../../models/commentType';
import {ResolutionStatus} from '../../../../models/resolutionStatus';
import moment from 'moment/moment';
import {CreateRouteStepCommentDTO} from '../../../../dtos/RouteDTOs/createRouteDTO';
import {MatDialog} from '@angular/material/dialog';
import {RouteService} from '../../../../services/route.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrNotificationService} from '../../../../services/toastr-notification.service';

@Component({
  selector: 'app-finish-create-smot',
  template: `
    <div class="sundo-container medium h-100 m-auto">
      <div class="back-button" (click)="goToPreviousStep()">
        <mat-icon >keyboard_arrow_left</mat-icon>
      </div>
      <app-h2 class="text-center">Smot succesvol aangemaakt</app-h2>
      <div class="buttons">
        <app-warning-button [fullWidth]="true" (click)="displayAddCommentModal()">Voeg opmerking toe</app-warning-button>
        <app-warning-button [fullWidth]="true" (click)="pdfGenerator.openPrintDialog()">Print label</app-warning-button>
        <app-warning-button [fullWidth]="true" (click)="router.navigate(['/production'])">Terug naar overzicht</app-warning-button>
        <app-label-generator #pdfGenerator [entityType]="'SMOT'" [entity$]="smot$"></app-label-generator>
      </div>
  </div>
  `,
  styleUrls: ['../../../operations/operations.scss'],
})
export class FinishCreateSmotComponent implements OnInit, BaseFlowStepComponent {
  @Input() data: any;
  @Output() nextStep: EventEmitter<true> = new EventEmitter();
  @Output() previousStep: EventEmitter<false> = new EventEmitter();

  smot$: Observable<SmotDTO>;

  constructor(public router: Router,
              private dialog: MatDialog,
              private routeService: RouteService,
              private translate: TranslateService,
              private smotService: SmotService,
              private toastr: ToastrNotificationService) {
  }

  ngOnInit(): void {
    this.smot$ = this.smotService.getSmot(this.data.smotId).pipe(
      filter(x => x !== undefined)
    );
  }

  public goToPreviousStep() {
    this.previousStep.emit(false);
  }

  displayAddCommentModal() {
    const dialog = this.dialog.open(InputModalComponent, {
      data: {
        key: 'translate.operations.smot-maintenance.addACommentToThisSMOTSpot',
        multiline: true
      }
    });

    dialog.afterClosed().pipe(
      filter(x => x !== undefined),
      switchMap(comment => {
        const commentDTO = {
          type: CommentType.COMMENT,
          smotLogicalId: this.data.smotId,
          content: comment,
          resolutionStatus: ResolutionStatus.UNRESOLVED,
          creationDate: moment().utc()
        } as CreateRouteStepCommentDTO;

        return this.routeService.createComment(commentDTO).pipe(
          filter(x => x !== undefined),
          tap(() => this.toastr.showSucces(this.translate.instant('translate.routes.addCommentSuccessful'), 'Success'))
        );
      })
    ).subscribe();
  }
}
