import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {catchError, filter, switchMap, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ToastrNotificationService} from '../../services/toastr-notification.service';
import {EMPTY} from 'rxjs';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  usernameControl = new FormControl('', [Validators.required]);
  passwordControl = new FormControl('', [Validators.required]);
  loading = false;
  serverError = false;
  extras;
  previousRoute: string = '';

  constructor(private auth: AuthenticationService,
              private router: Router,
              private userService: UserService,
              private translate: TranslateService,
              private toastr: ToastrNotificationService) {
    this.extras = this.router.getCurrentNavigation().extras.state;
    this.previousRoute = this.router.getCurrentNavigation()?.previousNavigation?.finalUrl.toString();
  }

  ngOnInit() {
    this.auth.logout();
    this.createForm();
  }

  public tryLogin() {
    this.serverError = false;

    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;

    const controls = this.loginForm.controls;
    this.auth.login(controls.username.value, controls.password.value).pipe(
      switchMap(ignored => this.auth.getLoggedInUserAuth()),
      filter(x => x !== undefined),
      tap(user => {
        this.translate.use(user.language ?? 'en');
        this.auth.setSessionRole(user.authority);

        if (this.extras) {
          if (this.previousRoute.toLowerCase() === '/operations') {
            this.router.navigate(['operations'], {queryParams: {
                bck: this.extras.bckId,
                cas: this.extras.casId
              }});
          } else {
            this.router.navigate([this.previousRoute]);
          }
        } else {
          this.router.navigate(['dashboard']);
        }
      }),
      catchError(err => {
        if (err.status === 401) {
          this.toastr.showError(this.translate.instant('translate.login.wrongLogin'), this.translate.instant('translate.general.error.error'));
        } else {
          this.serverError = true;
          this.toastr.showErrorBasedOnStatus(err.status);
        }
        this.loading = false;
        return EMPTY;
      })
    ).subscribe();
  }

  private createForm() {
    this.loginForm = new FormGroup({
      username: this.usernameControl,
      password: this.passwordControl
    });
  }
}
