import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {UserService} from '../../../services/user.service';
import {catchError, filter, tap} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {ToastrNotificationService} from '../../../services/toastr-notification.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../reset-password/reset-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  submitted: boolean = false;
  error: boolean = false;
  username: FormControl = new FormControl('', Validators.required);
  constructor(private userService: UserService,
              private toastr: ToastrNotificationService,
              private translate: TranslateService) { }

  ngOnInit(): void {
  }

  sendForgotPassword() {
    this.userService.sendForgotPasswordMail(this.username.value)
      .pipe(
        filter(x => x !== undefined),
        tap(() => this.submitted = true),
        catchError(err => {
          if (err.status === 404) {
            this.toastr.showError(this.translate.instant('translate.statusError.notFound',
              {
                item: this.translate.instant('translate.user.user')
              }), this.translate.instant('translate.general.error.error'));
            return EMPTY;
          }
          this.submitted = true;
          this.error = true;
          return EMPTY;
        })
      ).subscribe();
  }
}
