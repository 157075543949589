
  <div class="row">
    <div class="d-flex justify-content-between w-100 pr-4">
      <mat-form-field appearance="fill" class="pl-3">
        <mat-label>Operation types</mat-label>
        <mat-select #select [formControl]="selectedOperationTypes" multiple>
          <mat-option *ngFor="let opType of operationTypes" [value]="opType">{{opType}}</mat-option>
        </mat-select>
      </mat-form-field>
      <app-warning-button [disabled]="loadingDownload " (click)="downloadPdf()">Download PDF</app-warning-button>
    </div>
    <div class="col-sm-12">
      <app-sundo-table
        [$dataSource]="smotOperations$"
        [columns]="columns"
        [hideSearch]="true"
        (dataChangeRequested)="handleDataRequest($event)"
        (infoClicked)="openDialog($event)"
      ></app-sundo-table>
    </div>
  </div>
