export enum ClientMappings {
  DEFAULT = 'default',
  DROPDOWN_CLIENT_DTO = 'dropdownClientDTO'
}

export enum SmotMappings {
  DEFAULT = 'default',
  TABLE_SMOT_DTO = 'tableSmotDTO',
  SMOT_CREATE_DTO = 'smotCreateDTO',
  GOOGLE_MAPS_SMOT_DTO = 'googleMapsSmotDTO',
  DROPDOWN_SMOT_DTO = 'dropdownSmotDTO',
  ROUTE_SMOT_DTO = 'routeSmotDTO'
}

export enum OperationMappings {
  DEFAULT = 'default',
  OPERATION_TIME_DTO = 'operationTimeDTO',
  CREATE_OPERATION_DTO = 'createOperationDTO',
  OPERATION_TABLE_DTO = 'operationTableDTO',
}

export enum UserMappings {
  DEFAULT = 'default',
  USER_PROFILE_DTO = 'userProfileDTO',
  USER_AUTHENTICATION_DTO = 'userAuthenticationDTO'
}

export enum RouteMappings {
  DEFAULT = 'default',
  TABLE_ROUTE_DTO = 'tableRouteDTO',
}

export enum PlannedRouteMappings {
  DEFAULT = 'default',
  CREATE_PLANNED_ROUTE_DTO = 'createPlannedRouteDTO',
  TABLE_PLANNED_ROUTE_DTO = 'tablePlannedRouteDTO',
  CARD_PLANNED_ROUTE_DTO = 'cardPlannedRouteDTO',
}

export enum CustomCasingMappings {
  DEFAULT = 'default',
}

export enum BadConfigMappings {
  DEFAULT = 'default',
  GOOGLE_MAP_BAD_CONFIG_DTO = 'googleMapBadConfigDTO',
}

