import {AfterViewInit, Component, ElementRef, Inject, Input, ViewChild} from '@angular/core';
import Cropper from 'cropperjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-edit-img-modal',
  template: `
    <div class="sundo-container medium hide-overflow">
      <img #image [src]="imageSource" crossorigin id="originalImage">
      <div class="row">
        <div class="col-12 col-md-6 d-flex justify-content-center">
          <img [src]="imagePreview" id="previewImage">
        </div>

        <div class="col-12 col-md-6 position-relative">
          <app-primary-button class="center" id="submit-button" (click)="close()" >
            {{submit}}
          </app-primary-button>
        </div>
      </div>
    </div>
`,
  styles: ['#originalImage { max-width: 800px; max-height:200px; margin: 20px; }' +
  '#previewImage { max-width: 200px; border-radius: 100%; margin: 20px 0; }']
})
export class EditImgModalComponent implements  AfterViewInit {

  @ViewChild('image', {static: false})
  public imageElement: ElementRef;
  public submit: string = this.translate.instant('translate.form.submit');

  @Input()
  public imageSource: string;

  public imagePreview: string;
  private cropper: Cropper;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private translate: TranslateService,
              public dialogRef: MatDialogRef<EditImgModalComponent>,
              ) {
    this.imageSource = data.imageSource;
  }

  ngAfterViewInit(): void {
    this.cropper = new Cropper(this.imageElement.nativeElement, {
      modal: true,
      autoCropArea: 1,
      aspectRatio: 1,
      zoomable: true,
      scalable: false,
      zoom: (event) => {
        if (event.detail.ratio >= 1) {
          event.preventDefault();
        }
        },
      crop: () => {
        const canvas = this.cropper.getCroppedCanvas({
          width: 300,
          height: 300,
          minHeight: 1024,
          minWidth: 1024,
          imageSmoothingEnabled: true,
          imageSmoothingQuality: 'high',
        });
        this.imagePreview = canvas.toDataURL('image/webp', 0.6);
      },
    });
  }

  close() {
    this.dialogRef.close(this.imagePreview);
  }
}
