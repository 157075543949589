import { BaseFlow } from './base-flow.class';
import {RouteService} from '../../services/route.service';
import {
  ROUTE_CUSTOM_FLOW,
  ROUTE_DEINSTALL_FLOW,
  ROUTE_INSTALL_FLOW,
  ROUTE_MAINTENANCE_FLOW
} from '../../models/routeFlows';
import { Params } from '@angular/router';
import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {ToastrNotificationService} from '../../services/toastr-notification.service';
import _ from 'lodash';
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';
import {PlannedRouteDTO} from '../../dtos/RouteDTOs/plannedRouteDTO';
import moment from 'moment';
import { EMPTY } from 'rxjs';
import { OperationService } from '../../services/operation.service';
import {CreateOperationDTO} from '../../dtos/OperationDTOs/createOperationDTO';
import {FlowService} from '../../services/flow.service';

@Injectable({
  providedIn: 'root'
})
export class RouteFlow extends BaseFlow {
  constructor(private routeService: RouteService,
              public location: Location,
              public toastr: ToastrNotificationService,
              public flowService: FlowService,
              private operationService: OperationService) {
    super(toastr, location, flowService);
    this.key = 'route_step_stage';
  }

  getFlow(params: Params): any {
    switch (params['flow']) {
      case 'installation':
        this.flow = _.cloneDeep(ROUTE_INSTALL_FLOW);
        return this.flow;
      case 'deinstallation':
        this.flow = _.cloneDeep(ROUTE_DEINSTALL_FLOW);
        return this.flow;
      case 'custom':
        this.flow = _.cloneDeep(ROUTE_CUSTOM_FLOW);
        return this.flow;
      case 'maintenance':
        this.flow = _.cloneDeep(ROUTE_MAINTENANCE_FLOW);
        return this.flow;
      default:
        return null;
    }
  }

  getCurrentStep(params: Params): any {
    const step = super.getCurrentStep(params);

    // Update any step specific properties
    if (step.data.expectedEntityType) {
      step.data.expectedId = params['smotId'];
    }

    // Get SMOTSpot and define step data
    step.data = {
      ...step.data,
      plannedRouteId: params['plannedRouteId'],
      stepId: params['stepId'],
      smotId: params['smotId']
    };

    return step;
  }

  finishFlow(): void {
    // Mark the step as completed
    this.routeService.getPlannedRouteById<PlannedRouteDTO>(this.params['plannedRouteId']).pipe(
      filter(x => x !== undefined),
      switchMap(plannedRoute => {
        const stepIndex = plannedRoute.plannedRouteSteps.findIndex(step => step.routeStep.routeStepId === +this.params['stepId']);
        plannedRoute.plannedRouteSteps[stepIndex].finishTime = moment().utc();
        return this.routeService.updatePlannedRoute(plannedRoute);
      }),
      filter(x => x !== undefined),
      tap(() => {
        super.finishFlow();
        this.routeService.clearStepStage();
      }),
      catchError(error => {
        this.toastr.showErrorBasedOnStatus(error.status);
        return EMPTY;
      })
    ).subscribe();

    switch (this.params['flow']) {
      case 'maintenance':
        const operation: CreateOperationDTO = {
          operationType: 'CLEAN',
          info: null,
          smotLogicalId: this.params['smotId'],
        };
        this.operationService.postOperation(operation).subscribe();
        break;
    }
  }
}
