import { Component } from '@angular/core';

@Component({
  selector: 'app-500',
  templateUrl: '500.component.html'
})
export class P500Component {

  constructor() { }

}
