import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-danger-button',
  template: `
    <button class="danger small-button"
            [class.extra-small-button]="size === 'extra-small'"
            [class.small-button]="size === 'small'"
            [class.big-button]="size === 'big'"
            [class.disabled]="disabled"
            [class.w-100]="fullWidth"
            [disabled]="disabled || loading"
            [type]="type">
      <ng-container *ngIf="!loading else spinner">
        <ng-content></ng-content>
      </ng-container>
      <ng-template #spinner>
        <div class="d-flex justify-content-center">
          <mat-spinner style="margin-top: 3px" [color]="'accent'" [diameter]="20" [mode]="'indeterminate'"></mat-spinner>
        </div>
      </ng-template>
    </button>
  `,
  styleUrls: ['../button-styles.scss'],
})
export class DangerButtonComponent implements OnInit {
  @Input() size: 'extra-small' | 'small' | 'normal' | 'big' = 'normal';
  @Input() fullWidth: boolean = false;
  @Input() disabled: boolean = false;
  @Input() type: 'button' | 'submit' | 'reset' = 'button';
  @Input() loading: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
