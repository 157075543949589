import { Injectable } from '@angular/core';
import {ApiHelperService} from './api-helper.service';
import {environment} from '../../environments/environment';
import { PackagingDTO } from '../dtos/packagingDTO';
import {RoutePackageDTO} from '../dtos/SmotDTOs/routeSmotDTO';

@Injectable({
  providedIn: 'root'
})
export class PackageService {
  private packageUrl = environment.apiRoot + '/packages';

  constructor(private helper: ApiHelperService) { }

  /**
   * GET packaging by id
   */
  public getPackaging(id: number) {
    const url = `${this.packageUrl}/${id}`;
    return this.helper.getRequest<PackagingDTO>(url);
  }


  /**
   * PUT update an existing package
   */
  public savePackaging(packagingDTO: PackagingDTO | RoutePackageDTO) {
    return this.helper.putRequest(this.packageUrl, packagingDTO);
  }
}
