import { Injectable } from '@angular/core';
import {ApiHelperService} from './api-helper.service';
import {Observable} from 'rxjs';
import {BackplateDTO} from '../dtos/BackplateDTO';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BackplateService {
  private backplateUrl = environment.apiRoot + '/backplates';

  constructor(private api: ApiHelperService) { }

  public getBackplate(id: number): Observable<BackplateDTO> {
    return this.api.getRequest(this.backplateUrl + `/${id}`);
  }
}
