import {Component, OnInit, ViewChild} from '@angular/core';
import {ChartDataSets} from 'chart.js';
import {SmotService} from '../../services/smot.service';
import {catchError, filter, map, tap} from 'rxjs/operators';
import {PacketService} from '../../services/packet.service';
import {DatePipe} from '@angular/common';
import {OperationService} from '../../services/operation.service';
import {FormControl, FormGroup} from '@angular/forms';
import {IssueService} from '../../services/issue.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication.service';
import {MatDialog} from '@angular/material/dialog';
import {combineLatest, Observable} from 'rxjs';
import {Role} from '../../models/role';
import {of} from 'rxjs/internal/observable/of';
import {ToastrNotificationService} from '../../services/toastr-notification.service';
import {OperationMappings} from '../../models/dtoMappings';
import {OperationTimeDTO} from '../../dtos/OperationDTOs/operationTimeDTO';
import moment, {Moment} from 'moment';
import {RouteService} from '../../services/route.service';
import {DataRequest, TableColumn} from '../tables/table/table.component';
import {MatSort, Sort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {IssueDTO} from '../../dtos/IssueDTOs/issueDTO';
import {RouteDTO} from '../../dtos/RouteDTOs/routeDTO';


@Component({
  selector: 'app-smot-detail',
  templateUrl: './smot-detail.component.html',
  styleUrls: ['./smot-detail.component.scss']
})
export class SmotDetailComponent implements OnInit {
  //region form
  dateForm: FormGroup;
  dateFromControl: FormControl = new FormControl(new Date(new Date().setDate(new Date().getDate() - 7)));
  dateUntilControl: FormControl = new FormControl(new Date());
  maxDate = new Date();
  //endregion

  //region sunscreenChart
  refills$: Observable<OperationTimeDTO[]>;

  sunscreenChartData$: Observable<{ datasets: ChartDataSets[], labels: string[], options }>;
  sunscreenChartDataSet: ChartDataSets = {
    label: 'Sunscreen level',
    maxBarThickness: 20
  };
  sunscreenChartOptions: any = {
    scaleShowVerticalLines: false,
    legend: false,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            max: 1,
            beginAtZero: true
          }
        }
      ],
    }
  };
  //endregion

  //region batteryChart
  batteryChartData$: Observable<{ datasets: ChartDataSets[], labels: string[], options, events: number[] }>;
  batteryChartDataSet: ChartDataSets = {
    label: 'Battery level',
    maxBarThickness: 20,
  };
  batteryChartOptions: any = {
    scaleShowVerticalLines: false,
    legend: false,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            max: 100,
            beginAtZero: true
          }
        }
      ],
    }
  };

  //endregion

  //region issueChart
  issueChartData$: Observable<{ datasets: ChartDataSets[], labels: string[], options }>;
  issueChartDataSet: ChartDataSets = {
    label: 'Issues',
    maxBarThickness: 40
  };
  issueChartOptions: any = {
    scaleShowVerticalLines: false,
    legend: false,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ],
    }
  };
  //endregion

  //region smot
  smot$: Observable<any>;
  //endregion

  //region routes
  userHasAccessToRoutes$: Observable<boolean> = of(false);
  smotRoutes$: Observable<any>;
  smotRouteColumns: TableColumn[] = [
    {
      dbProperty: 'route_id',
      field: 'routeId',
      header: 'translate.smot-detail.smotInfo.smotId',
      sortable: true,
      textAlign: 'left'
    },
    {
      dbProperty: 'description',
      field: 'routeDescription',
      header: 'translate.routes.routeName',
      sortable: true,
      textAlign: 'left'
    },
    {
      dbProperty: 'next_planned_date',
      field: 'nextPlannedDate',
      header: 'translate.routes.planned',
      sortable: true,
      textAlign: 'left'
    }
  ];
  lastEventValues: DataRequest;
  //endregion

  //region issuesTable
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  allIssues: any = [];
  smotIssues = new MatTableDataSource<any>();
  activeLabelFilter: String;
  displayedColumns: string[] = ['errorCode', 'timeOfError', 'severity'];
  //endregion
  constructor(private smotService: SmotService,
              private packetService: PacketService,
              private operationService: OperationService,
              private issueService: IssueService,
              private datePipe: DatePipe,
              private auth: AuthenticationService,
              private route: ActivatedRoute,
              public dialog: MatDialog,
              private toastr: ToastrNotificationService,
              private routeService: RouteService,
              private authService: AuthenticationService,
              private router: Router,
              ) {
    this.route.params.subscribe(params => {
      this.smot_logical_id = params['id'];
    });
  }
  temp: Array<any> = [];
  private smot_logical_id: number;
  isPartnerOrAdmin: boolean = false;


  private static getColorBasedOnSeverity(severity) {
    switch (severity) {
      case 'LOW':
        return 'rgb(66, 186, 150)';
      case 'MODERATE':
        return 'rgb(255, 193, 7)';
      case 'SEVERE':
        return 'rgb(223, 71, 89)';
    }
  }

  private static convertSeverityToNumber(severity): number {
    switch (severity) {
      case 'LOW':
        return 1;
      case 'MODERATE':
        return 2;
      case 'SEVERE':
        return 3;
    }
  }

  ngOnInit(): void {
    this.createForm();
    this.subscribeToFormChanges();
    this.getIssuesForSmot();
    this.getBatteryDataForSmot();
    this.getRefillData();
    this.combineBatteryAndRefillDataIntoSunscreenData();
    this.getSmot();
    this.fetchRoutesForSmotSpot(0, 10, '', null);
    this.checkIfUseHasAccessToRoutes();
    // Fetch sunscreen level data and map to usable data
    if (this.auth.getRole() === Role.ADMIN || this.auth.getRole() === Role.PARTNER) {
      this.isPartnerOrAdmin = true;
    }
  }


  public navigateToRoute(route: RouteDTO) {
    this.router.navigate(['/route', route.routeId]);
  }

  handleDataChangeRequested(event: DataRequest) {
    this.lastEventValues = event;
    this.fetchRoutesForSmotSpot(event.pageNumber, event.pageSize, event.searchString, event.sort);
  }

  public clearFilter() {
    this.activeLabelFilter = null;
    this.smotIssues.data = this.allIssues;
  }

  public handleChartClick(event) {
    if (!event.active[0]) {
      return;
    }

    const label = event.active[0]._model.label;
    // If the same label has been pressed again -> reset the filter
    if (this.activeLabelFilter === label) {
      this.smotIssues.data = this.allIssues;
      this.activeLabelFilter = null;
      return;
    }

    // Filter the data by label
    this.activeLabelFilter = label;
    this.smotIssues.data = this.allIssues.filter((issue: IssueDTO) => issue.errorCode === label);
  }

  private getBatteryDataForSmot() {
    this.batteryChartData$ = null;
    const fromDate: Moment = moment(this.dateFromControl.value).startOf('day');
    const untilDate: Moment = moment(this.dateUntilControl.value).endOf('day');

    const dates = this.getDatesInRange(fromDate, untilDate);

    this.batteryChartData$ = this.packetService.getSmotspotPackets(this.smot_logical_id, fromDate, untilDate).pipe(
      filter(x => x !== undefined),
      map(packets => {
        const labels = [];
        const data = [];
        const events = [];
        if (packets && packets.length > 0) {
          for (let i = 0; i < dates.length - 1; i++) {
            const packet = packets.find(p => p[0] === dates[i]);
            if (packet) {
              const percentage = (packet[1] / 144 * 100);
              labels.push(dates[i]);
              data.push(percentage > 100 ? 100 : percentage.toPrecision(4));
              events.push(packet[2]);
            } else {
              labels.push(dates[i]);
              data.push(0);
              events.push(0);
            }
          }
        }

        return ({
          datasets: [{
            ...this.batteryChartDataSet,
            data: data,
          }],
          options: this.batteryChartOptions,
          labels: labels,
          events: events
        }) as { datasets: ChartDataSets[], labels: string[], options, events: number[] };
      })).shareReplay(1);
  }

  private getIssuesForSmot() {
    this.issueChartData$ = null;
    const fromDate: Moment = moment(this.dateFromControl.value).startOf('day');
    const untilDate: Moment = moment(this.dateUntilControl.value).endOf('day');
    this.issueChartData$ = this.issueService.getIssuesSmotspotAndBetweenDates(this.smot_logical_id, fromDate, untilDate, true).pipe(
          filter(x => x !== undefined),
          map((issues: any) => {
            const values = [];
            const keys = [];
            const colors = [];
            this.allIssues = issues;
            this.smotIssues.data = issues;
            this.smotIssues.paginator = this.paginator;
            this.smotIssues.sort = this.sort;

            if (issues != null) {
              let grouped = this.groupBy(issues, issue => issue.errorCode);
              grouped = new Map([...grouped].sort((a, b) => {
                return SmotDetailComponent.convertSeverityToNumber(a[1][0]['severity']) - SmotDetailComponent.convertSeverityToNumber(b[1][0]['severity']);
              }));

              grouped.forEach((value, key) => {
                values.push(value.length);
                if (value.length) {
                  keys.push(key);
                  colors.push(SmotDetailComponent.getColorBasedOnSeverity(value[0].severity));
                }
              });
            }

            return ({
              datasets: [{
                ...this.issueChartDataSet,
                data: values,
                backgroundColor: colors
              }],
              options: this.issueChartOptions,
              labels: keys
            }) as { datasets: ChartDataSets[], labels: string[], options };
          }),
      catchError(err => {
        if (err.status !== 404) {
          this.toastr.showErrorBasedOnStatus(err.status);
        }
        return of(({
          datasets: [{
            ...this.issueChartDataSet,
            data: [],
            backgroundColor: []
          }],
          options: this.issueChartOptions,
          labels: []
        }) as { datasets: ChartDataSets[], labels: string[], options });
      })).shareReplay(1);
  }

  private getRefillData() {
    this.refills$ = this.operationService.getOperationsForSmotspot<OperationTimeDTO>(this.smot_logical_id, 'REFILL', OperationMappings.OPERATION_TIME_DTO).pipe(
      filter(x => x !== undefined),
      map(operations => operations?.map(operation => ({
        ...operation,
        operationTime: moment(+operation.operationTime * 1000)
      })))
    );
  }

  private combineBatteryAndRefillDataIntoSunscreenData() {
    this.sunscreenChartData$ = combineLatest([this.refills$, this.batteryChartData$]).pipe(
      map(([refills, chartData]) => {
        // check if a refill has happened before selected dates
        const fromDate = moment(chartData.labels[0]).startOf('day');
        if (refills?.find(r => r.operationTime.isBefore(fromDate))) {
          let volume = 1;
          // filter refills to only contain before fromDate dates
          let refillsBeforeFrom = refills.filter(r => r.operationTime.isBefore(fromDate));
          // find most recent refill
          refillsBeforeFrom = refillsBeforeFrom.sort((r1, r2) => +r1.operationTime.isBefore(r2.operationTime));
          // Fetch total Events between relevantDate and fromDate
            this.sunscreenChartData$ = this.packetService.getTotalEvents(this.smot_logical_id, refillsBeforeFrom[0].operationTime , fromDate).pipe(
              filter(x => x !== undefined),
              map(events => {
                volume = 1 - ((events.events ?? 0) * 0.001);
                return this.handleFillingSunscreenData(volume, chartData, refills);
              }));
        } else {
          const volume = 0;
          return this.handleFillingSunscreenData(volume, chartData, refills);
        }
      })
    );
  }

  private subscribeToFormChanges() {
    this.dateUntilControl.valueChanges.pipe(
      filter(x => x !== null),
      tap(() => {
        this.getIssuesForSmot();
        this.getBatteryDataForSmot();
        this.combineBatteryAndRefillDataIntoSunscreenData();
      }),
    ).subscribe();
  }

  private createForm() {
    this.dateForm = new FormGroup({
      dateFrom: this.dateFromControl,
      dateUntil: this.dateUntilControl
    });
  }

  private groupBy(list, keyGetter) {
    const groupMap = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = groupMap.get(key);
      if (!collection) {
        groupMap.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return groupMap;
  }

  private getSmot() {
    this.smot$ = this.smotService.getSmot(this.smot_logical_id).pipe(
      filter(x => x !== undefined),
      map(smot => ({
        ...smot,
        activationDate: smot.activationDate ? this.datePipe.transform(
          smot.activationDate * 1000, 'yyyy-MM-dd') : '',
        lastRefill: smot.lastRefill ? this.datePipe.transform(
          smot.lastRefill * 1000, 'yyyy-MM-dd') : '',
        installationDate: smot.installationDate ? this.datePipe.transform(
          smot.installationDate * 1000, 'yyyy-MM-dd') : '',
        lastInit: smot.lastInit ? this.datePipe.transform(
          smot.lastInit * 1000, 'yyyy-MM-dd') : '',
      })),
    );
  }

  // get all dates in between dateFrom and dateUntil
  private getDatesInRange(dateFrom, dateUntil) {
    const date = new Date(dateFrom);
    const endDate = new Date(dateUntil);
    const dates = [];

    while (date <= endDate) {
      dates.push(this.datePipe.transform(new Date(date), 'yyyy-MM-dd'));
      date.setDate(date.getDate() + 1);
    }
    return dates;
  }

  private handleFillingSunscreenData(volume: number, chartData, refills) {
    const data = [];
    for (let i = 0; i < chartData.events.length; i++) {
      if (refills.find(r => r.operationTime.format('yyyy-MM-DD') === chartData.labels[i])) {
        volume = 1;
      }
      volume -= chartData.events[i] * 0.001;
      if (volume < 0) {
        volume = 0;
      }
      data.push(volume);
    }

    return ({
      datasets: [{
        ...this.sunscreenChartDataSet,
        data: data,
      }],
      options: this.sunscreenChartOptions,
      labels: chartData.labels
    }) as { datasets: ChartDataSets[], labels: string[], options };
  }

  private fetchRoutesForSmotSpot(page: number, size: number, search: string, sort: Sort) {
    this.smotRoutes$ = this.routeService.getRoutesBySmotId(this.smot_logical_id, page, size, search, sort).pipe(
      filter(x => x !== undefined),
      map((paginatedRoutes: any) => ({
        ...paginatedRoutes,
        content: paginatedRoutes.content.map(route => ({
          ...route,
          nextPlannedDate: route.nextPlannedDate ? this.datePipe.transform(route.nextPlannedDate * 1000, 'dd-MM-yyyy') : 'translate.smot-detail.smotInfo.noPlannedDates'
        }))
      }))
    );
  }

  private checkIfUseHasAccessToRoutes() {
    this.userHasAccessToRoutes$ = this.authService.getLoggedInUserAuth().pipe(
      map(user => !!user.hasAccessToRoutes)
    );
  }
}
