import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';


const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface DialogData {
  from: string;
  to: string;
  dropdown: string;
  minDate: Date;
  maxDate: Date;
}
interface DropDownOption {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-chartfilter',
  templateUrl: 'chartfilter.component.html',
  styleUrls: ['./chartfilter.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class ChartfilterComponent implements OnInit {

  // List for dropdownlist
  options: DropDownOption[] = [
    {value: 'week', viewValue: 'Week'},
    {value: 'maand', viewValue: 'Maand'},
    {value: 'jaar', viewValue: 'Jaar'},
  ];
  constructor(
    public dialogRef: MatDialogRef<ChartfilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {
  }
  // clears date from datepicker
  clearDate(from, to) {
    this.data.dropdown = null;
    this.data.from = null;
    this.data.to = null;
  }
  // controls for the dropdownlist
  changeSelectionRange(value, event) {
    if (event.isUserInput) {
      this.data.to = null;
      this.data.from = null;
      this.data.dropdown = value;
    }
  }
  // handle event for datepicker
  dateChoose(value, event) {
    this.data.dropdown = null;
  }

}
