import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiHelperService} from './api-helper.service';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {SmotAccessDTO} from '../dtos/smotAccessDTO';
import {Sort} from '@angular/material/sort';
import {UserDTO} from '../dtos/UserDTOs/userDTO';
import {UserMappings} from '../models/dtoMappings';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private usersUrl = environment.apiRoot + '/users';

  constructor(private http: HttpClient, private helper: ApiHelperService) {
  }

  getAll<T>(mappingTarget: UserMappings = UserMappings.DEFAULT): Observable<T> {
    return this.helper.getRequest<T>(this.usersUrl + '?mappingTarget=' + mappingTarget);
  }

  getAllWithPagination(page: number | null = null, size: number | null = null, search: string = '', sort: Sort = null, mappingTarget: UserMappings = UserMappings.DEFAULT) {
    let url = this.usersUrl;
    url += this.helper.getPaginationString(page, size, search);
    url += this.helper.getSortString(sort);
    if (url !== this.usersUrl) {
      url += '&mappingTarget=';
    } else {
      url += '?mappingTarget=';
    }
    url += mappingTarget;
    return this.helper.getRequest(url);
  }

  editUser(user: UserDTO) {
    return this.helper.putRequest<UserDTO>(this.usersUrl, user);
  }

  getUser<T>(username: string, preventDefaultErrorBehavior: boolean = false, mappingTarget: UserMappings = UserMappings.DEFAULT): Observable<T> {
    const url = this.usersUrl + '/' + username + '?mappingTarget=' + mappingTarget;
    return this.helper.getRequest(url, null, preventDefaultErrorBehavior);
  }

  checkUsernameAvailability(username: string, preventDefaultBehavior: boolean = false) {
    return this.helper.getRequest(this.usersUrl + `/${username}/availability`, null, preventDefaultBehavior);
  }

  getUsersFromFacilitator(clientNumber: string, mappingTarget: UserMappings = UserMappings.DEFAULT) {
    const url = this.usersUrl + '/facilitator/' + clientNumber + '?mappingTarget=' + mappingTarget;
    return this.helper.getRequest<any[]>(url);
  }

  createUser(user: UserDTO) {
    return this.helper.postRequest<UserDTO>(this.usersUrl, user);
  }

  getRegisterUserToken(username: string) {
    const url = this.usersUrl + `/${username}/ResendRegisterLink`;
    return this.helper.getRequest<any>(url);
  }

  getUserSmotAccess(username: string) {
    const url = this.usersUrl + `/${username}/smot-access`;
    return this.helper.getRequest<SmotAccessDTO[]>(url);
  }

  getUserSmotAccessForSmotWithId(username: string, logicalId) {
    const url = this.usersUrl + `/${username}/smot/${logicalId}/smot-access`;
    return this.helper.getRequest<SmotAccessDTO>(url);
  }
  updateUserSmotAccess(username: string, smotAccessDTOs: SmotAccessDTO[]) {
    const url = this.usersUrl + `/${username}/smot-access`;
    return this.helper.putRequest(url, smotAccessDTOs);
  }

  changePassword(password: String) {
    return this.helper.putRequest<any>(`${this.usersUrl}/changePassword`, password);
  }

  sendForgotPasswordMail(userName: String) {
    return this.helper.putRequestWithoutToken<any>(`${this.usersUrl}/forgotPassword`, userName);
  }

  resetPassword(password: String, username: String, jwt: String) {
    const url = `${this.usersUrl}/resetPassword/${username}/${jwt}`;
    return this.helper.putRequest<any>(url, password);
  }

  fetchProfileImage(userName: string): Observable<Blob> {
    const url = environment.apiRoot + '/fileUpload/avatar/' + userName;

    return this.helper.getRequest<Blob>(url, { responseType: 'blob'});
  }
  /**
   * Deletes a user asynchronously. The request is only sent
   * once you `subscribe()` to the returned `Observable`.
   */
  deleteUser(user: UserDTO): Observable<UserDTO> {
    const url = `${this.usersUrl}/${user.username}`;
    return this.helper.deleteRequest<UserDTO>(url, user);
  }
}
