import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../../services/authentication.service';
import {EMPTY, Observable} from 'rxjs';
import {RouteService} from '../../../../services/route.service';
import {PlannedRouteMappings} from '../../../../models/dtoMappings';
import moment from 'moment/moment';
import {catchError, filter, map} from 'rxjs/operators';
import {ToastrNotificationService} from '../../../../services/toastr-notification.service';
import {Role} from '../../../../models/role';
import {CardPlannedRouteDTO} from '../../../../dtos/RouteDTOs/cardPlannedRouteDTO';

@Component({
  selector: 'app-start-route',
  templateUrl: './start-route.component.html',
  styleUrls: ['./start-route.component.scss', '../../operations.scss']
})
export class StartRouteComponent implements OnInit {
  username: string = '';
  plannedRoutes$: Observable<CardPlannedRouteDTO[]>;
  isAdmin: boolean = false;

  constructor(private authService: AuthenticationService,
              private routeService: RouteService,
              private toastr: ToastrNotificationService) { }

  ngOnInit(): void {
    this.username = this.authService.getUsername();
    this.plannedRoutes$ = this.fetchPlannedRoutes();
  }

  private fetchPlannedRoutes() {
    if (this.authService.getRole() === Role.ADMIN) {
      this.isAdmin = true;
      return this.routeService.getPlannedRoutes<CardPlannedRouteDTO[]>(PlannedRouteMappings.CARD_PLANNED_ROUTE_DTO).pipe(
        filter(x => x !== undefined),
        map(routes => routes.filter(r => !r.finishTime)),
        map(routes => routes.sort((a, b) => +a.plannedDate - +b.plannedDate)),
        catchError(err => {
          this.toastr.showErrorBasedOnStatus(err.status);
          return EMPTY;
        })
      );
    } else {
      return this.routeService.getPlannedRoutes<CardPlannedRouteDTO[]>(PlannedRouteMappings.CARD_PLANNED_ROUTE_DTO, moment().startOf('day').utc(), this.username).pipe(
        filter(x => x !== undefined),
        map(routes => routes.filter(r => !r.finishTime)),
        catchError(err => {
          this.toastr.showErrorBasedOnStatus(err.status);
          return EMPTY;
        })
      );
    }
  }
}
