import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {ApiHelperService} from './api-helper.service';
import {Observable} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';
import {filter} from 'rxjs/operators';
import {IssueOverviewDTO} from '../dtos/IssueDTOs/issueOverviewDTO';
import {IssueDTO} from '../dtos/IssueDTOs/issueDTO';
import {Moment} from 'moment';

@Injectable({
  providedIn: 'root'
})
export class IssueService {

  private issueUrl = environment.apiRoot + '/issues';

  constructor(private helper: ApiHelperService) { }

  /**
   * Gets the list of all issues asynchronously. The request is only sent
   * once you `subscribe()` to the returned `Observable`.
   */
  getIssues(): Observable<IssueDTO[]> {
    return this.helper.getRequest<IssueDTO[]>(this.issueUrl).pipe(
      filter(x => x !== undefined)
    );
  }

  getIssuesByLogLevel(logLevel: string, preventDefaultBehavior: boolean = false): Observable<IssueDTO[]> {
    return this.helper.getRequest<IssueDTO[]>(this.issueUrl + '/level/' + logLevel, null, preventDefaultBehavior).pipe(
      filter(x => x !== undefined)
    );
  }

  /**
   * Gets the counts of Issues by issueTypes. The request is only sent
   * once you `subscribe()` to the returned `Observable`.
   */
  getIssueCountsByIssueType(): Observable<any> {
    return this.helper.getRequest<any>(this.issueUrl + '/counts');
  }

  /**
   * Gets the counts of Issues by issueTypes with a custom url for query parameters. The request is only sent
   * once you `subscribe()` to the returned `Observable`.
   */
  getIssuesWithCustomUrl(url): Observable<any> {
    return this.helper.getRequest<any>(this.issueUrl + '/counts' + url);
  }


  /**
   * Gets the list of all issues of a smotspot asynchronously. The request is only sent
   * once you `subscribe()` to the returned `Observable`.
   */
  getIssuesSmotspot(logicalId): Observable<IssueOverviewDTO> {
    return this.helper.getRequest<IssueOverviewDTO>(this.issueUrl + '/smot/' + logicalId).pipe(
      filter(x => x !== undefined)
    );
  }

  getIssuesSmotspotAndBetweenDates(logicalId, from: Moment, until: Moment, preventDefaultBehavior: boolean = false): Observable<IssueOverviewDTO> {
    return this.helper.getRequest<IssueOverviewDTO>(this.issueUrl + '/smot/' + logicalId + '/dates' + '?from=' + from.utc().toISOString() + '&until=' + until.utc().toISOString(), null, preventDefaultBehavior).pipe(
      filter(x => x !== undefined)
    );
  }



  getIssuesWithPaginationByUrgency(urgency: string, page: number, size: number, errorCodes: number[], smotId: number, clientNumber: number, resolved: number[]) {
    if (urgency === undefined) { return of(null); }
    let url = this.issueUrl + `/${urgency}?page=${page}&size=${size}&resolved=${resolved.join(',')}`;
    if (errorCodes !== undefined && errorCodes.length > 0) {
        url += `&errors=${errorCodes.join(',')}`;
    }
    if (smotId !== undefined && smotId != null) {
      url += `&smotId=${smotId}`;
    }
    if (clientNumber !== undefined && clientNumber != null) {
      url += `&clientNumber=${clientNumber}`;
    }
    return this.helper.getRequest<any>(url);
  }

}
