import {Type} from '@angular/core';
import {PlannedRouteDTO} from '../dtos/RouteDTOs/plannedRouteDTO';
import { CommentsComponent } from '../views/shared/flow/flow-steps/comments/comments.component';
import { FinishFlowComponent } from '../views/shared/flow/flow-steps/finish-flow/finish-flow.component';
import { FlowScannerComponent } from '../views/shared/flow/flow-steps/flow-scanner/flow-scanner.component';
import { RefillComponent } from '../views/shared/flow/flow-steps/refill/refill.component';
import { UploadImageComponent } from '../views/shared/flow/flow-steps/upload-image/upload-image.component';

export const ROUTE_MAINTENANCE_FLOW: RouteFlow = {
  type: 'maintenance',
  steps: [
    {
      component: CommentsComponent,
      data: {
        requiredToCheckAllComments: false,
      }
    },
    {
      component: FlowScannerComponent,
      data: {
        expectedEntityType: 'smot',
        headerString: 'translate.operations.smot-scanner.scanAnyQRCode'
      }
    },
    {
      component: UploadImageComponent,
      data: {
        headerString: 'translate.operations.smot-maintenance.imageBeforeCleaning',
        uploadUrl: '/operation/{smotId}?suffix=before',
      }
    },
    {
      component: CommentsComponent,
      data: {
        requiredToCheckAllComments: true,
      }
    },
    {
      component: UploadImageComponent,
      data: {
        headerString: 'translate.operations.smot-maintenance.imageAfterCleaning',
        uploadUrl: '/operation/{smotId}?suffix=after',
      }
    },
    {
      component: RefillComponent,
      data: {}
    },
    {
      component: FinishFlowComponent,
      data: {
        headerString: 'translate.routes.stepComplete'
      }
    }
  ]
};

export const ROUTE_INSTALL_FLOW: RouteFlow = {
  type: 'installation',
  steps: [
    {
      component: CommentsComponent,
      data: {
        requiredToCheckAllComments: false,
      }
    },
    {
      component: FlowScannerComponent,
      data: {
        expectedEntityType: 'smot',
        headerString: 'translate.operations.smot-scanner.scanAnyQRCode'
      }
    },
    {
      component: CommentsComponent,
      data: {
        requiredToCheckAllComments: true,
      }
    },
    {
      component: UploadImageComponent,
      data: {
        uploadUrl: '/operation/smot/',
        headerString: 'translate.operations.smot-image-upload.uploadSmotImage',
      }
    },
    {
      component: FinishFlowComponent,
      data: {
        headerString: 'translate.routes.stepComplete'
      }
    }
  ]
};

export const ROUTE_DEINSTALL_FLOW: RouteFlow = {
  type: 'deinstallation',
  steps: [
    {
      component: CommentsComponent,
      data: {
        requiredToCheckAllComments: false,
      }
    },
    {
      component: FlowScannerComponent,
      data: {
        expectedEntityType: 'smot',
        headerString: 'translate.operations.smot-scanner.scanAnyQRCode'
      }
    },
    {
      component: UploadImageComponent,
      data: {
        headerString: 'Upload een foto VOOR het kuisen',
        uploadUrl: '/operation/{smotId}?suffix=before',
      }
    },
    {
      component: UploadImageComponent,
      data: {
        headerString: 'Upload een foto NA het kuisen',
        uploadUrl: '/operation/{smotId}?suffix=after',
      }
    },
    {
      component: CommentsComponent,
      data: {
        headerString: 'translate.routes.deinstallTheSmot',
        requiredToCheckAllComments: true,
      }
    },
    {
      component: FinishFlowComponent,
      data: {
        headerString: 'translate.routes.stepComplete'
      }
    }
  ]
};

export const ROUTE_CUSTOM_FLOW: RouteFlow = {
  type: 'custom',
  steps: [
    {
      component: CommentsComponent,
      data: {
        headerString: 'custom',
        requiredToCheckAllComments: true,
      }
    },
    {
      component: FinishFlowComponent,
      data: {
        headerString: 'translate.routes.stepComplete'
      }
    }
  ]
};



export interface RouteFlow {
  type: 'maintenance' | 'installation' | 'deinstallation' | 'custom';
  steps: RouteFlowStep[];
}

interface RouteFlowStep {
  component: Type<any>;
  data: RouteFlowStepData;
}

export interface RouteFlowStepData {
  uploadUrl?: string;
  requiredToCheckAllComments?: boolean;
  headerString?: string;
  videoUrl?: string;
  plannedRoute?: PlannedRouteDTO;
  plannedRouteId?: number;
  stepId?: number;
  smotId?: number;
  expectedEntityType?: 'smot' | 'device' | 'customCasing';
  expectedId?: number;
}
