import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {UserDTO} from '../../dtos/UserDTOs/userDTO';
import {AuthenticationService} from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuard implements CanActivate {
  user: UserDTO;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>(obs => {
        if (this.authenticationService.isLoggedIn()) {
          const roleFromUser = this.authenticationService.getRole();
          if (route.data.roles) {
            route.data.roles.forEach(role => {
              if (role === roleFromUser) {
                return obs.next(true);
              }
            });
            return obs.next(false);
          }
          return obs.next(true);
        } else {
            this.router.navigate(['/login']);
          return obs.next(false);
        }
    });
  }

}
