import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import defaultLanguage from '../assets/i18n/EN.json';
import {UserService} from './services/user.service';
import {Observable} from 'rxjs';
import {AuthenticationService} from './services/authentication.service';
import {ToastrNotificationService} from './services/toastr-notification.service';
import {UserDTO} from './dtos/UserDTOs/userDTO';
import {UserMappings} from './models/dtoMappings';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

  user: UserDTO;

  constructor(private router: Router,
              private translateService: TranslateService,
              private userService: UserService,
              private authService: AuthenticationService,
              private toastr: ToastrNotificationService,
              ) {
    translateService.setTranslation('en', defaultLanguage);
    translateService.setDefaultLang('en');
  }

  ngOnInit() {
    if (this.authService.isLoggedIn()) {
      this._getUser().then(() => {
        const currentLang = this.user.language;
        this.translateService.use(currentLang);
      });
    }

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    },
      (e) => {
        this.toastr.showErrorBasedOnStatus(e.status);
        if (e.status === 500) {
          console.log('server');
        } else {
          console.log('verkeerde login');
        }
      });
  }


  async _getUser() {
    const username = this.authService.getUsername();
    const apiCall: Observable<UserDTO> = this.userService.getUser(username, false, UserMappings.USER_AUTHENTICATION_DTO);

    await apiCall.toPromise()
      .then((res: any) => {
        this.user = res;
      }).catch( (error) => {
        this.toastr.showErrorBasedOnStatus(error.status);
      });
  }
}
