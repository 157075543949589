import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {catchError, filter, tap} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import { BaseFlowStepComponent } from '../base-flow-step.interface';
import { RouteFlowStepData } from '../../../../../models/routeFlows';
import { FileUploadService } from '../../../../../services/file-upload.service';
import { ToastrNotificationService } from '../../../../../services/toastr-notification.service';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss', '../../../../operations/operations.scss']
})
export class UploadImageComponent implements OnInit, BaseFlowStepComponent {
  @Input() data: RouteFlowStepData;
  @Output() nextStep: EventEmitter<true> = new EventEmitter();
  @Output() previousStep: EventEmitter<false> = new EventEmitter();

  previewUrl: any = null;
  blob: any = null;
  disableButton: boolean = false;
  constructor(private fileUploadService: FileUploadService,
              private toastr: ToastrNotificationService) {
  }

  ngOnInit(): void {
  }

  public onFileSelect(event) {
    const _this = this;
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = function() {
      const img = new Image();

      img.src = reader.result as string;

      img.onload = function() {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const imgDimensions = _this.calculateAspectRatioFit(img.width, img.height, 1080, 1080);

        canvas.width = imgDimensions.width;
        canvas.height = imgDimensions.height;

        ctx.drawImage(img, 0, 0, imgDimensions.width, imgDimensions.height);

        // Compress the image
        canvas.toBlob(function (blob) {
            _this.blob = blob;
        }, 'image/webp', 0.6);

        _this.previewUrl = reader.result;
      };
    };
    reader.readAsDataURL(file);
  }
  public goToPreviousStep() {
    this.previousStep.emit(false);
  }

  public async goToNextStep(skipped: boolean = false) {
    if (skipped) {
      this.nextStep.emit(true);
      return;
    }

    this.disableButton = true;
    this.fileUploadService.uploadImage(this.blob, this.data.uploadUrl).pipe(
      filter(x => x !== undefined),
      tap(() => this.nextStep.emit(true)),
      catchError(err => {
        this.toastr.showErrorBasedOnStatus(err.status);
        this.disableButton = false;
        return EMPTY;
      })
    ).subscribe();
  }


  private calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return { width: srcWidth * ratio, height: srcHeight * ratio };
  }
}
