import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EMPTY, Observable} from 'rxjs';
import {NecessityDTO, RouteDTO, RouteStepCommentDTO, RouteStepDTO} from '../../../../dtos/RouteDTOs/routeDTO';
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';
import {RouteService} from '../../../../services/route.service';
import {MatDialog} from '@angular/material/dialog';
import {PlannedRouteDTO} from '../../../../dtos/RouteDTOs/plannedRouteDTO';
import {of} from 'rxjs/internal/observable/of';
import {ToastrNotificationService} from '../../../../services/toastr-notification.service';
import {ConfirmActionModalComponent} from '../../../plugins/confirm-action-modal/confirm-action-modal.component';
import {FormControl, Validators} from '@angular/forms';
import {ValidationFormsService} from '../../../forms/validation-forms/validation-forms.service';
import {InputModalComponent} from '../../../plugins/input-modal/input-modal.component';
import {MaintenanceService} from '../../../../services/maintenance.service';
import {MobileService} from '../../../../services/mobile.service';
import moment from 'moment';

@Component({
  selector: 'app-route-overview',
  templateUrl: './route-overview.component.html',
  styleUrls: ['./route-overview.component.scss', '../../operations.scss', '../../../route/route-detail/route-detail.component.scss'],
})
export class RouteOverviewComponent implements OnInit {
  plannedRouteId: number;
  route$: Observable<PlannedRouteDTO>;
  maintenances$: Observable<any>;
  nextStep: RouteStepDTO = null;
  displayEnterLicensePlate: boolean = false;
  // displayCheckNecessities: boolean = false;
  // hasUncheckedNecessities: boolean = true;
  licensePlateControl: FormControl = new FormControl('', [Validators.required, Validators.nullValidator, Validators.pattern(this.vf.formRules.nonEmpty)]);
  kilometerCountControl: FormControl = new FormControl('', [Validators.required, Validators.nullValidator, Validators.pattern(this.vf.formRules.nonEmpty)]);

  constructor(private route: ActivatedRoute,
              private routeService: RouteService,
              private router: Router,
              private dialog: MatDialog,
              private toastr: ToastrNotificationService,
              private vf: ValidationFormsService,
              private mobileService: MobileService,
              private maintenanceService: MaintenanceService) {
    route.params.pipe(
      filter(params => this.plannedRouteId = params['id'])
    ).subscribe();
  }

  ngOnInit(): void {
    this.route$ = this.fetchRoute();
    this.licensePlateControl.markAsTouched();
  }

  public getButtonText() {
    if (this.nextStep === null) {
      return 'translate.routes.finishRoute';
    }

    if (this.nextStep.skipped) {
      return 'translate.routes.undoSkip';
    }

    if (this.nextStep.finished) {
      return 'translate.routes.stepAlreadyPerformed';
    }

    if (!this.nextStep.smot) {
      return 'translate.routes.startStep';
    }

    if (this.nextStep.maintenance) {
      return 'translate.routes.startMaintenance';
    } else if (this.nextStep.installation) {
      return 'translate.routes.startInstallation';
    } else if (this.nextStep.deinstallation) {
      return 'translate.routes.startDeinstallation';
    }
  }

  public navigateWithWaze(step: RouteStepDTO) {
    const lat = step.smot ? step.smot?.latitude : step.latitude;
    const lng = step.smot ? step.smot?.longitude : step.longitude;
    if (this.mobileService.detectMobileDeviceByUserAgent()) {
      window.open(`waze://?ll=${lat},${lng}&navigate=yes`, '_blank');
    } else {
      window.open(`https://waze.com/ul?ll=${lat}%2C${lng}&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location`, '_blank');
    }
  }

  public navigateWithGoogleOrAppleMaps(step: RouteStepDTO) {
    const lat = step.smot ? step.smot?.latitude : step.latitude;
    const lng = step.smot ? step.smot?.longitude : step.longitude;

    window.open(`https://maps.apple.com/?q=${lat},${lng}`, '_blank');
  }

  public startStep(plannedRoute: PlannedRouteDTO) {
    if (this.nextStep === null) {
      const dialogRef = this.dialog.open(InputModalComponent, {
        data: {
          key: 'Voer de kilometerstand van de auto waarmee je gereden hebt in',
          type: 'number',
          value: plannedRoute.startKilometerCount
        }
      });

      dialogRef.afterClosed().pipe(
        filter(x => !!+x),
        tap(kilometerCount => {
          // Set the finishing parameters for the route
          plannedRoute.finishKilometerCount = kilometerCount;
          plannedRoute.finishTime = moment().utc();

          // Get all the missedSteps and mark them as skipped
          const missingSteps = plannedRoute.route.steps
            .filter(step => !plannedRoute.plannedRouteSteps.find(plannedStep => plannedStep.routeStep.routeStepId === step.routeStepId));
          plannedRoute.plannedRouteSteps = plannedRoute.plannedRouteSteps.concat(missingSteps.map(step => ({
            plannedRouteStepId: 0,
            plannedRouteId: plannedRoute.plannedRouteId,
            routeStep: step,
            stepType: this.routeService.getPlannedStepTypeFromStep(step),
            finishTime: moment.utc(),
            startTime: null,
            hasBeenSkipped: 1,
            reason: ''
          })));

          // Update the plannedRoute
          this.updatePlannedRoute(plannedRoute).subscribe();
        }),
        switchMap(() => {
          const routeId = plannedRoute.route.routeId;
          if (plannedRoute.route.type === 1) {
            plannedRoute.route.type = 0;
            plannedRoute.route.description += ' - Auto generated';
            plannedRoute.route.steps = plannedRoute.route.steps.map(step => ({
              ...step,
              maintenance: !!step.smot,
              installation: false,
              deinstallation: false
            }));
            return this.createAndSaveRoute(plannedRoute.route).pipe(
              filter(x => x !== undefined),
              switchMap(() => this.routeService.deleteRoute(routeId).pipe(
                tap(() => this.router.navigate(['/operations'])),
                catchError(err => {
                  this.toastr.showErrorBasedOnStatus(err.status);
                  return EMPTY;
                })
              ))
            );
          } else {
            this.router.navigate(['/operations']);
          }
        })
      ).subscribe();
      return;
    }
    if (this.nextStep.skipped) {
      const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
        data: {
          key: 'Deze stap werdt overgeslagen, wil je deze alsnog uitvoeren?'
        }
      });

      dialogRef.afterClosed().pipe(
        filter(x => x !== undefined && x),
        tap(() => {
          this.unSkipStep(this.nextStep, plannedRoute);
          this.router.navigate([`operations/perform-route`], {
            queryParams: {
              plannedRouteId: this.plannedRouteId,
              stepId: this.nextStep.routeStepId,
              flow: this.getFlowTypeFromStep(this.nextStep),
              smotId: this.nextStep.smot?.logicalId
            }
          });
        })
      ).subscribe();

      return;
    }
    if (this.nextStep.started) {
      this.router.navigate([`operations/perform-route`], {
        queryParams: {
          plannedRouteId: this.plannedRouteId,
          stepId: this.nextStep.routeStepId,
          flow: this.getFlowTypeFromStep(this.nextStep),
          smotId: this.nextStep.smot?.logicalId
        }
      });
    } else {
      // if (this.hasUncheckedNecessities && this.nextStep.routeStepId === plannedRoute.route.steps[0].routeStepId) {
      //   this.displayCheckNecessities = true;
      //   return;
      // }
      this.routeService.clearStepStage();
      const plannedRouteStepIndex = plannedRoute.plannedRouteSteps.findIndex(s => s.routeStep.routeStepId === this.nextStep.routeStepId);
      if (plannedRouteStepIndex !== -1) {
        plannedRoute.plannedRouteSteps[plannedRouteStepIndex].startTime = moment.utc();
      } else {
        // Create new plannedStep and update plannedRoute
        plannedRoute.plannedRouteSteps.push({
          plannedRouteId: this.plannedRouteId,
          plannedRouteStepId: 0,
          finishTime: null,
          routeStep: this.nextStep,
          stepType: this.routeService.getPlannedStepTypeFromStep(this.nextStep),
          startTime: moment().utc(),
          reason: ''
        });
      }


      this.updatePlannedRoute(plannedRoute).pipe(
        tap(route => this.route$ = of(route)),
        tap(() => this.router.navigate([`operations/perform-route`], {
          queryParams: {
            plannedRouteId: this.plannedRouteId,
            stepId: this.nextStep.routeStepId,
            flow: this.getFlowTypeFromStep(this.nextStep),
            smotId: this.nextStep.smot?.logicalId
          }
        })),
        catchError(err => {
          this.toastr.showErrorBasedOnStatus(err.status);
          return EMPTY;
        })
      ).subscribe();
    }
  }

  public openFinishRouteModal(plannedRoute) {
    const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
      data: {
        key: 'Ben je zeker dat je deze route wilt beeindigen?'
      }
    });

    dialogRef.afterClosed().pipe(
      filter(x => !!x),
      tap(() => {
            this.nextStep = null;
            this.startStep(plannedRoute);
      })
    ).subscribe();
  }

  public openConfirmSkipModal(step, plannedRoute) {
    const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
      data: {
        key: 'translate.form.areYouSureYouWantToPerformThisAction'
      }
    });

    dialogRef.afterClosed().pipe(
      filter(x => !!x),
      tap(() => this.openEnterReasonModal(step, plannedRoute))
    ).subscribe();
  }

  public unSkipStep(step: RouteStepDTO, plannedRoute: PlannedRouteDTO) {
    const plannedStepIndex = plannedRoute.plannedRouteSteps.findIndex(s => s.routeStep.routeStepId === step.routeStepId);
    const plannedStep = plannedRoute.plannedRouteSteps[plannedStepIndex];
    plannedStep.hasBeenSkipped = 0;
    plannedStep.reason = '';
    plannedStep.finishTime = null;

    plannedRoute.plannedRouteSteps[plannedStepIndex] = plannedStep;

    this.updatePlannedRoute(plannedRoute)
      .pipe(
        tap((route) => this.route$ = of(this.mapRoute(route)))
      ).subscribe();
  }

  private openEnterReasonModal(step, plannedRoute) {
    const dialogRef = this.dialog.open(InputModalComponent, {
      data: {
        key: 'translate.routes.giveAReasonForSkipping',
        multiline: true
      }
    });

    dialogRef.afterClosed().pipe(
      filter(x => !!x?.trim()),
      tap((reason) => this.skipStep(step, plannedRoute, reason))
    ).subscribe();
  }
  public handleLicensePlateInput(plannedRoute: PlannedRouteDTO) {
    this.licensePlateControl.markAsTouched();
    this.kilometerCountControl.markAsTouched();
    if (this.licensePlateControl.invalid || this.kilometerCountControl.invalid) {
      return;
    }

    plannedRoute.startKilometerCount = this.kilometerCountControl.value;
    let licensePlate: string = this.licensePlateControl.value;
    licensePlate = licensePlate.replaceAll(' ', '');
    plannedRoute.licensePlate = licensePlate;

    this.updatePlannedRoute(plannedRoute).pipe(
      tap(() => this.displayEnterLicensePlate = false)
    ).subscribe();
  }

  // public hasUncheckedValues(necessities, maintenances) {
  //   this.hasUncheckedNecessities = necessities.find(n => n.checked === false) || maintenances.find(n => n.checked === false);
  // }

  private fetchRoute() {
    return this.routeService.getPlannedRouteById<PlannedRouteDTO>(this.plannedRouteId).pipe(
      filter(x => x !== undefined),
      map(plannedRoute => this.mapRoute(plannedRoute)),
      tap(plannedRoute => {
        this.displayEnterLicensePlate = !!!plannedRoute.licensePlate || !!!plannedRoute.startKilometerCount;
        this.licensePlateControl.setValue(plannedRoute.licensePlate);
        this.kilometerCountControl.setValue(plannedRoute.startKilometerCount);
        this.fetchMaintenances(plannedRoute.route.routeId);
        // this.hasUncheckedValues(plannedRoute.route.necessities, []);
      }),
      tap(plannedRoute => this.nextStep = this.findNextStepToBePerformed(plannedRoute)),
      catchError(err => {
        this.toastr.showErrorBasedOnStatus(err.status);
        return EMPTY;
      })
    );
  }

  private mapRoute(plannedRoute: PlannedRouteDTO) {
    plannedRoute = ({
      ...plannedRoute,
      route: {
        ...plannedRoute.route,
        steps: plannedRoute.route.steps.filter(x => x.active).sort((s1, s2) => s1.stepNumber - s2.stepNumber)
      }
    });
    return ({
        ...plannedRoute,
        route: {
          ...plannedRoute.route,
          necessities: plannedRoute.route.necessities.map(necessity => ({
            ...necessity,
            checked: false
          })),
          steps: plannedRoute.route.steps.map(step => ({
            ...step,
            started: !!plannedRoute.plannedRouteSteps.find(s => s.routeStep.routeStepId === step.routeStepId)?.startTime,
            finished: !!plannedRoute.plannedRouteSteps.find(s => s.routeStep.routeStepId === step.routeStepId && s.finishTime),
            skipped: !!plannedRoute.plannedRouteSteps.find(s => s.routeStep.routeStepId === step.routeStepId && s.hasBeenSkipped)
          }))
        }
      });
  }

  private fetchMaintenances(routeId) {
    this.maintenances$ = this.maintenanceService.getMaintenanceByRouteId(routeId).pipe(
      filter(x => x !== undefined),
      map(maintenances => maintenances.map(maintenance => ({
        description: maintenance.repairPart,
        checked: false
      })))
    );
  }

  private skipStep(step: RouteStepDTO, plannedRoute: PlannedRouteDTO, reason: string) {
    this.routeService.clearStepStage();
    const plannedRouteIndex = plannedRoute.plannedRouteSteps.findIndex(s => s.routeStep.routeStepId === step.routeStepId);

    if (plannedRouteIndex !== -1) {
      // Update existing step
      const plannedStep = plannedRoute.plannedRouteSteps[plannedRouteIndex];
      plannedStep.reason = reason;
      plannedStep.hasBeenSkipped = 1;
      plannedStep.finishTime = moment().utc();

      plannedRoute.plannedRouteSteps[plannedRouteIndex] = plannedStep;
    } else {
      // Create new plannedStep and update plannedRoute
      plannedRoute?.plannedRouteSteps.push({
        plannedRouteId: this.plannedRouteId,
        plannedRouteStepId: 0,
        routeStep: step,
        startTime: moment().utc(),
        finishTime: moment.utc(),
        stepType: this.routeService.getPlannedStepTypeFromStep(step),
        hasBeenSkipped: 1,
        reason: reason
      });
    }


    // Update plannedRoute
    this.updatePlannedRoute(plannedRoute).pipe(
      tap((route) => {
        // Update UI
        this.route$ = of(this.mapRoute(route));
        this.nextStep = this.findNextStepToBePerformed(plannedRoute);
        step.started = true;
        step.skipped = true;
      }),
      catchError(err => {
        this.toastr.showErrorBasedOnStatus(err.status);
        return EMPTY;
      })
    ).subscribe();
  }

  private findNextStepToBePerformed(plannedRoute) {
    // get All Ids from steps that have been finished or skipped
    const finishedStepIds = plannedRoute.plannedRouteSteps.filter(s => s.finishTime !== null || !!s.hasBeenSkipped).map(s => s.routeStep?.routeStepId);
    // get the first step that does not have its id included in finishedStepIds
    const nextStep  = plannedRoute.route.steps.find(s => !finishedStepIds.includes(s.routeStepId));
    // if a nextStep has been found, check if it has been started, if no update the plannedRoute
    if (nextStep) {
      if (!plannedRoute.startTime) {
        plannedRoute.startTime = moment().utc();
        this.updatePlannedRoute(plannedRoute).subscribe();
      }
      return nextStep;
    }
    // If no nextStep has been found, route will be marked as finished
    plannedRoute.finishTime = moment().utc();
    this.updatePlannedRoute(plannedRoute).subscribe();
    return null;
  }

  private updatePlannedRoute(plannedRoute: PlannedRouteDTO) {
    return this.routeService.updatePlannedRoute<PlannedRouteDTO>(plannedRoute).pipe(
      filter(x => x !== undefined)
    );
  }

  private createAndSaveRoute(routeToSave: RouteDTO) {
    routeToSave = this.stripRouteOfIds(routeToSave);
    // Link Smot to step in case Smot is present
    routeToSave.steps = routeToSave.steps.map(step => ({
      ...step,
      smotLogicalId: step.smot?.logicalId
    }));
    return this.routeService.createRoute<RouteDTO>(routeToSave).pipe(
      filter(x => x !== undefined),
      // Map data to copy from current route to newly created route
      map(route => ({
        ...route,
        steps: route.steps.map((step, index) => ({
          ...step,
          smot: routeToSave.steps[index].smot,
          contactPerson: routeToSave.steps[index].contactPerson,
          deinstallation: routeToSave.steps[index].deinstallation,
          installation: routeToSave.steps[index].installation,
          maintenance: routeToSave.steps[index].maintenance,
          routeStepComments: routeToSave.steps[index].routeStepComments
            .filter(c => !(c.smotLogicalId && c.routeStepCommentId))
            .map(comment => ({
              ...comment,
              routeStepId: comment.smotLogicalId ? null : step.routeStepId,
              routeStepCommentId: null,
            }) as RouteStepCommentDTO)
        }) as RouteStepDTO),
        necessities: routeToSave.necessities.map(necessity => ({
          ...necessity,
          necessityId: null,
        }) as NecessityDTO)
      })),
      switchMap(route => this.saveRoute(route)),
      catchError(error => {
        this.toastr.showErrorBasedOnStatus(error.status);
        return EMPTY;
      })
    );
  }

  private stripRouteOfIds(route) {
    route.routeId = null;
    route.necessities.map(necessity => ({
      ...necessity,
      necessityId: null
    }));
    route.steps.map(step => ({
      ...step,
      routeStepId: null
    }));
    return route;
  }

  private saveRoute(route: RouteDTO) {
    return this.routeService.updateRoute(route).pipe(
      filter(x => x !== undefined),
      catchError(err => {
        this.toastr.showErrorBasedOnStatus(err.status);
        return EMPTY;
      })
    );
  }

  private getFlowTypeFromStep(step: RouteStepDTO) {
    if (step.installation) {
      return 'installation';
    } else if (step.maintenance) {
      return 'maintenance';
    } else if (step.deinstallation) {
      return 'deinstallation';
    } else if (step.repair) {
      return 'repair';
    } else {
      return 'custom';
    }
  }
}
