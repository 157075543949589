import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {ApiHelperService} from './api-helper.service';
import {Observable} from 'rxjs';
import {ClientDTO} from '../dtos/ClientDTOs/clientDTO';
import {SmotDTO} from '../dtos/SmotDTOs/smotDTO';
import {ClientMappings} from '../models/dtoMappings';

@Injectable({
  providedIn: 'root'
})
@Injectable({
  providedIn: 'root'
})
export class ClientService {

  private clientUrl = environment.apiRoot + '/clients';


  constructor(private helper: ApiHelperService) { }

  /**
   * Gets the list of clients asynchronously. The request is only sent
   * once you `subscribe()` to the returned `Observable`.
   */
  getClients<T>(mappingTarget: ClientMappings = ClientMappings.DEFAULT ): Observable<ClientDTO[]> {
    const url = this.clientUrl + '?mappingTarget=' + mappingTarget;
    return this.helper.getRequest(url);
  }

  getClientSmotSpots(id): Observable<SmotDTO[]> {
    return this.helper.getRequest<SmotDTO[]>(this.clientUrl + '/' + id + '/smots' );
  }

  /**
   * Gets a single facilitator asynchronously. The request is only sent
   * once you `subscribe()` to the returned `Observable`.
   *
   * This can be used for a searchbar.
   */
  getClient(id: number): Observable<ClientDTO> {
    const url = `${this.clientUrl}/${id}`;
    return this.helper.getRequest<ClientDTO>(url);
  }

  /**
   * Adds a new client asynchronously. The request is only sent
   * once you `subscribe()` to the returned `Observable`.
   */
  addClient(client: ClientDTO): Observable<ClientDTO> {
    return this.helper.postRequest<ClientDTO>(this.clientUrl, client);
  }

  /**
   * Edits a facilitator asynchronously. The request is only sent
   * once you `subscribe()` to the returned `Observable`.
   */
  editClient(client: ClientDTO): Observable<ClientDTO> {
    return this.helper.putRequest<ClientDTO>(this.clientUrl, client);
  }

  /**
   * Deletes a client asynchronously. The request is only sent
   * once you `subscribe()` to the returned `Observable`.
   */
  deleteClient(client: ClientDTO):  Observable<ClientDTO> {
    const url = `${this.clientUrl}/${client.clientNumber}`;
    return this.helper.deleteRequest<ClientDTO>(url, client);
  }
}
