export enum Role {
  ADMIN = '615033',
  FACILITATOR = '931045',
  FACILITATORADMIN = '609221',
  PRODUCER = '982481',
  PRODUCERADMIN = '227443',
  SYSTEM = '795314',
  PARTNER = '781037',
  STAFF = '319746',
  VIEWER = '610937'
}
