<div *ngIf="!hideSearch" class="d-flex justify-content-sm-between justify-content-center align-items-center flex-wrap">
  <app-sundo-search-bar [control]="searchControl"></app-sundo-search-bar>
  <div class="d-flex flex-wrap">
    <ng-content></ng-content>
    <app-primary-button *ngIf="allowCreate" (click)="emitCreateClicked()" >{{ 'translate.create.create' | translate}}</app-primary-button>
  </div>

</div>
<ng-container *ngIf="$dataSource | async as data else loading">
  <div class="rounded-mask">
    <table matSort mat-table [matSortActive]="activeSort?.active" [matSortDirection]="activeSort?.direction" (matSortChange)="handleSortChange($event)" [dataSource]="data.content" class="mat-elevation-z8 w-100">
      <!--    Region: Headers -->
      <ng-container *ngFor="let col of columns">
        <ng-container [ngSwitch]="col.field">
          <ng-container *ngSwitchCase="'delete'" [matColumnDef]="col.field">
            <th class="p-1 text-center" mat-header-cell *matHeaderCellDef> {{col.header | translate}} </th>
            <td class="p-4 text-center" mat-cell *matCellDef="let element" (click)="emitDeleteClicked(element)">
              <a class="cil-trash icons"
                 style="color: red"
                 onMouseOver="this.style.cursor='pointer'; this.style.color='#990000'"
                 onMouseOut="this.style.cursor='arrow'; this.style.color='red'">
              </a>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'edit'" [matColumnDef]="col.field">
            <th class="p-1 text-center" mat-header-cell *matHeaderCellDef> {{col.header | translate}}</th>
            <td class="p-4 text-center" mat-cell *matCellDef="let element" (click)="emitEditClicked(element)">
              <a class="cil-cog icons"
                 style="color: #27C5E1"
                 onMouseOver="this.style.cursor='pointer'; this.style.color='#426F85'"
                 onMouseOut="this.style.cursor='arrow'; this.style.color='#27C5E1'">
              </a>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'info'" [matColumnDef]="col.field">
            <th class="p-1 text-center" mat-header-cell *matHeaderCellDef> {{col.header | translate}} </th>
            <td class="p-4 text-center" mat-cell *matCellDef="let element" (click)="emitInfoClicked(element)">
              <a class="cil-info icons"
                 style="color: #27C5E1"
                 onMouseOver="this.style.cursor='pointer'; this.style.color='#426F85'"
                 onMouseOut="this.style.cursor='arrow'; this.style.color='#27C5E1'">
              </a>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'status'" [matColumnDef]="col.field">
            <ng-container *ngIf="col.sortable else unsorted">
              <th class="p-1 text-center" mat-header-cell *matHeaderCellDef mat-sort-header>{{col.header | translate}}</th>
              <td class="p-4 text-center" mat-cell *matCellDef="let element" (click)="emitStatusClicked(element)"
                  onMouseOver="this.style.cursor='pointer'"
                  onMouseOut="this.style.cursor='arrow'">
              <span [class]="element.class">
                {{element[col.field]}}
              </span>
              </td>
            </ng-container>
            <ng-template #unsorted>
              <th class="p-1 text-center" mat-header-cell *matHeaderCellDef>{{col.header | translate}}</th>
              <td class="p-4 text-center" mat-cell *matCellDef="let element" (click)="emitStatusClicked(element)"
                  onMouseOver="this.style.cursor='pointer'"
                  onMouseOut="this.style.cursor='arrow'">
              <span [class]="element.class">
                {{element[col.field]}}
              </span>
              </td>
            </ng-template>
          </ng-container>
          <ng-container *ngSwitchDefault [matColumnDef]="col.dbProperty ? col.dbProperty : col.field">
            <ng-container *ngIf="col.sortable else unsorted">
              <th class="p-4" mat-header-cell *matHeaderCellDef mat-sort-header><span [innerHTML]="col.header | translate"></span></th>
              <td class="p-4" [class.text-center]="col.textAlign === 'center'" mat-cell (click)="emitRowClicked(element)" [style.cursor]="clickableRows ? 'pointer' : 'default'" *matCellDef="let element"><span [innerHTML]="element[col.field] | translate"></span> </td>
            </ng-container>
            <ng-template #unsorted>
              <th class="p-4" mat-header-cell *matHeaderCellDef><span [innerHTML]="col.header | translate"></span></th>
              <td class="p-4" [class.text-center]="col.textAlign === 'center'" mat-cell (click)="emitRowClicked(element)" [style.cursor]="clickableRows ? 'pointer' : 'default'" *matCellDef="let element"><span [innerHTML]="element[col.field] | translate"></span> </td>
            </ng-template>
          </ng-container>
        </ng-container>
      </ng-container>

      <!--    EndRegion: Headers  -->

      <!--  Region: Data -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <!--  EndRegion: Data -->
      <tr *matNoDataRow>
        <td [colSpan]="columns.length">{{'translate.dataTable.noDataFound' | translate}}</td>
      </tr>
    </table>
  </div>
  <!--  Region: Paginator -->
  <mat-paginator
    style="border-radius: 0 0 20px 20px;"
    [length]="data.totalElements"
    [pageSize]="data.size"
    [pageIndex]="data.pageable.pageNumber"
    [pageSizeOptions]="[5, 10, 15]"
    [showFirstLastButtons]="true"
    (page)="handlePageChange($event)"
    aria-label="select page of table">
  </mat-paginator>
  <!--  EndRegion: Paginator  -->

</ng-container>
<ng-template #loading>
  <div class="rounded-mask">
    <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    <table mat-table class="mat-elevation-z8 w-100">
      <!--    Region: Headers -->
      <ng-container *ngFor="let col of columns">
        <ng-container [ngSwitch]="col.field">
          <ng-container *ngSwitchCase="'delete'" [matColumnDef]="col.field">
            <th mat-header-cell *matHeaderCellDef> {{col.header | translate}} </th>
          </ng-container>
          <ng-container *ngSwitchCase="'edit'" [matColumnDef]="col.field">
            <th mat-header-cell *matHeaderCellDef> {{col.header | translate}} </th>
          </ng-container>
          <ng-container *ngSwitchCase="'info'" [matColumnDef]="col.field">
            <th mat-header-cell *matHeaderCellDef> {{col.header | translate}} </th>
          </ng-container>
          <ng-container *ngSwitchDefault [matColumnDef]="col.dbProperty ? col.dbProperty : col.field">
            <th mat-header-cell *matHeaderCellDef> {{col.header | translate}} </th>
          </ng-container>
        </ng-container>
      </ng-container>

      <!--    EndRegion: Headers  -->

      <!--  Region: Data -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="emitRowClicked(row)"></tr>
      <!--  EndRegion: Data -->


    </table>
  </div>
  <!--  Region: Paginator -->
  <mat-paginator
    style="border-radius: 0 0 20px 20px;"
    [length]="0"
    [pageSize]="0"
    [pageIndex]="0"
    aria-label="select page of table">
  </mat-paginator>
  <!--  EndRegion: Paginator  -->
</ng-template>

