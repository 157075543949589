import {BaseFlow} from './base-flow.class';
import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {ToastrNotificationService} from '../../services/toastr-notification.service';
import {Params} from '@angular/router';
import {
  CREATE_CONFIGURATION_FLOW,
  CREATE_CUSTOM_CASING_FLOW,
  CREATE_DEVICE_FLOW,
  CREATE_SMOT_FLOW
} from '../../models/productionFlows';
import _ from 'lodash';
import {FlowService} from '../../services/flow.service';

@Injectable({
  providedIn: 'root'
})
export class ProductionFlow extends BaseFlow {
  constructor(public location: Location,
              public toastr: ToastrNotificationService,
              public flowService: FlowService) {
    super(toastr, location, flowService);
    this.key = 'production_step_stage';
  }

  getFlow(params: Params): any {
    switch (params['flow']) {
      case 'custom-casing':
        this.flow = _.cloneDeep(CREATE_CUSTOM_CASING_FLOW);
        return this.flow;
      case 'device':
        this.flow = _.cloneDeep(CREATE_DEVICE_FLOW);
        return this.flow;
      case 'configuration':
        this.flow = _.cloneDeep(CREATE_CONFIGURATION_FLOW);
        return this.flow;
      case 'smotspot':
        this.flow = _.cloneDeep(CREATE_SMOT_FLOW);
    }
  }

  getCurrentStep(params: Params): any {
    const step = super.getCurrentStep(params);

    step.data = {
      ...step.data,
      smotId: params['smotId'],
    };

    return step;
  }
}
