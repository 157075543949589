import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';

import {SmotDetailRoutingModule} from './smot-detail-routing.module';
import {SmotDetailComponent} from './smot-detail.component';
import {ChartsModule} from 'ng2-charts';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {DatatableInitModule} from '../tables/datatable/datatable.module';
import {MatSelectModule} from '@angular/material/select';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {TablesModule} from '../tables/tables.module';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
  declarations: [
    SmotDetailComponent
  ],
  exports: [
    SmotDetailComponent
  ],
  imports: [
    CommonModule,
    SmotDetailRoutingModule,
    ChartsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    TranslateModule,
    DatatableInitModule,
    MatSelectModule,
    MatExpansionModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    TablesModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule
  ],
  providers: [DatePipe]
})
export class SmotDetailModule { }
