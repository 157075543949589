<style>
  table th, table td {
    padding: 0.75rem 2px;
  }
</style>

<div class="animated fadeIn">
  <div class="card" *ngIf="!hideContainer">
    <div class="card-header" [innerHTML]="dataTitle | translate">
    </div>
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-md-4" [hidden]="createIsHidden">
          <div class="mb-4 col-12 col-md-8" >
            <app-primary-button routerLink="create" >{{ 'translate.create.create' | translate}}</app-primary-button>
          </div>
        </div>
        <div class="col-md-4" [ngClass]="createIsHidden?'offset-md-8':'offset-md-4'" [hidden]="searchIsHidden">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text bg-primary"><i class="fa fa-search"></i></span>
            </div>
            <input type="text" class="form-control" [(ngModel)]="filterQuery" placeholder="{{'translate.dataTable.searchByName' | translate}}"/>
          </div>
        </div>
      </div>
      <table class="table table-striped table-hover table-responsive-lg" [mfData]="data | dataFilter : filterQuery"
             #mf="mfDataTable" [mfRowsOnPage]="rowsOnPage">
        <thead>
        <tr>
          <th *ngFor="let header of dataHeaders; let i = index" style="{{'width:' + 100/dataHeaders.length + '%'}}">
            <mfDefaultSorter by="{{dataFields[i]}}" #headerName>
              <div [innerHTML]="header | translate"></div>
              <span *ngIf="headerName.isSortedByMeAsc" class="cui-arrow-top" aria-hidden="true"></span>
              <span *ngIf="headerName.isSortedByMeDesc" class="cui-arrow-bottom" aria-hidden="true"></span>
            </mfDefaultSorter>
          </th>
          <th class="pl-3" *ngIf="editableRows">{{'translate.general.edit' | translate}}</th>
          <th class="pl-3" *ngIf="deletableRows">{{'translate.form.delete' | translate }}</th>
          <th class="pl-3" *ngIf="clickForInfo">{{'translate.dataTable.moreInfo' | translate}}</th>
        </tr>
        </thead>
        <tbody *ngIf="loading">
        <tr class="common" style="height: 100px;">
          <td colspan="8">{{'translate.dataTable.dataIsLoading' | translate}}</td>
        </tr>
        </tbody>
        <tbody *ngIf="mf.data.length == 0 && !loading">
        <tr class="common" style="height: 100px;">
          <td colspan="8">{{'translate.dataTable.noDataFound' | translate}}</td>
        </tr>
        </tbody>
        <tbody *ngIf="mf.data.length > 0">
        <tr *ngFor="let item of mf.data"
            [style.cursor]="isClickable ? 'pointer' : 'default'">
          <ng-template [ngIf]="severity" [ngIfElse]="regular">
            <td *ngFor="let field of dataFields" (click)="onRowClicked(item)">
              <div [ngSwitch]="item[field]">
              <span>
                <a href="{{item['link']}}" *ngSwitchCase="'SEVERE'" class="btn"
                   [ngClass]="'btn-danger'">{{item[field]}}</a>
              </span>
                <span>
                <a href="{{item['link']}}" *ngSwitchCase="'MODERATE'" class="btn"
                   [ngClass]="'btn-warning'">{{item[field]}}</a>
              </span>
                <span>
                <a href="{{item['link']}}" *ngSwitchCase="'LOW'" class="btn"
                   [ngClass]="'btn-success'">{{item[field]}}</a>
              </span>
                <span *ngSwitchDefault [innerHTML]="item[field] | translate"></span>
              </div>
            </td>
          </ng-template>
          <ng-template #regular>
            <td *ngFor="let field of dataFields" (click)="onRowClicked(item)">
            <span [innerHTML]="item[field] | translate"></span>
            </td>
          </ng-template>
          <td *ngIf="editableRows" (click)="onRowClicked(item)">
            <span class="pl-3">
              <a href="{{item['link']}}" style="text-decoration: none"
                 class="cui-cog icons">
              </a>
            </span>
          </td>
          <td *ngIf="deletableRows">
            <span class="pl-3">
              <a class="cui-trash icons"
                 style="color: red"
                 onMouseOver="this.style.cursor='pointer'; this.style.color='#990000'"
                 onMouseOut="this.style.cursor='arrow'; this.style.color='red'"
              (click)="emitDelete(item)">
              </a>
            </span>
          </td>
          <td *ngIf="clickForInfo">
            <span>
              <div style="color: #1ac1dd"
                 (click)="emitItemInfo(item['info'])">
                <button mat-mini-fab color="accent">
                  <a class="cui-info"></a>
                </button>
              </div>
            </span>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colspan="10">
            <mfBootstrapPaginator></mfBootstrapPaginator>
          </td>
        </tr>
        </tfoot>
      </table>
    </div>
  </div>
  <div *ngIf="hideContainer">
    <div class="row mb-3">
      <div class="col-md-4" [hidden]="createIsHidden">
        <div class="mb-4 col-12 col-md-8" >
          <app-primary-button routerLink="create" >{{ 'translate.create.create' | translate}}</app-primary-button>
        </div>
      </div>
      <div class="col-md-4" [ngClass]="createIsHidden?'offset-md-8':'offset-md-4'" [hidden]="searchIsHidden">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text bg-primary"><i class="fa fa-search"></i></span>
          </div>
          <input type="text" class="form-control" [(ngModel)]="filterQuery" placeholder="{{'translate.dataTable.searchByName' | translate}}"/>
        </div>
      </div>
    </div>
    <table class="table table-striped table-hover table-responsive-lg" [mfData]="data | dataFilter : filterQuery"
           #mf="mfDataTable" [mfRowsOnPage]="rowsOnPage">
      <thead>
      <tr>
        <th *ngFor="let header of dataHeaders; let i = index" style="{{'width:' + 100/dataHeaders.length + '%'}}">
          <mfDefaultSorter by="{{dataFields[i]}}" #headerName>
            <div [innerHTML]="header | translate"></div>
            <span *ngIf="headerName.isSortedByMeAsc" class="cui-arrow-top" aria-hidden="true"></span>
            <span *ngIf="headerName.isSortedByMeDesc" class="cui-arrow-bottom" aria-hidden="true"></span>
          </mfDefaultSorter>
        </th>
        <th class="pl-3" *ngIf="editableRows">{{'translate.general.edit' | translate}}</th>
        <th class="pl-3" *ngIf="deletableRows">{{'translate.form.delete' | translate }}</th>
        <th class="pl-3" *ngIf="clickForInfo">{{'translate.dataTable.moreInfo' | translate}}</th>
      </tr>
      </thead>
      <tbody *ngIf="mf.data.length > 0">
      <tr *ngFor="let item of mf.data"
          [style.cursor]="isClickable ? 'pointer' : 'default'">
        <ng-template [ngIf]="severity" [ngIfElse]="regular">
          <td *ngFor="let field of dataFields" (click)="onRowClicked(item)">
            <div [ngSwitch]="item[field]">
              <span>
                <a href="{{item['link']}}" *ngSwitchCase="'SEVERE'" class="btn"
                   [ngClass]="'btn-danger'">{{item[field]}}</a>
              </span>
              <span>
                <a href="{{item['link']}}" *ngSwitchCase="'MODERATE'" class="btn"
                   [ngClass]="'btn-warning'">{{item[field]}}</a>
              </span>
              <span>
                <a href="{{item['link']}}" *ngSwitchCase="'LOW'" class="btn"
                   [ngClass]="'btn-success'">{{item[field]}}</a>
              </span>
              <span *ngSwitchDefault [innerHTML]="item[field] | translate"></span>
            </div>
          </td>
        </ng-template>
        <ng-template #regular>
          <td *ngFor="let field of dataFields" (click)="onRowClicked(item)">
            <span [innerHTML]="item[field] | translate"></span>
          </td>
        </ng-template>
        <td *ngIf="editableRows" (click)="onRowClicked(item)">
            <span class="pl-3">
              <a href="{{item['link']}}" style="text-decoration: none"
                 class="cui-cog icons">
              </a>
            </span>
        </td>
        <td *ngIf="deletableRows">
            <span class="pl-3">
              <a class="cui-trash icons"
                 style="color: red"
                 onMouseOver="this.style.cursor='pointer'; this.style.color='#990000'"
                 onMouseOut="this.style.cursor='arrow'; this.style.color='red'"
                 (click)="emitDelete(item)">
              </a>
            </span>
        </td>
        <td *ngIf="clickForInfo">
            <span>
              <div style="color: #1ac1dd"
                   (click)="emitItemInfo(item['info'])">
                <button mat-mini-fab color="accent">
                  <a class="cui-info"></a>
                </button>
              </div>
            </span>
        </td>
      </tr>
      </tbody>
      <tbody *ngIf="loading">
      <tr class="common" style="height: 100px;">
        <td colspan="8">{{'translate.dataTable.noDataFound' | translate}}</td>
      </tr>
      </tbody>
      <tbody *ngIf="mf.data.length == 0 && !loading">
      <tr class="common" style="height: 100px;">
        <td colspan="8">{{"translate.dataTable.noDataFound" | translate}}</td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td colspan="10">
          <mfBootstrapPaginator></mfBootstrapPaginator>
        </td>
      </tr>
      </tfoot>
    </table>
  </div>
</div>
